import * as React from "react";
import { callApi } from "../../utils/api";
import MaskedInput from "react-text-mask";
import Select from 'react-select';
import ReactFlexyTable from "react-flexy-table";
import FileSearchModal from "../file/FileSearchModal";
import { PendingFile } from "../file/ManagePendingFile";
import './EntryTrackingReport.css'
import { User } from "../user/ManageUser";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { V } from "jointjs";
import {
	ToastsContainer,
	ToastsContainerPosition,
	ToastsStore
} from "react-toasts";

declare var $: any;

interface AllProps {
	match: any;
	history: any;
	router: any;
	route: any;
	id: any;
}

class EntryTrackingReport extends React.Component<AllProps> {
	state = {
		selectVal: false,
		setResponse: true,
		myJSON: '',
		lookup: '',
		fileId: '',
		sampleId: "",
		id: "",
		fromDate: "",
		toDate: "",
		files: [],
		fileNo: '',
		lawhandsBranches: [],
		// lawHandsBranch: "",
		lawhands_branch: "",
		assigned_to: "",
		users: [],
		total: '',
		green: '',
		red: '',
		OutTime: 0,
		inTime: 0,
		inreason: "",
		outreason: "",
		// fromDate: moment().format('YYYY-MM-DD'),
		// toDate: moment().format('YYYY-MM-DD'),
		isLoading: false
	};
	obj: any;
	componentDidMount() {
		this.reGetvalue()
		this.getLawHandsBranches()
		this.getUsers();
		this.getOutTime();
		this.ouTime();
	}

	previousPop = () => {
		$("#out_reason").modal("show");
		$("#another-reason").modal("hide");
	}
	getOutTime = () => {
		let date = new Date();
		let stratTime = date.getTime();
		localStorage.setItem("startTime", JSON.stringify(stratTime))
		let outDate = date.toLocaleDateString();
		localStorage.setItem("OutDateGet", JSON.stringify(outDate));
		var Railway = date.getMinutes();
		if (Railway < 10) {
			var TotalMinutes = "0" + Railway;
			let ouTtime = date.getHours() + ":" + TotalMinutes;
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}
		else {
			let ouTtime: any = date.getHours() + ":" + date.getMinutes();
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}

	}
	ouTime = () => {
		const getfileId: any = localStorage.getItem("currentfileId");
		if (localStorage.getItem("currentfileId")) {
			$("#out_reason").modal("show");
		}
	}
	BacktoFile = () => {
		$("#out_reason").modal("hide");
		const fileid1: any = localStorage.getItem("currentfileId");
		this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
	}
	outimeGet = () => {
		this.setState({ isLoading: true });
		const fileid1: any = localStorage.getItem("currentfileId");
		const userid1: any = localStorage.getItem("user1");
		const inreason: any = localStorage.getItem("in_reason");
		const intime: any = localStorage.getItem("IntimeGet");
		console.log("70", intime);
		const outtime: any = localStorage.getItem("outtimeGet");
		console.log("73", outtime);
		const outdate: any = localStorage.getItem("OutDateGet");
		const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
		console.log('Second Spn', secondsSpent);
		if (this.state.outreason == "") {
			this.setState({ selectVal: true });
		}
		else {
			let obj = {
				"file_id": JSON.parse(fileid1),
				"user_id": JSON.parse(userid1),
				"in_reason": JSON.parse(inreason),
				"out_reason": this.state.outreason,
				"intime": JSON.parse(intime),
				"outtime": JSON.parse(outtime),
				"insert_date": JSON.parse(outdate),
				"total_seconds": secondsSpent
			}

			// console.log("72",obj);
			callApi("POST", "process_eod_insert.php", obj)
				.then(res => res.data)
				.then(response => {
					console.log("76", response);
					this.setState({ isLoading: false });
					// localStorage.removeItem("currentfileId");
					localStorage.removeItem("in_reason");
					localStorage.removeItem("outreason");
					localStorage.removeItem("IntimeGet");
					localStorage.removeItem("OutDateGet");
					$("#out_reason").modal("hide");
					$("#another-reason").modal("hide");
					ToastsStore.success("Your Reason Saved Successfully");
					let process_officerId: any = localStorage.getItem("process_office_id");
					let process_officer_id = JSON.parse(process_officerId);
					const userid1: any = localStorage.getItem("user1");
					let user_id = JSON.parse(userid1);
					const take_file: any = localStorage.getItem("takenfile_id");
					const user: any = localStorage.getItem("user");
					const getfileId: any = localStorage.getItem("currentfileId");
					if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
						this.fileout(fileid1);
					} else {
						localStorage.removeItem('currentfileId');
					}
				}).catch((err) => {
					console.log(err);
					this.setState({ isLoading: false });
					ToastsStore.error("Your Reason Not Stored");
				})
		}
	}
	fileout = (fileid1) => {
		let fileId = JSON.parse(fileid1);
		const loginuser: any = localStorage.getItem("user1");
		let req = {
			id: fileId,
			reason: "File Out",
			created_by: JSON.parse(loginuser)
		}
		callApi("POST", "time_update.php", req)
			.then(res => res.data)
			.then(response => {
				localStorage.removeItem('currentfileId');
				// localStorage.removeItem("takenfile_id");
			});
	}
	onchangeReason = (e: any) => {

		if (e.target.value == "Other") {
			this.setState({ in_reason: "", selectVal: false })
			$("#out_reason").modal("hide");
			$("#another-reason").modal("show");
		}
		else if (e.target.value == "select") {
			this.setState({ setResponse: false })
		}
		else {
			// this.setState({})
			this.setState({ [e.target.name]: e.target.value, selectVal: false });
		}
	};
	onchange = (e: any) => {

		this.setState({ [e.target.name]: e.target.value, selectVal: false });

	};
	reGetvalue = () => {
		let obj = localStorage.getItem("EodReport");
		console.log("55", obj);
		callApi("POST", "en_report.php", obj)
			.then(res => {
				let data = [];
				localStorage.removeItem("EodReport");
				if (res.data && res.data.responseJson && res.data.responseJson.data) {
					data = res.data.responseJson.data;
				}
				this.setState({ files: data ? data : [] });
				// this.props.history.push("/entry-tracking-report" + this.state.id);
			})
			.catch(err => {
				console.log(err);
				this.setState({ files: [] });
			})
	}
	getUsers = () => {
		callApi("GET", "user_get.php")
			.then(res => res.data)
			.then(response => {
				const user: any = localStorage.getItem("user");
				const roleType = user ? JSON.parse(user)["roleType"] : "";
				const userId = user ? JSON.parse(user)["userId"] : "";
				this.setState({ users: response.data });
				if (roleType == 7 || roleType == 8) {
					this.filterUser();
				} else if (roleType == 5) {
					this.filterreportUser();
				}
				else {
					this.setState({ users: response.data });
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	goToFileDashboard = fileName => {
		const url = "/entry-tracking-report";
		localStorage.setItem("url", url);
		let obj = { "file_id": fileName }
		localStorage.setItem("thisPopup", JSON.stringify(obj))
		localStorage.setItem("currentfileId", JSON.stringify(fileName));
		this.props.history.push("/file-dashboard/" + fileName);
	};

	getLawHandsBranches() {
		callApi("GET", "get_branch.php")
			.then(res => res.data)
			.then(response => {
				this.setState({ lawhandsBranches: response.data });


			})
			.catch(err => {
				console.log(err);
			});
	}


	getEodTableData = () => {
		const user: any = localStorage.getItem("user");
		const roleType = user ? JSON.parse(user)["roleType"] : "";
		const userId = user ? JSON.parse(user)["userId"] : "";
		let obj: any = {
			id: this.state.id,
			from_date: this.state.fromDate,
			to_date: this.state.toDate,
			employee_id: this.state.assigned_to,
			lawhands_branch: this.state.lawhands_branch,
			user_id: userId,
			role_type: roleType
		};

		localStorage.setItem("EodReport", JSON.stringify(obj));

		callApi("POST", "en_report.php", obj)
			.then(res => {
				// localStorage.setItem("eodreport",obj);
				console.log("98", localStorage.getItem("EodReport"));

				let data = [];
				if (res.data && res.data.responseJson && res.data.responseJson.data) {
					data = res.data.responseJson.data;
				}
				this.setState({ files: data ? data : [] });
				// this.props.history.push("/entry-tracking-report" + this.state.id);
			})
			.catch(err => {
				console.log(err);
				this.setState({ files: [] });
			})

	}

	handleChange1 = (e, propName?, fieldType?) => {
		let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
		let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
		if (fieldName === "lawhands_branch") {
			const branch = fieldValue;
			const filteredUsers = this.state.users.filter(
				(itm: any) => itm.branch_id === branch
			);
			this.setState({ [fieldName]: fieldValue });
			this.setState({ filteredUsers });
		}
		if (fieldName === "assigned_to") {
			const employee_id = fieldValue;
			this.setState({ [fieldName]: fieldValue });
		}
	}

	handleChange = (e, stateName?, type?) => {
		if (e.target.name === "toDate") {
			this.setState({ todate: e.target.value })
		}
		if (e.target.name === "fromDate") {
			this.setState({ fromdate: e.target.value })
		}
		if (e.target.name === "id") {
			this.setState({ id: e.target.value })
		}
		if (e.target.name === "lawhands_branch") {
			const branch = e.target.value;
			const filteredUsers = this.state.users.filter(
				(itm: any) => itm.branch_id === branch
			);
			this.setState({ filteredUsers });
		}
		this.setState({ [e.target.name]: e.target.value });
	};
	handleDateChange = (date, dateStr, type) => {
		// console.log(date, dateStr);
		this.setState({ [type]: dateStr });
	};
	filterUser() {
		const user: any = localStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";
		const filteredUsers = this.state.users.filter((user: any) => user.id === userId);
		this.setState({ users: filteredUsers });
	}
	filterreportUser() {
		const user: any = localStorage.getItem("user");
		const userId = user ? JSON.parse(user)["userId"] : "";
		const filteredUsers = this.state.users.filter((user: any) => user.report_to === userId);
		this.setState({ users: filteredUsers });
	}
	render() {

		const columns = [
			{
				header: 'F.No',
				key: 'file_id',
				td: (file) => <div>
					<span onClick={() => this.goToFileDashboard(file.fileName)}>{file.id}</span>
				</div>
			},
			{
				// front_officer: "Priyanga", verify_officer
				header: 'P Officer',
				key: 'process_officer',
				// key: 'front_officer',
				// td: (file) => <h6 onClick={() =>this.goToFileDashboard(file.fileName)}>{file.front_officer}</h6>
				td: (file) =>
					<h6 onClick={() => this.goToFileDashboard(file.fileName)}>{file.process_officer}</h6>
			},
			{
				header: 'V Officer',
				key: 'verify_officer',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.fileName)}>{file.verify_officer}</h6>
			},

			{
				header: 'Scan',
				key: 'scanned',
				td: (file) => {
					return <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.scanned === "YES" ? "text-c-green1" : "text-c-red1"}></div>
				}
			},
			{
				header: "assigned",
				key: 'unassigned',
				td: (file) => {
					return <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.unassigned === "YES" ? "text-c-green1" : "text-c-red1"}></div>
				}
			},
			{
				header: 'Hint',
				key: 'hint',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.hint === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'SOP',
				key: 'generaldetails',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)}>
					<h6 style={{ textAlign: "center" }} >Total - {file.sop_total}</h6>
					<div className="ConT"><p className="text-c-red1">{file.sop_pending}</p><p className="text-c-yellow1">{file.sop_notreleated}</p><p className="text-c-green1">{file.sop_complete} </p></div>
				</div>
			},
			{
				header: 'HL',
				key: 'highlight',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.highlight === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'FC',
				key: 'flowchart',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.flowchart === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Query',
				key: 'query',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.query === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Data Entry',
				key: 'data_entry',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)}>
					<h6 style={{ textAlign: "center" }}>Total - {file.lod_total}</h6>
					<div className="ConT"><p className="text-c-red1 "> {file.lod_pending} </p><p className="text-c-yellow1" >{file.lod_notreleated}</p><p className="text-c-green1">{file.lod_complete} </p></div>
				</div>
				// <span className="slash">/</span>

			},
			{
				header: 'T.Complete',
				key: 'typing_complete',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.typing_complete === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'V.Complete',
				key: 'verification_complete',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.fileName)} className={file.verification_complete === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			}
		];
		const data = this.state.files;
		// const downloadExcelProps = {
		// 	type: 'filtered',
		// 	title: 'test',
		// showLabel: true
		//   }
		const { toDate, setResponse, selectVal } = this.state;
		const user: any = localStorage.getItem("user");
		const roleType = user ? JSON.parse(user)["roleType"] : "";
		const lawhandsBranchesoptions = [
			{ value: "", label: "All" },
			...this.state.lawhandsBranches
		];
		let usersoptions;
		if (roleType == 8 || roleType == 7) {
			usersoptions = [
				...this.state.users
			];
		} else {
			usersoptions = [
				{ value: "", label: "All" },
				...this.state.users
			];
		}

		return (
			<section className="pcoded-main-container">
				<ToastsContainer
					store={ToastsStore}
					position={ToastsContainerPosition.TOP_RIGHT}
				/>{" "}
				<div className="pcoded-wrapper">
					<div className="pcoded-content">
						<div className="pcoded-inner-content">
							<div className="main-body">
								<div className="page-wrapper">
									<div className="row">
										<div className="col-sm-12">
											<div className="card">
												<div className="card-header">
													<h2 className="file-status-header" style={{ display: "flex", justifyContent: "center" }}>Entry Tracking Report</h2>
													<div className="row mb-3">
														<div className="col-xl-2 col-md-2">
															<h6>From</h6>
															<DatePicker format={'DD-MM-YYYY'}
																onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>To</h6>
															<DatePicker format={'DD-MM-YYYY'}
																onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>LH Branch</h6>
															{this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
																opt.label = opt.name; opt.value = opt.id
															}) &&
																<Select options={lawhandsBranchesoptions} name="lawhands_branch"
																	value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
																	onChange={(e) => this.handleChange1(e, 'lawhands_branch', 'singleselect')}
																/>
															}
														</div>


														<div className="col-xl-2 col-md-2">
															<h6>Employee Name</h6>
															{this.state.users && this.state.users.length >= 0 && this.state.users.map((opt: any) => {
																opt.label = opt.name; opt.value = opt.id
															}) &&
																<Select options={usersoptions} name="assigned_to"
																	value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
																	onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
																/>
															}
														</div>

														<div className="col-xl-2 col-md-2">
															<h6>File.No</h6>
															<input type="id" name="id" className="form-control" id="exampleFormControlInput1"
																placeholder=""
																value={this.state.id}
																onChange={e => this.handleChange(e)}
															/>
														</div>
														<button
															type="submit"
															className="btn btn-primary"
															style={{ alignSelf: 'end' }}
															onClick={() => this.getEodTableData()}
														>
															Search
														</button>
													</div>
												</div>
												<div className="card-body">
													<ReactFlexyTable data={data} columns={columns} sortable showExcelButton />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* THIS FOR OUT REASON MODAL */}
				<div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog ">
						<div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
							<div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
								<h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
							</div>
							<div className="form-group" style={{ width: '74%', margin: 'auto' }}>
								<select
									className="custom-select"
									name="outreason"
									value={this.state.outreason}
									onChange={(e) => this.onchangeReason(e)}
									required
									id="select1"
									style={{ marginTop: '8px' }}
								>
									<option value="select">Select Your Reason</option>
									<option value="Query Raised">Query Raised</option>
									<option value=" Re-Query Raised"> Re-Query Raised</option>
									<option value="Hold ">Hold </option>
									<option value="Completed">Completed</option>
									<option value="Other">Others</option>

								</select>
								{selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
							</div>
							<div className="modal-footer" style={{ marginTop: '27px' }}>
								<button
									type="button"
									className="btn btn-primary"
									disabled={this.state.isLoading}
									onClick={this.outimeGet}
									style={{ width: '76px', fontSize: '17px' }}
								>  {this.state.isLoading ? (
									<i className="fa fa-spinner fa-spin"></i>
								) : "OK"}</button>
								<button
									type="button"
									className="btn btn-outline"
									onClick={this.BacktoFile}
									style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
								> Back To File</button>
							</div>
						</div>
					</div>
				</div>
				{/* OTHER REASON MODAL */}
				<div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
					<div className="modal-dialog ">
						<div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
							<h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
							<div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

								{/* <div className="col-md-5"> */}
								<div className="form-group" style={{ width: '98%', margin: 'auto' }}>
									<textarea
										className="form-control"
										placeholder="Type Your Reason"
										name="outreason" onChange={this.onchange} />
									{selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
								</div>
								{/* </div> */}

							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => this.outimeGet()}
									style={{ width: '76px', fontSize: '17px' }}
									disabled={this.state.isLoading}
								>  {this.state.isLoading ? (
									<i className="fa fa-spinner fa-spin"></i>
								) : "OK"}</button>
								<button
									type="button"
									className="btn btn-outline"

									onClick={() => this.previousPop()}
									// onClick={this.disableModalHandler}
									style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
								> Back</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


export default EntryTrackingReport;

function x(arg0: string, x: any) {
	throw new Error("Function not implemented.");
}
