import * as React from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import { ADMIN_ROLE } from "../../utils/role";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import Spinner from "../work-file/loader";
import moment from "moment";
import ExcelUserReport from "./UserExcel";

declare var $: any;
export interface User {
  id: number;
  docid: string;
  name: string;
  mob_no: string;
  email: string;
  user_type: string;
  subordinate: string;
  branch: string;
  report_to: string;
  role: string;
  usertypelabel: string;
  // itm:any;
}
interface AllProps {
  history: any;
}
class ManageUser extends React.Component<AllProps> {
  state = {
    users: [],
    bankToBeDeleted: '',
    isFileRefresh: false,
    outreason: "",
    selectVal: false,
    isLoading: false,
    resetLoading: false,
  };
  componentDidMount() {
    this.listUser();
    this.getOutTime();
    this.ouTime();
  }
  listUser = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "user_get.php")
      .then(res => res.data)
      .then(response => {
        response.data.map(itm => {
          console.log("response--->>>", itm);
          itm.usertypelabel = this.getUserType(itm.user_type);

        });
        const files = response.data;
        if (files && files.length > 0) {
          files.forEach((file, index) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
          })
        }
        this.setState({ users: files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = localStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = localStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId))
    console.log("32", getfileId);
    if (localStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }
  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = localStorage.getItem("currentfileId");
    const userid1: any = localStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent
      }

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          console.log("76", response);
          this.setState({ isLoading: false });
          // localStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = localStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = localStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = localStorage.getItem("takenfile_id");
          const user: any = localStorage.getItem("user");
          const getfileId: any = localStorage.getItem("currentfileId");
          if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
            this.fileout(fileid1);
          } else {
            localStorage.removeItem('currentfileId');
          }
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        localStorage.removeItem('currentfileId');
        // localStorage.removeItem("takenfile_id");
      });
  }
  handleReset = ()=> {
    this.setState({resetLoading : true});
    callApi("GET", "deviceTotalReset.php")
      .then(res => res.data)
      .then(response => {
        if (response.success) {
          this.setState({resetLoading : false});
          this.listUser();
          ToastsStore.success(response.message);
        }else{
          this.setState({resetLoading : false});
          ToastsStore.error(response.error || response.message);
        }
      }).catch(err => {
        console.log(err);
        this.setState({ resetLoading: false });
      });
  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };


  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  }
  assignFile = (e, type) => {
    console.log("assignFile", e);
    this.setState({ isLoading: true });

    // Simulating an asynchronous operation
    // setTimeout(() => {
    //   this.setState({ isLoading: false });
    // }, 2000);
    let obj = {
      user_id: e.id,
      level_status: type
      // file_id: [
      //   21182,
      //   21226,
      //   35159,35160,35161,35085,35084,35052,35057,35062,35067,35069,35071,35072,35073,35074,35077,
      //   35081,35063,35083,35061,35078,35086,35087,36978,35089,36986,35088,36987,36990,35091,35090,
      //   35092,36998,35093,35095,35096,35097,35148,35098,35100,35158,35099,35102,35156,35103,35155,
      //   35101,35106,35105,35154,35108,35104,35107,35110,35153,35150,35109,35111,35112,35114,35113,
      //   35115,35118,35117,35116,35119,35151,35121,35120,35149,35123,35122,35127,35147,35125,35124,
      //   35126,35128,35152,35145,35146,35164,35163,36982
      // ],
    }
    callApi("POST", "level_two_duplication_file.php", obj)
      .then(res => res.data)
      .then(response => {
        console.log("response", response);
        ToastsStore.success("100 Files Submitted Successfully");
        this.setState({ isLoading: false });

      })
      .catch(err => {
        console.log(err);
      });
  };
  getUserType = (type: string) => {
    console.log("getUserType------>>>>>", type);
    switch (type) {
      case "1":
        return "Admin";
      case "2":
        return "Manager";
      case "3":
        return "Executive";
      case "4":
        return "Front Office";
      case "5":
        return "Trainee";
      case "6":
        return "Trainer";
      default:
        return "";
    }
  };
  // editUser = (user: User) => {
  //   this.props.history.push("/user/add/" + user.id);
  // };
  confirmDeleteUser = (file) => {
    this.setState({ bankToBeDeleted: file.id });
  };
  deleteUser = () => {
    const obj = {
      id: this.state.bankToBeDeleted
    }

    callApi("POST", "user_delete.php", obj)
      .then(res => res.data)
      .then(response => {
        this.listUser();
      })
      .catch(err => {
        console.log(err);
      });
  };
  render() {
    const user: any = localStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : ""; const columns = [
      {
        header: 'ID',
        key: 'index',
      },
      // {
      //   header: 'User ID',
      //   key: 'id',
      // },
      {
        header: 'Name',
        key: 'name',
      },
      {
        header: 'User Type',
        key: 'usertypelabel',
      },
      {
        header: 'Mobile Number',
        key: 'mob_no',
      },
      {
        header: 'Branch',
        key: 'branch',
      },
      {
        header: "Device Limit",
        key: "deviceLimit",
      },
      {
        header: "Login/Logout Time",
        key: "loginTime",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">{file.loginTime}</div>;
        },
      },
      {
        header: "Actions",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">
            <button
              type="button"
              className="btn mr-3 btn-primary"
              style={{ borderRadius: "4px" }}
              onClick={() =>
                this.assignFile(file, 1)
              }
            >

              Level 1
            </button>
            <button
              type="button"
              className="btn mr-3 btn-primary"
              style={{ borderRadius: "4px" }}
              onClick={() =>
                this.assignFile(file, 2)
              }
            >

              Level 2
            </button>
            <button
              type="button"
              style={{
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px"
              }}
              className="btn btn-primary"
            >
              Action
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">
                Toggle Dropdown
              </span>
            </button>
            <div
              className="dropdown-menu"
              x-placement="top-start"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                transform:
                  "translate3d(88px, -2px, 0px)"
              }}
            >
              <a
                className="dropdown-item"
                // onClick={() =>
                onClick={() =>
                  this.props.history.push("/user/add/" + file.id)
                  // this.editUser(user)
                }
              >
                Edit
              </a>
              {role_id === ADMIN_ROLE && (
                <a
                  className="dropdown-item"
                  style={{ float: "right" }}
                  data-toggle="modal"
                  data-target="#delete-user"
                  onClick={() =>
                    this.confirmDeleteUser(file)
                  }
                >
                  Delete
                </a>
              )}
            </div>
          </div>

        }
      }
    ];
    const data = this.state.users;
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>User Manage</h5>
                          <button
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            onClick={() => this.props.history.goBack()}
                          >
                            Back
                          </button>
                        </div>
                        <div className="card-body">
                          {this.state.isLoading ? (
                            <Spinner />
                          ) : ""}
                          <div className="dt-responsive table-responsive">
                          <div className="d-flex gap-5">
                              <Link
                                to={"/user/add/0"}
                                className="btn btn-primary m-b-20"
                              >
                                Add User
                              </Link>
                              <ExcelUserReport />
                              <button
                                className="btn btn-primary m-b-20 ml-2"
                                onClick={this.handleReset}
                                disabled={this.state.resetLoading}
                              >
                              {this.state.resetLoading && (
                                  <i className="fa fa-spinner fa-spin"></i>
                              )}
                                Rest
                              </button>
                            </div>
                            {this.state.isFileRefresh &&
                              <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="delete-user"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete User</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to Delete this user?</h6>
                <div className="form-group">
                  {/* <label>Reason</label> */}
                  {/* <textarea
                    name="reason"
                    className="form-control"
                    onChange={e => this.handleChange(e)}
                    value={this.state.reason}
                    placeholder="Reason"
                  /> */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.deleteUser}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"

                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > Ok</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                <div className="modal-footer" style={{ marginTop: '27px' }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={this.state.isLoading}
                    onClick={this.outimeGet}
                    style={{ width: '76px', fontSize: '17px' }}
                  >{this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : "OK"}</button>
                  <button
                    type="button"
                    className="btn btn-outline"
                    onClick={this.BacktoFile}
                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                  > Back To File</button>
                </div>
              </div>
            </div>
          </div>
          {/* OTHER REASON MODAL */}
          <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog ">
              <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
                <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                  {/* <div className="col-md-5"> */}
                  <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                    <textarea
                      className="form-control"
                      placeholder="Type Your Reason"
                      name="outreason" onChange={this.onchange} />
                    {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                  </div>
                  {/* </div> */}

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.outimeGet()}
                    style={{ width: '76px', fontSize: '17px' }}
                    disabled={this.state.isLoading}
                  > {this.state.isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : "OK"}</button>
                  <button
                    type="button"
                    className="btn btn-outline"

                    onClick={() => this.previousPop()}
                    // onClick={this.disableModalHandler}
                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                  > Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ManageUser;
