import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Login from "./components/login/Login";
import VisitorAdd from "./components/login/VisitorAdd";
import PrivateRoutes from "./PrivateRoutes";
import ScrutinyForm from "./components/work-file/ScrutinyForm";
import ECForm from "./components/work-file/ECForm";
import GeneralDetailForm from "./components/work-file/GeneralDetailForm";
import OverViewFullWidth from "./components/work-file/OverviewFullWidth";
import WorkFileQA from "./components/work-file/WorkFileQA";
import WorkFileDetails from "./components/work-file/WorkFileDetails";
import VerificationList from "./components/workFileVerificationList/verificationList";
import Flowchartaudio from "./components/login/flow_audio";
import Forgetpassword from "./components/login/ForgotPassword";
import ForgetpasswordOtp from "./components/login/ForgotPassword";

class Routes extends React.Component {
  isAuthenticated = () => {
    const user = localStorage.getItem("user");
    const authkey = localStorage.getItem("authkey");
    return !!user && !!authkey;
  };
  public render() {
    return (
      <Router>
        <Switch>
          {/* Root path redirect */}
          <Route exact path="/">
            {this.isAuthenticated() ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          {/* Public routes */}
          <Route path="/login" render={(props) => (
            this.isAuthenticated() ? (
              <Redirect to="/dashboard" />
            ) : (
              <Login {...props} />
            )
          )} />
          <Route path="/forgot-password" component={ForgetpasswordOtp} />
          <Route path="/visitoradd" component={VisitorAdd} />
          <Route path="/flowchartaudio/:id" component={Flowchartaudio} />
          <Route
            path="/general-detail/:fileid/:id/:docid"
            component={GeneralDetailForm}
          />
          <Route path="/scrutiny/:fileid/:id/:tabId" component={ScrutinyForm} />
          <Route path="/ec-form/:fileid/:id/:tabId" component={ECForm} />
          <Route path="/overview/:id" component={OverViewFullWidth} />
          <Route exact path="/work-file-qa/:id" component={WorkFileQA} />
          <Route exact path="/work-file-details/:id" component={WorkFileDetails} />
          <Route path="/work-file-verification-list/:id" component={VerificationList} />
          <AuthGuard exact component={PrivateRoutes} />
        </Switch>
      </Router>
    );
  }
}
export default Routes;
