import moment from "moment";
import React from "react";
import { callApi } from "../../utils/api";
import { notifyToUser } from "../../utils/firebase";
import { MANAGER_ROLE } from "../../utils/role";
import { Branch } from "../branch/ManageBranch";
import { User } from "../user/ManageUser";
import { pushnotificationApi } from "../../utils/pushNotify";
interface AllProps {
  assignedFrom: string;
  fileToBeAssigned: number;
  getFiles: any;
}
class AssignBankUser extends React.Component<AllProps> {
  state = {
    userName: false,
    bankName: false,
    user: 0,
    branch: 0,
    branches: [],
    users: [],
    filteredUsers: [],
    reason: "",
    view_by: null,
    verified_by: null,
  };
  componentDidMount() {
    this.listUser();
  }

  listBranches = () => {
    callApi("GET", "get_branch.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ branches: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  listUser = () => {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ users: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleBranchChange = (e) => {
    const branch = e.target.value;
    const filteredUsers = this.state.users.filter(
      (itm: any) => itm.branch === branch
    );
    this.setState({ filteredUsers, branch });
  };
  handleUserChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  assignFile = () => {
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    if (this.state.user) {
      const bankName = localStorage.getItem("notiBank");
      const user: any = localStorage.getItem("user");
      const userId = user ? JSON.parse(user)["userId"] : "";
      const userName = user ? JSON.parse(user)["firstName"] : "";
      const loginuser: any = localStorage.getItem("user1");
      const notifyData = {
        title: `${this.props.fileToBeAssigned} File Assigned`,
        fileId: this.props.fileToBeAssigned,
        assignedTo: this.state.user,
        assignedBy: userId,
        notifyType: "message",
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      let user_id = userId;

      let obj;
      if (this.props.assignedFrom == "assign") {
        obj = {
          reassign_time: currenttime,
          reassign_date: currentdate,
          reassign_by: JSON.parse(loginuser),
          id: this.props.fileToBeAssigned,
          reason: "Reassign",
          created_by: JSON.parse(loginuser),
          color: "#edada0"
        }
        callApi("POST", "time_update.php", obj)
          .then(res => res.data)
          .then(response => {

          });
      } else {
        obj = {
          assign_time: currenttime,
          assign_date: currentdate,
          assign_by: JSON.parse(loginuser),
          id: this.props.fileToBeAssigned,
          reason: "Assign",
          created_by: JSON.parse(loginuser),
          color: "#edada0"
        }
        callApi("POST", "time_update.php", obj)
          .then(res => res.data)
          .then(response => {

          });
      }
      let req = {
        type: "FileAssigned",
        notification_type: "assigned",
        user_id: this.state.user,
        ...notifyData,
      }

      callApi("POST", "pushnotification.php", req)
        .then((res) => res.data)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        });
      notifyToUser(
        "user",
        {
          ...notifyData,
          notifyType: "response",
          updateType: "assign-reason",
          updateData: JSON.stringify({
            userId,
            assigned_to: this.state.user,
            view_by: this.state.view_by,
            verified_by: this.state.verified_by,
            id: this.props.fileToBeAssigned,
            reassign: this.props.assignedFrom === "unassign" ? 0 : 1,
          }),
          updateReasonData: JSON.stringify({
            userId,
            action: "ressign",
            reason: this.state.reason,
            fod_fk: this.props.fileToBeAssigned,
          }),
          assignedFromProps: this.props.assignedFrom,
        },
        [this.state.user] //assigned to
      );

      const branchManger: any = localStorage.getItem("branch_manager");
      const branch_Manager = JSON.parse(branchManger);
      const queryOfficer: any = localStorage.getItem("Query_officer");
      const query_ooficer = JSON.parse(queryOfficer);
      if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
        notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
        const branchManager = branch_Manager.join(",");
        let req = {
          notification_type: "branchmanger",
          user_id: branchManager,
          ...notifyData
        }
        callApi("POST", "pushnotification.php", req)
          .then((res) => res.data)
          .then((response) => {
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
        notifyToUser("user", { ...notifyData, notifyType: "message" }, query_ooficer);
        console.log("119", ...query_ooficer);
        const queryofficer = query_ooficer.join(",");

        let req = {
          notification_type: "queryofficer",
          user_id: queryofficer,
          ...notifyData,
        }
        callApi("POST", "pushnotification.php", req)
          .then((res) => res.data)
          .then((response) => {
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      console.log("not Assign");
    }
    if (this.state.verified_by) {
      const bankName = localStorage.getItem("notiBank");
      const user: any = localStorage.getItem("user");
      const userId = user ? JSON.parse(user)["userId"] : "";
      const userName = user ? JSON.parse(user)["firstName"] : "";
      const loginuser: any = localStorage.getItem("user1");
      const notifyData = {
        title: `${this.props.fileToBeAssigned} File Verification Assigned`,
        fileId: this.props.fileToBeAssigned,
        assignedTo: this.state.user,
        assignedBy: userId,
        notifyType: "message",
        updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      let obj;
      if (this.props.assignedFrom == "assign") {
        obj = {
          reassign_time: currenttime,
          reassign_date: currentdate,
          reassign_by: JSON.parse(loginuser),
          id: this.props.fileToBeAssigned,
          reason: "Reassign",
          created_by: JSON.parse(loginuser),
          color: "#edada0"
        }
        callApi("POST", "time_update.php", obj)
          .then(res => res.data)
          .then(response => {

          });
      } else {
        obj = {
          assign_time: currenttime,
          assign_date: currentdate,
          assign_by: JSON.parse(loginuser),
          id: this.props.fileToBeAssigned,
          reason: "Assign",
          created_by: JSON.parse(loginuser),
          color: "#edada0"
        }
        callApi("POST", "time_update.php", obj)
          .then(res => res.data)
          .then(response => {

          });
      }
      let req = {
        notification_type: "verifyassign",
        user_id: this.state.verified_by,
        ...notifyData,
      }
      callApi("POST", "pushnotification.php", req)
        .then((res) => res.data)
        .then((response) => {
        })
        .catch((err) => {
          console.log(err);
        });

      notifyToUser(
        "user",
        {
          ...notifyData,
          notifyType: "response",
          updateType: "assign-reason",
          updateData: JSON.stringify({
            userId,
            assigned_to: this.state.user,
            view_by: this.state.view_by,
            verified_by: this.state.verified_by,
            id: this.props.fileToBeAssigned,
            reassign: this.props.assignedFrom === "unassign" ? 0 : 1,
          }),
          updateReasonData: JSON.stringify({
            userId,
            action: "ressign",
            reason: this.state.reason,
            fod_fk: this.props.fileToBeAssigned,
          }),
          assignedFromProps: this.props.assignedFrom,
        },
        [this.state.verified_by] //assigned to
      );

      const branchManger: any = localStorage.getItem("branch_manager");
      const branch_Manager = JSON.parse(branchManger);
      const queryOfficer: any = localStorage.getItem("Query_officer");
      const query_ooficer = JSON.parse(queryOfficer);
      if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
        notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
        const branchManager = branch_Manager.join(",");
        let req = {
          notification_type: "vassigned",
          user_id: branchManager,
          ...notifyData
        }
        callApi("POST", "pushnotification.php", req)
          .then((res) => res.data)
          .then((response) => {
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
        notifyToUser("user", { ...notifyData, notifyType: "message" }, query_ooficer);
        const queryofficer = query_ooficer.join(",");
        let req = {
          // notification_type:"vqassigned",
          user_id: queryofficer,
          ...notifyData
        }
        callApi("POST", "pushnotification.php", req)
          .then((res) => res.data)
          .then((response) => {
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      console.log("not Assign");
    }
  };
  bankName = (type: any) => {
    if (type == 2) {
      this.setState({ bankName: true })
      this.listBranches();
    } else {

    }
  }
  userName = (type: any) => {
    if (type == 2) {
      this.setState({ userName: true })
      this.listUser();
    } else {

    }
  }

  render() {
    const { userName, bankName } = this.state;
    return (
      <div
        className="modal fade"
        id="assign-modal"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Assigned</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="col-form-label">Branch:</label>
                  <select
                    className="custom-select"
                    name="bank_name"
                    onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                    onChange={(e) => this.handleBranchChange(e)}
                  >
                    <option value="">Select the Branch</option>
                    {this.state.branches && this.state.branches.length
                      ? this.state.branches.map((itm: Branch) => {
                        return (
                          <option value={itm.name} key={itm.id}>
                            {itm.name}
                          </option>
                        );
                      })
                      : ""}
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Assigned To:</label>
                  <select
                    className="custom-select"
                    name="user"
                    onClick={userName ? () => this.userName(1) : () => this.userName(2)}
                    onChange={(e) => this.handleUserChange(e)}
                  >
                    <option value="">Select the Person</option>
                    {this.state.branches && this.state.branches.length
                      ? this.state.filteredUsers.map((itm: User) => {
                        return <option value={itm.id}>{itm.name}</option>;
                      })
                      : ""}
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-form-label">View By:</label>
                  <select
                    className="custom-select"
                    name="view_by"
                    onChange={(e) => this.handleUserChange(e)}
                  >
                    <option value="">Select the Person</option>
                    {this.state.branches && this.state.branches.length
                      ? this.state.filteredUsers.map((itm: User) => {
                        return <option value={itm.id}>{itm.name}</option>;
                      })
                      : ""}
                  </select>
                </div>
                {/* <div className="form-group">
                  <label className="col-form-label">Verified By:</label>
                  <select
                    className="custom-select"
                    name="verified_by"
                    onChange={(e) => this.handleUserChange(e)}
                  >
                    <option value="">Select the Person</option>
                    {this.state.branches && this.state.branches.length
                      ? this.state.filteredUsers.map((itm: User) => {
                          return <option value={itm.id}>{itm.name}</option>;
                        })
                      : ""}
                  </select>
                </div> */}
                {this.props.assignedFrom !== "unassign" && (
                  <div className="form-group">
                    <label>Reason</label>
                    <textarea
                      name="reason"
                      className="form-control"
                      onChange={(e) => this.handleUserChange(e)}
                      value={this.state.reason}
                      placeholder="Reason"
                    />
                  </div>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.assignFile}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AssignBankUser;
