import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faExclamationCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoone from "../../assets/images/lawlogo.png";
import { callApi } from "../../utils/api";
import "./Forgetpassword.css";
import "./OtpPage.css";
import { withRouter } from "react-router-dom";

interface State {
    email: string;
    emailError: string;
    otp: string[];
    timer: number;
    isResendVisible: boolean;
    showOtpPage: boolean;
    showPasswordPage: boolean;
    password: string;
    confirmPassword: string;
    passwordError: string;
    confirmpasswordError: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
    isButtonDisabled: boolean;
    isOtpButtonDisabled: boolean;
    isResendButtonDisabled: boolean;
    isPasswordButtonDisbled: boolean;
}

class ForgetpasswordOtp extends Component<any, State> { 
    timerInterval: NodeJS.Timeout | null = null;

    constructor(props: {}) {
        super(props);
        this.state = {
            email: "",
            emailError: "",
            otp: ["", "", "", ""],
            timer: 60,
            isResendVisible: false,
            showOtpPage: false,
            showPasswordPage: false,
            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmpasswordError: "",
            showPassword: false,
            showConfirmPassword: false,
            isButtonDisabled: false,
            isOtpButtonDisabled: false,
            isResendButtonDisabled: false,
            isPasswordButtonDisbled: false,
        };
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: e.target.value, emailError: "" });
    };

    handleClick = () => {
        const { email } = this.state;
        let error = "";

        if (!email) {
            error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            error = "Invalid email format";
        }

        if (error) {
            this.setState({ emailError: error });
            return;
        }

        let req = { email };
        this.setState({ isButtonDisabled: true });
        callApi("POST", "forgotPassword.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (response.success) {
                    toast.success("Mail Sent Successfully!", { autoClose: 1500 });
                    this.setState({ showOtpPage: true });
                    this.setState({ isButtonDisabled: false });
                    this.startTimer();
                } else {
                    this.setState({ isButtonDisabled: false });
                    toast.error("Email does not match!", { autoClose: 1500 });
                }
            })
            .catch((err) => console.log(err));
    };

    startTimer = () => {
        this.setState({ timer: 60, isResendVisible: false });

        this.timerInterval = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.timer <= 1) {
                    clearInterval(this.timerInterval!);
                    return { timer: 0, isResendVisible: true }; // Explicitly return `isResendVisible`
                }
                return { timer: prevState.timer - 1, isResendVisible: prevState.isResendVisible }; // Ensure it's always included
            });
        }, 1000);
    };


    handleChangeOtp = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let newOtp = [...this.state.otp];
        newOtp[index] = e.target.value;
        this.setState({ otp: newOtp });

        if (e.target.value && index < 3) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
            if (nextInput) nextInput.focus();
        }
    };

    handleOtpSubmit = () => {
        const enteredOtp = this.state.otp.join("");
        if (enteredOtp.length < 4) {
            toast.error("Please enter a 4-digit OTP", { autoClose: 1500 });
            return;
        }

        let req = { email: this.state.email, otp: enteredOtp };
        this.setState({ isOtpButtonDisabled: true });
        callApi("POST", "loginOtpVerify.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (response.success) {
                    this.setState({ isOtpButtonDisabled: false });
                    toast.success("OTP Verified Successfully!", { autoClose: 1500 });
                    this.setState({ showOtpPage: false, showPasswordPage: true });
                } else {
                    this.setState({ isOtpButtonDisabled: false });
                    toast.error("Invalid OTP!", { autoClose: 1500 });
                     this.setState({ otp: ["", "", "", ""] });
                }
            })
            .catch((err) => console.log(err));
    };

    handleResend = () => {

        let req = { email: this.state.email };
        this.setState({ isResendButtonDisabled: true });
        callApi("POST", "forgotPassword.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (response.success) {
                    this.setState({ isResendButtonDisabled: false });
                    this.startTimer();
                    toast.success("OTP Resent Successfully!", { autoClose: 1500 });
                } else {
                    this.setState({ isResendButtonDisabled: false });
                    toast.error("Email does not match!", { autoClose: 1500 });
                }
            })
            .catch((err) => console.log(err));

    };

    handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: e.target.value, passwordError: "" });
    };

    handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ confirmPassword: e.target.value, passwordError: "" });
    };

    handlePasswordSubmit = () => {
        const { password, confirmPassword, email } = this.state;
    
        // Password validation regex (1 uppercase, 1 number, 1 special char, 3 any chars, min 6 length)
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    
        if (!password || !confirmPassword) {
            this.setState({ passwordError: "Password fields cannot be empty" });
            return;
        }
    
        if (!passwordRegex.test(password)) {
            this.setState({ 
                passwordError: "Password must have at least 6 characters, including 1 uppercase letter, 1 number, 1 special character, and at least 3 other characters."
            });
            return;
        }
    
        if (password !== confirmPassword) {
            this.setState({ passwordError: "Passwords do not match" });
            return;
        }
    
        let req = { email, confirmPassword };
        this.setState({ isPasswordButtonDisbled: true });
    
        callApi("POST", "updatePassword.php", req)
            .then((res) => res.data)
            .then((response) => {
                this.setState({ isPasswordButtonDisbled: false });
    
                if (response.success) {
                    toast.success("Password Reset Successfully!", { autoClose: 1500 });
                    this.props.history.push("/login");
                } else {
                    toast.error("Password Reset Failed!", { autoClose: 1500 });
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isPasswordButtonDisbled: false });
            });
    };
    
    

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
    };

    toggleConfirmPasswordVisibility = () => {
        this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
    };

    render() {
        return (
            <div className="page-container">
                {/* <ToastContainer position="top-center" autoClose={1000} hideProgressBar /> */}
                {!this.state.showOtpPage && !this.state.showPasswordPage && (
                    <div className="forgot-password-card">
                        <div className="header-section">
                            <img src={logoone} alt="Logo" className="brand-logo" />
                            <h2 className="main-heading">Forgot your Password?</h2>
                            <p className="instruction-text">Enter your email address to recover your password</p>
                        </div>
                        <div className="form-content">
                            <div className="email-input-container">
                                <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
                                <input type="email" name="email" placeholder="Email address" className="email-input" onChange={this.handleChange} />
                            </div>
                            {this.state.emailError && <div className="error-message">{this.state.emailError}</div>}
                            <button className="submit-button btn btn-primary " disabled={this.state.isButtonDisabled} onClick={this.handleClick}>Request password link</button>
                        </div>
                    </div>
                )}

                {this.state.showOtpPage && (
                    <div className="otp-card">
                        <img src={logoone} alt="Logo" className="brand-logo" />
                        <h2 className="otp-heading">Enter OTP</h2>
                        <div className="otp-inputs">
                            {this.state.otp.map((data, index) => (
                                <input
                                    key={index}
                                    id={`otp-input-${index}`}
                                    type="text"
                                    maxLength={1}
                                    className="otp-input"
                                    value={this.state.otp[index]}
                                    onChange={(e) => this.handleChangeOtp(e, index)}
                                />
                            ))}
                        </div>
                        <button className="submit-button" disabled={this.state.isOtpButtonDisabled} onClick={this.handleOtpSubmit}>Verify OTP</button>
                        <p className="timer-text">{this.state.timer > 0 ? `Resend OTP in ${this.state.timer}s` : ""}</p>
                        {this.state.isResendVisible && <button className="resend-button" disabled={this.state.isResendButtonDisabled} onClick={this.handleResend}>Resend OTP</button>}
                    </div>
                )}

                {this.state.showPasswordPage && (
                    <div className="password-card">
                        <img src={logoone} alt="Logo" className="brand-logo" />
                        <h2 className="password-heading">Reset Password</h2>
                        <div className="password-field">
                            <input type={this.state.showPassword ? "text" : "password"} placeholder="New Password" required onChange={this.handlePasswordChange} />
                            <FontAwesomeIcon icon={this.state.showPassword ? faEye : faEyeSlash} onClick={this.togglePasswordVisibility} />
                        </div>
                        <div className="password-field">
                            <input type={this.state.showConfirmPassword ? "text" : "password"} placeholder="Confirm Password" required onChange={this.handleConfirmPasswordChange} />
                            <FontAwesomeIcon icon={this.state.showConfirmPassword ? faEye : faEyeSlash} onClick={this.toggleConfirmPasswordVisibility} />
                        </div>
                        {this.state.passwordError && <div className="error-message">{this.state.passwordError}</div>}
                        <button className="submit-button" disabled={this.state.isPasswordButtonDisbled} onClick={this.handlePasswordSubmit}>Reset Password</button>
                    </div>
                 )} 
            </div>
        );
    }
}

export default withRouter(ForgetpasswordOtp);
