import moment from "moment";
import React from "react";
import { callApi } from "../../utils/api";
import { notifyToUser } from "../../utils/firebase";
import { Branch } from "../branch/ManageBranch";
import { User } from "../user/ManageUser";

interface AllProps {
  assignedFrom: string;
  fileToBeVerified: number;
  getFiles: any;
}
class VerifyFileModal extends React.Component<AllProps> {
  state = {
    user: 0,
    branch: 0,
    branches: [],
    users: [],
    filteredUsers: [],
    reason: "",
    view_by: null,
    verified_by: "",
  };
  componentDidMount() {
    this.listBranches();
    this.listUser();
  }
  listBranches = () => {
    callApi("GET", "get_branch.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ branches: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  listUser = () => {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ users: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleBranchChange = (e) => {
    const branch = e.target.value;
    const filteredUsers = this.state.users.filter(
      (itm: any) => itm.branch === branch
    );
    this.setState({ filteredUsers, branch });
  };
  handleUserChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  verifyFile = () => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const notifyData = {
      fileId: this.props.fileToBeVerified,
      notifyType: "response",
      title: `${this.props.fileToBeVerified} Verification Assigned `, 
    };
    let obj={
      ...notifyData,
      type:"VerificationAssigned",
      notification_type:"verificationassign",
      user_id: this.state.verified_by,
      updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
    callApi("POST", "pushnotification.php",obj)
    .then((res) => res.data)
    .then((response) => {
    })
    .catch((err) => {
      console.log(err);
    });
    notifyToUser(
      "user",
      {
        ...notifyData,
        notifyType: "response",
        updateType: "verify",
        updateData: JSON.stringify({
          userId,
          verified_by: this.state.verified_by,
          id: this.props.fileToBeVerified,
        }),
      },
      [this.state.verified_by]
    );
    const branchManger:any= localStorage.getItem("branch_manager");
    const branch_Manager = JSON.parse(branchManger);
    const queryOfficer:any= localStorage.getItem("Query_officer");
    const query_ooficer =JSON.parse(queryOfficer);
    const Scan_officer:any= localStorage.getItem("scan_officer");
    const sacanofficer =JSON.parse(Scan_officer);
    const process_mennager:any= localStorage.getItem("process_manager");
    const processManager =JSON.parse(process_mennager);
    if(Array.isArray(branch_Manager) && branch_Manager.length > 0){
      notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);



    }
    if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
      notifyToUser("user", { ...notifyData, notifyType: "notify" }, query_ooficer);
    }
    if (Array.isArray(processManager) && processManager.length > 0) {
      notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
    }
    

    // @TODO ALERT
  };
  submitReason = () => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "reason_create.php", {
      userId,
      action: "ressign",
      reason: this.state.reason,
      fod_fk: this.props.fileToBeVerified,
    })
      .then((res) => res.data)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div
        className="modal fade"
        id="verify-modal"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Verify File</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label className="col-form-label">Branch:</label>
                  <select
                    className="custom-select"
                    name="bank_name"
                    onChange={(e) => this.handleBranchChange(e)}
                  >
                    <option value="">Select the Branch</option>
                    {this.state.branches && this.state.branches.length
                      ? this.state.branches.map((itm: Branch) => {
                          return (
                            <option value={itm.name} key={itm.id}>
                              {itm.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Verified By:</label>
                  <select
                    className="custom-select"
                    name="verified_by"
                    onChange={(e) => this.handleUserChange(e)}
                  >
                    <option value="">Select the Person</option>
                    {this.state.branches && this.state.branches.length
                      ? this.state.filteredUsers.map((itm: User) => {
                          return <option value={itm.id}>{itm.name}</option>;
                        })
                      : ""}
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.verifyFile}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VerifyFileModal;