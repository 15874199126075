import React, { Component } from "react";
import ReactFileReader from "react-file-reader";
import { callApi } from "../../utils/api";
import { cloneDeep } from "lodash";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import moment from "moment";
import { notifyToUser } from "../../utils/firebase";
import { FRONTOFFICE_ROLE, MANAGER_ROLE } from "../../utils/role";
import { pushnotificationApi } from "../../utils/pushNotify";
import Select from 'react-select';
declare var $: any;
interface Query {
  query: string;
  review: string;
  location: string;
  other_review: string;
  created_date: String;
}
interface AllProps {
  match: any;
  history: any;
}
export default class AddQuery extends Component<AllProps> {
  state = {
    querydetail: [],
    office_users: [],
    remote_users: [],
    remote_cc_users: [],
    isLoading: false,
    base: [],
    docToPreview: "",
    queryLoading: false,
    queryQues: [],
    oldQueryQues: [],
    quesSubmitted: false,
    documentBaseList: [],
    reasons: [],
    required: false
  };
  componentDidMount() {

    this.addItem();
    this.getFileDetail();
    this.liveinsert();
    this.listreason();
    // if (localStorage.getItem("BankTemplate")) {
    //   var bankTemp: any = localStorage.getItem("BankTemplate");
    //   this.setState({ bank_templates: JSON.parse(bankTemp) })
    // } else {
    //   this.getTemplates();
    // }
  }
  getTemplates = () => {
    callApi("POST", "bank_temp_get.php")
      .then(res => res.data)
      .then(response => {
        let banks = response.data;
        const vetting = banks.filter(item => item.name.split(" ").pop() != "Vetting");
        this.setState({ bank_templates: vetting });
        var bankTempName = [];
        bankTempName = vetting;
        // console.log(localStorage.getItem("BankTemplate"));
      })
      .catch(err => {
        console.log(err);
      });
  };
  listreason = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "query_reason_get.php")
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ reasons: files });
      })
      .catch(err => {
        console.log(err);
      });
  };
  validateQuery = () => {
    let base: any = this.state.querydetail;
    let isValid = true;
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (document.review == "other") {
        if (!document.query || !document.review || !document.other_review) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else {
        if (!document.query || !document.review) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      }
    }
    return isValid;
  };
  showQueryquestion = () => {
    this.setState({ required: true });
    let isValid = this.validateQuery();
    if (isValid) {
      $("#myModal").modal("show");
    } else {
      ToastsStore.error("Please fill all the required fields");
    }
    setTimeout(() => {
      console.log("querydetail", this.state.querydetail);
    }, 1000);
    this.getUsers();
    this.getQueryQuestion();
  }
  liveinsert = () => {

    const user: any = localStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["team_type"] : "";
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    console.log("214", role);
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const bankName: any = localStorage.getItem("bankName");
    const fileNamCode: any = localStorage.getItem("fileNamCode");
    const in_reason: any = localStorage.getItem("in_reason");
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    console.log("221", curentTime);
    const textRay = `For Query Raise To Open  : ${this.props.match.params.fileid}`;
    let obj = {
      file_no: fileNamCode,
      in_time: curentTime,
      reason: in_reason,
      user_id: userId,
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: textRay,
      bank_name: bankName,
    }
    console.log("231", obj);
    callApi("POST", "live_track_insert.php", obj)
      .then(res => res.data)
      .then(response => {

      })
  }
  getFileDetail = () => {
    callApi("POST", "front_office_get.php", {
      id: this.props.match.params.fileid,
    })
      .then((res) => res.data)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.length &&
          response.data[0].arr &&
          response.data[0].arr.length
        ) {
          const state = response.data[0].arr[0];
          state.base = response.data[0].base
            ? response.data[0].base.map((base) => {
              return {
                ...base,
                isSavedFile: true,
              };
            })
            : [];
          this.setState({ documentBaseList: state.base });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUsers = () => {
    const remote_users: any = [];
    callApi("POST", "office_details.php", {
      id: this.props.match.params.fileid,
    })
      .then((res) => res.data)
      .then((response) => {
        response.data.map((user) => {
          remote_users.push({ email: user.email, name: user.name });
        });
        this.setState({
          office_users: response.data,
          remote_users,
          remote_cc_users: remote_users,
        });
      });
  };
  getQueryQuestion = () => {
    callApi("POST", "query_question_get.php", {
      id: this.props.match.params.fileid,
    })
      .then((res) => res.data)
      .then((response) => {
        console.log(response);
        this.setState({
          queryQues: response.data,
          oldQueryQues: JSON.parse(JSON.stringify(response.data)),
        });
      });
  };
  addItem = (): void => {
    const querydetail: Query[] = this.state.querydetail
      ? this.state.querydetail
      : [];
    querydetail.push({
      query: "",
      review: "",
      location: "",
      other_review: "",
      created_date: moment().format("DD-MM-YYYY HH:mm:ss"),
    });
    this.setState({ querydetail });
  };
  removeItem = (index: number): void => {
    const querydetail: Query[] = this.state.querydetail;
    querydetail.splice(index, 1);
    this.setState({ querydetail });
  };
  handleChange = (e: any, index: number) => {
    const querydetail: Query[] = this.state.querydetail;
    querydetail.forEach((itm: any, i) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
        console.log(`value: ${e.target.value}--name: ${e.target.name}`);
      }
      console.log(itm);
      console.log(this.state.querydetail);
    });
    this.setState({ querydetail });
  };
  handleDetailsChange = (value, index, key) => {
    console.log("value--->>", this.state.querydetail);
    let baseArray = cloneDeep(this.state.querydetail);
    baseArray[index] = {
      ...baseArray[index],
      [key]: value,
    };
    this.setState({ querydetail: baseArray }, () => {
      console.log("value--->>", this.state.querydetail);
    });
  };
  selectUser = (e: any, user: any) => {
    let remote_users: any = this.state.remote_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users = remote_users.filter((itm) => itm.email !== user.email);
    }
    this.setState({ remote_users });
  };
  selectCcUser = (e: any, user: any) => {
    let remote_users: any = this.state.remote_cc_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users = remote_users.filter((itm) => itm.email !== user.email);
    }
    this.setState({ remote_cc_users: remote_users });
  };
  saveQuery = () => {
    console.log("this. state", this.state);
    this.props.match.params.id = Number(this.props.match.params.id);
    const queryInput = Object.create({});
    queryInput.querydetail = this.state.querydetail;
    queryInput.remote_users = this.state.remote_users;
    queryInput.remote_cc_users = this.state.remote_cc_users;
    const user: any = localStorage.getItem("user");
    queryInput.user_idfk = user ? JSON.parse(user)["userId"] : "";
    const report_to = user ? JSON.parse(user)["report_to"] : "";
    const firstName = user ? JSON.parse(user)["firstName"] : "";

    queryInput.fod_fk = this.props.match.params.fileid;
    queryInput.query_id = this.props.match.params.queryid;
    queryInput.base = this.state.base;
    this.setState({ queryLoading: true });
    callApi("POST", "query_create.php", queryInput)
      .then((res) => res.data)
      .then((response) => {
        localStorage.setItem("query_raised_status", JSON.stringify(true));
        this.timeUpdate(this.props.match.params.fileid);
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const userName = user ? JSON.parse(user)["firstName"] : "";
        const notifyData = {
          title: `${this.props.match.params.fileid}Query Raised`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
        };
        let user_id = userId;
        pushnotificationApi(notifyData, user_id);
        const branchManger: any = localStorage.getItem("branch_manager");
        const branch_Manager = JSON.parse(branchManger);
        const queryOfficer: any = localStorage.getItem("Query_officer");
        const query_ooficer = JSON.parse(queryOfficer);
        const Scan_officer: any = localStorage.getItem("scan_officer");
        const sacanofficer = JSON.parse(Scan_officer);
        const process_mennager: any = localStorage.getItem("process_manager");
        const processManager = JSON.parse(process_mennager);
        if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
          notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
          const branchManager = branch_Manager.join(",");
          let obj = {
            // notification_type:"queryraised",
            user_id: branchManager,
            ...notifyData
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
          notifyToUser("user", { ...notifyData, notifyType: "notify" }, query_ooficer);
          const OfficerQuery = query_ooficer.join(",");
          let obj = {
            // notification_type:"queryraised",
            user_id: OfficerQuery,
            ...notifyData
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (Array.isArray(processManager) && processManager.length > 0) {
          notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
          const ProcessManager = processManager.join(",");
          let obj = {
            // notification_type:"queryraised",
            user_id: ProcessManager,
            ...notifyData
          }
          callApi("POST", "pushnotification.php", obj)
            .then((res) => res.data)
            .then((response) => {
            })
            .catch((err) => {
              console.log(err);
            });
        }
        //  notifyToUser("user",{...notifyData,notifyType:'message'},[FRONTOFFICE_ROLE]);
        this.setState({ queryLoading: false, quesSubmitted: false });
        $("#myModal").modal("hide");
        ToastsStore.success("Queries added Successully");
        // if (this.props.match.params.fileid) {
        //   this.props.history.push(
        //     "/file-dashboard/" + this.props.match.params.fileid
        //   );
        // } else {
        //   this.props.history.push(
        //     `/file-dashboard/${this.props.match.params.fileid}`
        //   );
        // }
        // localStorage.removeItem("currentfileId")
        setTimeout(() => {
          this.props.history.push("/inprogress-file");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ queryLoading: false, quesSubmitted: true });
        // $("#myModal").modal("hide");
        ToastsStore.error("Something went Wrong, Please try again!");
      });
  };
  timeUpdate = (fileid) => {
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      query_raised_by: JSON.parse(loginuser),
      id: fileid,
      reason: "Query Raised",
      created_by: JSON.parse(loginuser),
      color: "#afa5bd"
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }

  handleFiles = (files: any) => {
    let baseFile: any = this.state.base || [];
    const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
    if (Number(fileSize) > 26214400) {
      ToastsStore.error("File(s) size shouldn't exceed 25 MB");
    } else {
      files.base64.map((file: any, index: number) => {
        baseFile.push({
          file,
          fileType: files.fileList[index].type,
          fileName: files.fileList[index].name,
        });
      });
      this.setState({ base: [...baseFile] });
    }
  };

  deleteDoc = (modal, index) => {
    const baseFile: never[] = cloneDeep(this.state.base);
    baseFile.splice(index, 1);
    this.setState({ base: [...baseFile] });
  };

  previewDoc = (modal) => {
    this.setState({ docToPreview: modal.file });
  };

  handleAnswerChange = (index, val) => {
    const queryQues: any = this.state.queryQues;
    queryQues[index].answer = val;
    this.setState({ queryQues });
  };
  saveQueryQuestion = () => {
    const { queryQues, oldQueryQues } = this.state;
    const user: any = localStorage.getItem("user");
    const obj = {
      fileNo: this.props.match.params.fileid,
      currentData: queryQues,
      oldData: oldQueryQues,
      loginId: user ? JSON.parse(user)["userId"] : "",
    };
    console.log(obj);

    callApi("POST", "queryverification_checklist.php", obj)
      .then((res) => res.data)
      .then((response) => {
        console.log(response);
        this.setState({ quesSubmitted: true });
      })
      .catch((err) => { });
  };
  render() {
    const { queryQues, quesSubmitted, documentBaseList } = this.state;
    const banktemplatesoption = [
      ...this.state.reasons,
      { value: "other", label: "Other" }
    ];
    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div
                      className={`col-sm-${documentBaseList && documentBaseList.length > 0
                        ? "8"
                        : "12"
                        }`}
                    >
                      <div className="card">
                        <div className="card-header">
                          <h5>Query Post</h5>
                        </div>
                        <div className="card-body">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.addItem}
                            >
                              <i className="feather icon-plus" />
                              ADD
                            </button>
                          </div>
                          {this.state.querydetail &&
                            this.state.querydetail.length
                            ? this.state.querydetail.map(
                              (queryItem: Query, index: number) => {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label>Query</label>{" "}
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="query"
                                          placeholder=""
                                          value={queryItem.query}
                                          onChange={(e) =>
                                            this.handleChange(e, index)
                                          }
                                        />
                                      </div>
                                      {this.state.required && !queryItem.query && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Reason</label>
                                        <div className="query-template-select">
                                          {this.state.reasons && this.state.reasons.length > 0 && this.state.reasons.map((opt: any) => {
                                            opt.label = opt.reason; opt.value = opt.reason
                                          }) && (
                                              <Select
                                                options={banktemplatesoption}
                                                name="review"
                                                onChange={(selectedOption) =>
                                                  this.handleDetailsChange(selectedOption.value, index, 'review')
                                                }
                                                value={this.state.reasons.find(
                                                  (o: any) => o.value === queryItem.review
                                                )}
                                              />
                                            )}
                                        </div>
                                        {this.state.required && !queryItem.review && <p style={{ color: "red", fontSize: "14px", marginTop: "-10px" }}>Required field.</p>}
                                        {queryItem.review === "other" ? (
                                          <>
                                            <div className="">
                                              <label>Other Reason</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="other_review"
                                                placeholder=""
                                                value={queryItem.other_review}
                                                onChange={(e) => this.handleChange(e, index)}
                                              />
                                            </div>
                                            {this.state.required && !queryItem.other_review && <p style={{ color: "red", fontSize: "14px" }}>Required field.</p>}
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label>Location</label>{" "}
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="location"
                                          placeholder=""
                                          value={queryItem.location}
                                          onChange={(e) =>
                                            this.handleChange(e, index)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-rounded btn-danger"
                                        style={{
                                          marginTop: "35px",
                                        }}
                                        disabled={
                                          this.state.querydetail.length === 1
                                        }
                                        onClick={() => this.removeItem(index)}
                                      >
                                        <i className="feather icon-minus" />
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )
                            : ""}
                          <ReactFileReader
                            fileTypes={[".pdf"]}
                            base64={true}
                            multipleFiles={true}
                            handleFiles={this.handleFiles}
                          >
                            <button
                              className="ml2 btn btn-primary btnupload float-right query-upload"
                              disabled={this.state.isLoading}
                            >
                              {this.state.isLoading ? (
                                <i className="fa fa-spinner fa-spin"></i>
                              ) : (
                                "Upload Document"
                              )}
                            </button>
                          </ReactFileReader>
                          <div className="query-document">
                            {this.state.base && this.state.base.length
                              ? this.state.base.map(
                                (itm: any, index: number) => {
                                  return (
                                    <div key={index}>
                                      <div className="card card-border-c-green">
                                        <span
                                          onClick={() =>
                                            this.deleteDoc(itm, index)
                                          }
                                        >
                                          <i className="feather icon-x cursor-pointer" />
                                        </span>
                                        <div className="card-body">
                                          <h5 className="card-title">
                                            <span className="mb-1 text-c-green">
                                              <i className="feather icon-file-text" />
                                            </span>
                                            <span>{itm.fileName || ""}</span>
                                            <span
                                              style={{ float: "right" }}
                                              className="cursor-pointer"
                                              data-toggle="modal"
                                              data-target="#preview-document"
                                              onClick={() =>
                                                this.previewDoc(itm)
                                              }
                                            >
                                              <i className="fa fa-eye" />
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                              : ""}
                          </div>
                          {this.state.docToPreview && (
                            <div
                              className={`modal ${this.state.docToPreview ? "open" : ""
                                }`}
                              id="preview-document"
                              role="dialog"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-lg"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Document Preview
                                    </h5>
                                    <button
                                      type="button"
                                      className="close cursor-pointer"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={() =>
                                        this.setState({ docToPreview: "" })
                                      }
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <iframe
                                      width="100%"
                                      height="400px"
                                      src={this.state.docToPreview}
                                    ></iframe>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                      onClick={() =>
                                        this.setState({ docToPreview: "" })
                                      }
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div>
                            <div>
                              <div className="form-group">
                                <a
                                  className="btn btn-primary md-trigger"
                                  onClick={() => this.showQueryquestion()}
                                >
                                  Submit
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {documentBaseList && documentBaseList.length > 0 && (
                      <div className="col-sm-4">
                        <div className="row">
                          <div className="dt-responsive table-responsive bg-white">
                            <table
                              id="bank-files-table"
                              className="table nowrap"
                            >
                              <thead>
                                <tr>
                                  <th style={{ background: "lightgrey" }}>
                                    S.no
                                  </th>
                                  <th
                                    className="text-center"
                                    style={{ background: "lightgrey" }}
                                  >
                                    Doc.No/Year
                                  </th>
                                  <th
                                    className="text-center"
                                    style={{ background: "lightgrey" }}
                                  >
                                    Category
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {documentBaseList &&
                                  documentBaseList.length > 0 &&
                                  documentBaseList.map((item: any, index) => (
                                    <tr>
                                      <th>{index + 1}</th>
                                      <th className="text-center">
                                        {item.docNoYear || "-"}
                                      </th>
                                      <th className="text-center text-capitalize">
                                        {item.type.replaceAll("_", " ") || "-"}
                                      </th>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="modal" id="myModal">
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Send Mail</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {!quesSubmitted && (
                            <div className="query-question">
                              <div className="row">
                                {queryQues.map((ques: any, index) => (
                                  <div className="col-md-6" key={index}>
                                    <div className="question">
                                      <p>{ques.query_question}</p>
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="radio"
                                              className=""
                                              name={`answer-${index}`}
                                              defaultChecked
                                              onChange={() =>
                                                this.handleAnswerChange(
                                                  index,
                                                  true
                                                )
                                              }
                                            />
                                            <label className="">Yes</label>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="radio"
                                              className=""
                                              name={`answer-${index}`}
                                              onChange={() =>
                                                this.handleAnswerChange(
                                                  index,
                                                  false
                                                )
                                              }
                                            />
                                            <label className="">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={this.saveQueryQuestion}
                                >
                                  <span>Submit</span>
                                </button>
                              </div>
                            </div>
                          )}
                          {quesSubmitted && (
                            <div className="row">
                              <div className="col-md-6">
                                <label>To:</label>
                                <div className="form-group">
                                  {this.state.office_users &&
                                    this.state.office_users.length ? (
                                    this.state.office_users.map(
                                      (user: any, index) => {
                                        return (
                                          <div
                                            className="custom-control custom-checkbox"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className=""
                                              onChange={(e) =>
                                                this.selectUser(e, user)
                                              }
                                              defaultChecked
                                            />
                                            <label className="">
                                              {user.name + " - " + user.email}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <button
                                      // onClick={this.addItem}
                                      className="btn btn-primary m-b-20"
                                    >
                                      + Add User
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label>Cc:</label>
                                <div className="form-group">
                                  {this.state.office_users &&
                                    this.state.office_users.length ? (
                                    this.state.office_users.map(
                                      (user: any, index) => {
                                        return (
                                          <div
                                            className="custom-control custom-checkbox"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className=""
                                              onChange={(e) =>
                                                this.selectCcUser(e, user)
                                              }
                                              defaultChecked
                                            />
                                            <label className="">
                                              {user.name + " - " + user.email}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <button
                                      // onClick={this.addItem}
                                      className="btn btn-primary m-b-20"
                                    >
                                      + Add User
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {quesSubmitted && (
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-info"
                              // data-dismiss="modal"
                              disabled={this.state.queryLoading}
                              onClick={this.saveQuery}
                            >
                              {this.state.queryLoading ? (
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <span>Send</span>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
