import React, { Component } from 'react';
import { callApi } from "../../utils/api";
import { Link } from "react-router-dom";
import ReactFlexyTable from "react-flexy-table";
// import "react-flexy-table/dist/index.css";
import Select from 'react-select';
import moment from 'moment';
// import $ from "jquery";
// import "smartwizard/dist/css/smart_wizard_all.css";
import "./livetracking.css";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";

declare var $: any;
export class liveTracking extends Component {
  state = {
    notications: [],
    user: {
      role_id: ""
    },
    filteredNotifications: [],
    searchText: ""
  }
  componentDidMount() {
    this.AlphaTeam();
    this.getComplaint();
    const user: any = localStorage.getItem("user");
    this.setState({ user: user ? JSON.parse(user)["userId"] : "" })
  }
  AlphaTeam = () => {
    $("#step-l-1").show();
    $("#step-l-2").hide();
    $("#step-l-3").hide();
    $("#step-l-4").hide();
    $("#step-l-5").hide();
    $("#step-l-6").hide();
    $("#step-l-7").hide();
    $("#step-l-8").hide();
    $('#smartwizard-left .sw-toolbar').appendTo($('#smartwizard-left .sw-container'));
  }
  goToTab = (modal) => {
    switch (modal) {
      case "step-l-1":
        $(`#step-l-1`).show();
        $("#step-l-2").hide();
        $("#step-l-3").hide();
        $("#step-l-4").hide();
        $("#step-l-5").hide();
        $("#step-l-6").hide();
        $("#step-l-7").hide();
        $("#step-l-8").hide();

        break;
      case "step-l-2":
        $(`#step-l-1`).hide();
        $("#step-l-2").show();
        $("#step-l-3").hide();
        $("#step-l-4").hide();
        $("#step-l-5").hide();
        $("#step-l-6").hide();
        $("#step-l-7").hide();
        $("#step-l-8").hide();

        break;
      case "step-l-3":
        $(`#step-l-1`).hide();
        $("#step-l-2").hide();
        $("#step-l-3").show();
        $("#step-l-4").hide();
        $("#step-l-5").hide();
        $("#step-l-6").hide();
        $("#step-l-7").hide();
        $("#step-l-8").hide();

        break;
      case "step-l-4":
        $(`#step-l-1`).hide();
        $("#step-l-2").hide();
        $("#step-l-3").hide();
        $("#step-l-4").show();
        $("#step-l-5").hide();
        $("#step-l-6").hide();
        $("#step-l-7").hide();
        $("#step-l-8").hide();

        break;
      case "step-l-5":
        $(`#step-l-1`).hide();
        $("#step-l-2").hide();
        $("#step-l-3").hide();
        $("#step-l-4").hide();
        $("#step-l-5").show();
        $("#step-l-6").hide();
        $("#step-l-7").hide();
        $("#step-l-8").hide();

        break;
      case "step-l-6":
        $(`#step-l-1`).hide();
        $("#step-l-2").hide();
        $("#step-l-3").hide();
        $("#step-l-4").hide();
        $("#step-l-5").hide();
        $("#step-l-6").show();
        $("#step-l-7").hide();
        $("#step-l-8").hide();

        break;
      case "step-l-7":
        $(`#step-l-1`).hide();
        $("#step-l-2").hide();
        $("#step-l-3").hide();
        $("#step-l-4").hide();
        $("#step-l-5").hide();
        $("#step-l-6").hide();
        $("#step-l-7").show();
        $("#step-l-8").hide();

        break;
        case "step-l-8":
        $(`#step-l-1`).hide();
        $("#step-l-2").hide();
        $("#step-l-3").hide();
        $("#step-l-4").hide();
        $("#step-l-5").hide();
        $("#step-l-6").hide();
        $("#step-l-7").hide();
        $("#step-l-8").show();
        break;
      default:
        break;
    }
  }
  goNext = () => {
    $("#next").click(function () {
      if ($(".divs div:visible").next().length != 0) {
        $(".divs div:visible").next().show().prev().hide();
      }
      else {
        $(".divs div:visible").hide();
        $(".divs div:first").show();
      }
      return false;
    });
    // });
    // $(`#${modal}`).show();
  }
  goBackPreview = () => {
    $("#prev").click(function () {
      if ($(".divs div:visible").prev().length != 0) {
        $(".divs div:visible").prev().show().next().hide();
      } else {
        $(".divs div:visible").hide();
        $(".divs div:last").show();
      }
      return false;
    });
  }
  getComplaint = () => {

    callApi("POST", "live_track.php")
      .then((res: any) => res.data)
      .then((res: any) => res.responseJson)
      .then(response => {
        console.log("33", response);
        this.setState({
          notications: response.data,
          filteredNotifications: response.data
        });
      })
      .catch(err => {
        console.log(err);
      });

  }
  handleSearchChange = (e) => {
    const { notications } = this.state;
    const LowerCaseValue = e.target.value.toLowerCase();
    const toLC = (value) => value.toLowerCase();
    const list = notications.filter((item: any) => {
      return toLC(item.title).includes(LowerCaseValue) ||
        toLC(item.information).includes(LowerCaseValue);
    });
    this.setState({
      searchText: e.target.value,
      filteredNotifications: list
    });
  }
  render() {
    const columns = [

      {
        header: 'S.No',
        key: 'index',
        td: (file) => <td >{file.index}</td>
      },
      {
        // front_officer: "Priyanga", verify_officer
        header: 'Procces Officer Name',
        key: 'user_Name',
        // key: 'front_officer',
        // td: (file) => <h6 onClick={() =>this.goToFileDashboard(file.id.slice(3))}>{file.front_officer}</h6>
        td: (file) =>
          <h6 >{file.user_Name}</h6>
      },
      {
        header: 'File No.',
        key: 'file_no',
        td: (file) => <h6 >{file.file_no}</h6>
      },

      {
        header: 'Bank Name',
        key: 'bank_name',
        td: (file) => <h6 >{file.bank_name}</h6>
      },
      {
        header: "Currently Working On",
        key: 'information',
        td: (file) => <h6 >{file.information}</h6>

      },
      {
        header: 'In Time For This Work',
        key: 'time',
        td: (file) => <h6 >{file.time}</h6>
      },
      {
        header: 'Reason',
        key: 'reason',
        td: (file) => <h6 >{file.reason}</h6>
      }
    ];
    const { notications, user, filteredNotifications, searchText } = this.state;
    const user_team: any = localStorage.getItem("user");
		const role_id = user ? JSON.parse(user_team)["role_id"] : "";
		const user_id = user ? JSON.parse(user_team)["userId"] : null;
		const userDetails = user ? JSON.parse(user_team) : "";
		const isAdmin = userDetails.roleType === "1" || userDetails.roleType === '12'|| userDetails.roleType === '2';
    filteredNotifications.forEach((notification: any, index) => { notification.serial = index + 1 })
    return (
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="page-header">
                    <div className="page-block">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <div className="page-header-title">
                            <h5 className="m-b-10"></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {user_id && ["146", "229", "173","174"].includes(user_id)  ? (<><div className='cardF mt-n4' >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className='titleHeading'>
                          <h4 style={{ textAlign: 'center' }}> Live Tracking</h4>
                        </div>
                      </div>
                    </div>
                    <div className="sw-vertical-right" id="smartwizard-left">
                      <div className="row" style={{ margin: "auto", width: "100%" }}>
                        <div className="col-sm-2 col-md-2 col-lg-2" style={{
                          height: "839px",
                          padding: "6px",
                          margin: "auto"
                        }}>
                          <ul className='ulNavLink'>
                            <li className='liNavLink Alpha' style={{ backgroundColor: 'purple' }} onClick={() => this.goToTab("step-l-1")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Alpha Team</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The Alpha Team Activity</p>
                            </li>
                            <li className='liNavLink Bravo' style={{ backgroundColor: '#FF8040' }} onClick={() => this.goToTab("step-l-2")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Bravo Team</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The Bravo Team Activity</p>
                            </li>
                            <li className='liNavLink Cobra' style={{ backgroundColor: '#87CEEB' }} onClick={() => this.goToTab("step-l-3")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Cobra Team</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The cobra Team Activity</p>
                            </li>
                            <li className='liNavLink Delta' style={{ backgroundColor: '#FFA500' }} onClick={() => this.goToTab("step-l-4")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Delta Team</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The Delta Team Activity</p>
                            </li>
                            <li className='liNavLink Query' style={{ backgroundColor: '#964B00' }} onClick={() => this.goToTab("step-l-5")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Query Officer</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The New Team Activity </p>
                            </li>
                            <li className='liNavLink Scan' style={{ backgroundColor: '#808080' }} onClick={() => this.goToTab("step-l-6")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'> Scan Officer</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The New Team Activity </p>
                            </li>
                            <li className='liNavLink Vetting' style={{ backgroundColor: '#242e3e' }} onClick={() => this.goToTab("step-l-7")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Vetting Officer</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The New Team Activity</p>
                            </li>
                            <li className='liNavLink Vetting' style={{ backgroundColor: '#242e3e' }} onClick={() => this.goToTab("step-l-8")}>
                              <h4 style={{ fontSize: "21px" }} className='titlePara'>Verification Officer</h4>
                              <p style={{ fontSize: "12px" }} className='titlePara'>To See The Verification Officer</p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-10 col-md-10 col-lg-10 divs" style={{ overflow: 'scroll', display: 'fit-content' }}>
                          <div id="step-l-1">
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: 'purple', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.team_type == 1 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div id="step-l-2" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#FF8040', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.team_type == 2 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div id="step-l-3" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#87CEEB', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.team_type == 3 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div id="step-l-4" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#FFA500', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.team_type == 4 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{0 + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div id="step-l-5" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#964B00', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.role_type == 10 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div id="step-l-6" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#242e3e', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.role_type == 9 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>
                          </div>
                          <div id="step-l-7" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#242e3e', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Procces Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.role_type == 11 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>

                          </div>
                          <div id="step-l-8" style={{ overflow: 'scroll', display: 'fit-content' }}>
                            <table style={{ boxShadow: '0 0 15px #0101', backgroundColor: '#242e3e', color: '#fff', borderRadius: '10px' }}>
                              <thead>
                                <tr >
                                  <th className="thTable">S.No</th>
                                  <th className="thTable">Verification Officer Name</th>
                                  <th className="thTable">File.No</th>
                                  <th className="thTable">Bank</th>
                                  <th className="thTable">Currently Working On</th>
                                  <th className="thTable">In Time For This Work</th>
                                  <th className="thTable">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.notications && this.state.notications.length
                                  ? this.state.notications.map(
                                    (track: any, index: 0) => track.role_type == 8 ?
                                      (
                                        <tr className="query-table-row cursor-pointer" key={index} >
                                          <td className="thTable">{index + 1}</td>
                                          <td className="thTable">{track.user_Name}</td>
                                          <td className="thTable">{track.file_no}</td>
                                          <td className="thTable">{track.bank_name}</td>
                                          <td className="thTable">{track.information}</td>
                                          <td className="thTable">{track.time}</td>
                                          <td className="thTable">{track.reason}</td>
                                        </tr>
                                      ) : null


                                  )
                                  : ""}
                              </tbody>
                            </table>

                          </div>

                        </div>
                        <div className="row mt-n3" style={{
                          width: "100%",
                          margin: "auto",
                          display: "flex"
                        }}>
                          <div className="col-md-8 col-sm-8 col-lg-8" >

                          </div>
                          <div className="col-md-4 col-sm-4 col-lg-4" style={{marginTop: '-45px'}}>
                            <div style={{ margin: "-7px" }} className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end" >
                              <div
                                className="btn-group mr-2 sw-btn-group"
                                role="group"
                              >
                                <button
                                  onClick={() => this.goBackPreview()}
                                  className="btn mt-2 btn-warning"
                                  id="prev"
                                  type="button">
                                  Previous
                                </button>
                              </div>
                              <div
                                className="btn-group mr-2"
                                role="group"
                              >
                                <button
                                  onClick={() => this.goNext()}
                                  id="next"
                                  style={{ marginTop: "8px" }}
                                  className="btn btn-primary"
                                  type="button">
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="cardF" style={{ height: "600px", overflowY: "scroll",marginTop: '86px'}}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className='titleHeading'>
                          <h4 style={{ textAlign: 'center' }}> Live Tracking</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ margin: "auto", width: "100%" }}>
                      <div className="col-sm-12 col-md-12 col-lg-12">

                        <table style={{ backgroundColor: '#964B00', color: '#fff', borderRadius: '10px', boxShadow: '0 0 10px #010101' }}>
                          <thead>
                            <tr>
                              <th className="thTable">S.No</th>
                              <th className="thTable">Procces Officer Name</th>
                              <th className="thTable">File.No</th>
                              <th className="thTable">Bank</th>
                              <th className="thTable">Currently Working On</th>
                              <th className="thTable">In Time For This Work</th>
                              <th className="thTable">Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.notications && this.state.notications.length
                              ? this.state.notications.map(
                                (track: any, index: number) => track.role_type==7 ?
                                   (
                                    <tr className="query-table-row cursor-pointer" key={index} >
                                      <td className="thTable">{index + 1}</td>
                                      <td className="thTable">{track.user_Name}</td>
                                      <td className="thTable">{track.file_no}</td>
                                      <td className="thTable">{track.bank_name}</td>
                                      <td className="thTable">{track.information}</td>
                                      <td className="thTable">{track.time}</td>
                                      <td className="thTable">{track.reason}</td>
                                    </tr>
                                  ): null
                              )
                              : ""}
                          </tbody>
                        </table>


                      </div>
                    </div>

                  </div></>):(<><div className="cardF" style={{ height: "600px", overflowY: "scroll",marginTop: '86px'}}>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className='titleHeading'>
                          <h4 style={{ textAlign: 'center' }}> Live Tracking</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ margin: "auto", width: "100%" }}>
                      <div className="col-sm-12 col-md-12 col-lg-12">

                        <table style={{ backgroundColor: '#964B00', color: '#fff', borderRadius: '10px', boxShadow: '0 0 10px #010101' }}>
                          <thead>
                            <tr>
                              <th className="thTable">S.No</th>
                              <th className="thTable">Procces Officer Name</th>
                              <th className="thTable">File.No</th>
                              <th className="thTable">Bank</th>
                              <th className="thTable">Currently Working On</th>
                              <th className="thTable">In Time For This Work</th>
                              <th className="thTable">Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.notications && this.state.notications.length
                              ? this.state.notications.map(
                                (track: any, index: number) => track.role_type==7&&user_id===track.team_leader ?
                                   (
                                    <tr className="query-table-row cursor-pointer" key={index} >
                                      <td className="thTable">{index + 1}</td>
                                      <td className="thTable">{track.user_Name}</td>
                                      <td className="thTable">{track.file_no}</td>
                                      <td className="thTable">{track.bank_name}</td>
                                      <td className="thTable">{track.information}</td>
                                      <td className="thTable">{track.time}</td>
                                      <td className="thTable">{track.reason}</td>
                                    </tr>
                                  ): null
                              )
                              : ""}
                          </tbody>
                        </table>


                      </div>
                    </div>

                  </div></>)}
                  
                </div>

              </div>

            </div>


          </div>
          {/* </div>
            </div> */}
          {/* </div> */}
        </div>
      </section>
    )
  }
}

export default liveTracking
