import React, { Component } from 'react';
// import { CounterContext } from '../../App';
interface CountdownTimerProps {
    initialTime: number; // Initial time in minutes
    updatedTime: (time: number) => void;
}

class CountdownTimer extends React.Component<CountdownTimerProps> {
    interval: NodeJS.Timeout | null = null;
    // static contextType = CounterContext;
    state = {
        remainingTime: this.props.initialTime * 60,
        updatedRemainingTime: this.props.updatedTime
    };


    componentDidMount() {
        // const counterValue = this.context;
        // console.log('Counter Value:', counterValue);
        if (this.props.initialTime > 0) {
            this.interval = setInterval(this.updateTimer, 1000);
            console.log(" loged");
        } else {
            this.setState(
                (prevState: any) => ({
                    remainingTime: 0
                }));
            console.log("not loged");
        }
    }

    clearInterval() {
        if (this.interval) {
            clearInterval(this.interval);
            console.log("interval cleared");
            this.interval = null;
        }
    }
    updateTimer = () => {
        this.setState(
            (prevState: any) => ({
                remainingTime: prevState.remainingTime - 1
            }),
            () => {
                let updatedRemainingTime: any = this.state.remainingTime / 60;
                this.props.updatedTime(updatedRemainingTime);
                // Update localStorage
                localStorage.setItem('updatedRemainingTime', updatedRemainingTime);
                if (updatedRemainingTime <= 0) {
                    this.clearInterval();
                }
            }
        );
    };

    render() {
        const { remainingTime } = this.state;
        const hours = Math.floor(remainingTime / 3600);
        const minutes = Math.floor((remainingTime % 3600) / 60);
        const seconds = Math.round(remainingTime % 60);
        return (
            <>
                <div className='d-flex counter-div'>
                    <h5>Countdown Timer - </h5>
                    <h5 style={{ color: "red" }}> {`${hours} hours ${minutes} minutes ${seconds} seconds`}</h5>
                </div>
            </>
        );
    }
}
// CountdownTimer.contextType = CounterContext;
export default CountdownTimer;

