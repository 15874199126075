import * as React from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import { ADMIN_ROLE, TRAINER } from "../../utils/role";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
export interface User {
    id: number;
    docid: string;
    name: string;
    mob_no: string;
    email: string;
    user_type: string;
    subordinate: string;
    branch: string;
    report_to: string;
    role: string;
    usertypelabel: string;
    // itm:any;
}
interface AllProps {
    history: any;
    match: any;
}
class ManageFiles extends React.Component<AllProps> {
    state = {
        dataLevel1: [],
        dataLevel2: [],
        isDocPreviewLoading: false,
        docReportForPreview: "",
        bankToBeDeleted: '',
        isFileRefresh: false,
        model_list: [],
        fruites: [
            { id: 1, value: "banana", isChecked: false },
            { id: 2, value: "apple", isChecked: false },
            { id: 3, value: "mango", isChecked: false },
            { id: 4, value: "grap", isChecked: false }
        ]
    };
    componentDidMount() {
        this.listUser();
        this.modelList();
    }
    modelList = () => {
        const model_list = [
            { id: 1, value: "banana", isChecked: false },
            { id: 2, value: "apple", isChecked: false },
            { id: 3, value: "mango", isChecked: false },
            { id: 4, value: "grap", isChecked: false }
        ]
        this.setState({ fruites: model_list });
    }
    listUser = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "law_select.php")
            .then(res => res.data)
            .then(response => {
                if (response["statusCode"] === 200) {
                    // console.log("fileno",response.responseJson);
                    var res = response.responseJson;
                    res.data.map(itm => {
                        itm.usertypelabel = this.getUserType(itm.user_type);
                    });
                    const dataLevel1: any = [];
                    const dataLevel2: any = [];

                    const Level1 = response.responseJson.data.filter((file: any) => {
                        if (file.level_status == "1") {
                            dataLevel1.push(file);
                        } else if (file.level_status == "2") {
                            dataLevel2.push(file);
                        }
                    });

                    if (dataLevel1 && dataLevel1.length > 0) {
                        dataLevel1.forEach((file, index) => {
                            Object.keys(file).forEach(fileKey => {
                                file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                            })
                            file.index = index + 1;
                        })
                    }
                    if (dataLevel2 && dataLevel2.length > 0) {
                        dataLevel2.forEach((file, index) => {
                            Object.keys(file).forEach(fileKey => {
                                file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                            })
                            file.index = index + 1;
                        })
                    }

                    this.setState({ dataLevel1, dataLevel2, isFileRefresh: true });
                } else { }

            })
            .catch(err => {
                console.log(err);
            });
    };
    getUserType = (type: string) => {
        switch (type) {
            case "1":
                return "Admin";
            case "2":
                return "Manager";
            case "3":
                return "Executive";
            case "4":
                return "Front Office";
            default:
                return "";
        }
    };
    // editUser = (user: User) => {
    //   this.props.history.push("/user/add/" + user.id);
    // };
    confirmDeleteUser = (file) => {
        this.setState({ bankToBeDeleted: file.id });
    };
    deleteUser = () => {
        const obj = {
            id: this.state.bankToBeDeleted
        }

        callApi("POST", "law_delete.php", obj)
            .then(res => res.data)
            .then(response => {
                this.listUser();
            })
            .catch(err => {
                console.log(err);
            });
    };
    handleAllChecked = (event) => {
        let fruites = this.state.fruites;
        fruites.forEach((fruite) => (fruite.isChecked = event.target.checked));
        this.setState({ fruites: fruites });
    };

    handleCheckChieldElement = (event) => {
        let fruites = this.state.fruites;
        fruites.forEach((fruite) => {
            if (fruite.value === event.target.value)
                fruite.isChecked = event.target.checked;
        });
        this.setState({ fruites: fruites });
    };
    previewDocument = (file) => {

        if (file != null) {
            this.setState({ docReportForPreview: file, enableRefresh: true });
            this.setState({ isDocPreviewLoading: false });
        } else {
            this.setState({ isDocPreviewLoading: true });
            this.setState({ docReportForPreview: file });
            this.setState({ isDocPreviewLoading: false });
        }
    };
    render() {
        const user: any = localStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : ""; const columns = [
            {
                header: 'S.No.',
                key: 'index',
            },
            {
                header: 'Model Number',
                key: 'model',
            },
            {
                header: 'Model Name',
                key: 'modelName',
            },
            {
                header: 'File Number',
                key: 'fileno',
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn mr-3 btn-primary"
                            style={{ borderRadius: "4px" }}
                            data-toggle="modal"
                            data-target="#doc-preview"
                            onClick={() => this.previewDocument(file.filepath)}
                        >
                            Document Preview
                        </button>
                        <button
                            type="button"
                            style={{
                                borderTopLeftRadius: "4px",
                                borderBottomLeftRadius: "4px"
                            }}
                            className="btn btn-primary"
                        >
                            Action
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle Dropdown
                            </span>
                        </button>
                        <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                    "translate3d(88px, -2px, 0px)"
                            }}
                        >
                            <a
                                className="dropdown-item"
                                // onClick={() =>
                                onClick={() =>
                                    this.props.history.push("/files/add/" + file.id)
                                    // this.editUser(user)
                                }
                            >
                                Edit
                            </a>
                            {role_id === ADMIN_ROLE && role_id === TRAINER && (
                                <a
                                    className="dropdown-item"
                                    style={{ float: "right" }}
                                    data-toggle="modal"
                                    data-target="#delete-user"
                                    onClick={() =>
                                        this.confirmDeleteUser(file)
                                    }
                                >
                                    Delete
                                </a>
                            )}
                        </div>
                    </div>

                }
            }
        ];

        const levelOneData = this.state.dataLevel1;
        const levelTwoData = this.state.dataLevel2;

        return (
            <><section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header d-flex justify-content-between" style={{ padding: "13px" }}>
                                                    <h5 style={{ fontSize: "17px", margin: "12px 0px 0px 0px" }}>Manage Files</h5>
                                                    <Link
                                                        to={"/files/add/0"}
                                                        className="btn btn-primary m-b-20"
                                                        style={{ margin: "1px 0px 3px 0px" }}
                                                    >
                                                        Add Training Files
                                                    </Link>
                                                </div>
                                                <Accordion className="mainAccordion">
                                                    <Tabs defaultActiveKey="levelone"
                                                        style={{ padding: 0 }}>
                                                        <Tab eventKey="levelone" title="Level One Files">
                                                            <div className="card-body">
                                                                <div className="dt-responsive table-responsive">
                                                                    {this.state.isFileRefresh &&
                                                                        <ReactFlexyTable data={levelOneData} columns={columns} sortable globalSearch />}
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="leveltwo" title="Level Two Files">
                                                            <div className="card-body">
                                                                <div className="dt-responsive table-responsive">
                                                                    {this.state.isFileRefresh &&
                                                                        <ReactFlexyTable data={levelTwoData} columns={columns} sortable globalSearch />}
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </Accordion>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="delete-user"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete User</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h6>Are you sure you want to Delete this user?</h6>
                                <div className="form-group">
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.deleteUser}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <div
                    className="modal fade bd-example-modal-xl"
                    id="doc-preview"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Document Preview</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.isDocPreviewLoading && (
                                    <div className="spin-container" style={{ width: '55em', height: '30em' }}>
                                        <span className="imgLoader" />
                                    </div>
                                )}
                                <iframe width="100%" height="400" frameBorder="0" src={this.state.docReportForPreview}></iframe>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div></>
        );
    }
}
export default ManageFiles;
