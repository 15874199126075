import moment from 'moment';
import * as React from "react";
import { callApi } from "../../utils/api";
interface AllProps {
    id: number;
}
export default class HintAboutFile extends React.Component<AllProps> {
    state = {
        hints: [],
        userHintAboutFile: '',
        deedinfo: [],
        loan_amount: "",
        contact_number: "",
        type_loan: ""
    };
    componentDidMount() {
        this.getHints();
        this.getdeedInfo();
    }
    getHints = () => {
        callApi("POST", "hint_get.php", { id: this.props.id })
            .then(res => res.data)
            .then(result => {
                this.setState({ hints: result.data })
            })
            .catch(err => {
                console.log(err);
            });
    }
    getdeedInfo = () => {
        callApi("POST", "get_title_deed_info.php", { id: this.props.id })
            .then(res => res.data)
            .then(result => {
                this.setState({ deedinfo: result.data, loan_amount: result.loan_amount, contact_number: result.contact_number, type_loan: result.type_loan })
            })
            .catch(err => {
                console.log(err);
            });
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    saveHint = () => {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        callApi('POST', 'add_hints.php', { id: this.props.id, userid: userId, hints: this.state.userHintAboutFile })
            .then(res => res.data)
            .then(result => {
                this.getHints();
            })
            .catch(err => {
                console.log(err);
            });
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-7">
                        <div className="card latest-update-card">
                            <div className="card-header">
                                <h5>Hints About Files</h5>
                            </div>
                            <div className="" style={{ height: '320px', position: 'relative', overflow: 'auto' }}>
                                <div className="card-body">
                                    <div className="latest-update-box">
                                        {this.state.hints && this.state.hints.length > 0 && this.state.hints.map((hint: any, index: number) => (
                                            <div className={index === 0 ? "row p-t-20 p-b-20" : "row p-b-20"}>
                                                <div className="col-auto text-right update-meta">
                                                    <i className="b-primary update-icon ring"></i>
                                                </div>
                                                <div className="col p-l-5">
                                                    <h6 className="m-0">{hint.created_by}  <span style={{ fontSize: '12px' }}>{hint.created_date ? "(" + moment(hint.created_date).format('DD/MM/YYYY hh:mm:ss') + ")" : ''}</span></h6>
                                                    <p className="m-b-0" style={{ wordBreak: 'break-word' }}>{hint.hints}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                                    <div className="ps__thumb-x" style={{ left: '0px', bottom: '0px' }}></div>
                                </div><div className="ps__rail-y" style={{ top: '0px', height: '320px', right: '0px' }}>
                                    <div className="ps__thumb-y" style={{ top: '0px', height: '212px' }}></div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Add Hints</label>
                                    <textarea
                                        placeholder=""
                                        name="userHintAboutFile"
                                        value={this.state.userHintAboutFile}
                                        onChange={e => this.handleChange(e)}
                                        className="form-control"
                                        style={{ height: '25vh' }}
                                    />
                                </div>
                                <button onClick={this.saveHint} className="btn btn-rounded btn-success">
                                    Save
                                </button>
                            </div>
                        </div >
                    </div>
                    <div className="col-md-5">
                        <div className="card latest-update-card" style={{ height: "618px" }}>
                            <div className="card-header">
                                <h5>Title Deed Info</h5>
                            </div>
                            <div className="card-body" style={{ overflowY: "scroll" }}>
                                <div className='m-2' style={{ borderBottom: "2px solid #bbb4b4", paddingBottom: "2%" }}>
                                    <p style={{ fontFamily: "unset" }}>Type Of Loan                 - {this.state.type_loan}</p>
                                    <p style={{ fontFamily: "unset" }}>Loan Amount                 - {this.state.loan_amount}</p>
                                    <p style={{ fontFamily: "unset" }}>LH Branch Person Contact Number                 - {this.state.contact_number}</p>
                                </div>
                                {this.state.deedinfo && this.state.deedinfo.length > 0 ? this.state.deedinfo.map((info: any, index: any) => (
                                    <div className="mt-3 hint-title-deed" key={index}>
                                        <h6><b>Title Deed Info - {index + 1}</b></h6>
                                        <p><span>*</span> Property Owner Name - {info.name ? info.name : ""}</p>
                                        <p><span>*</span> Document No - {info.doc_no ? info.doc_no : ""}</p>
                                        <p><span>*</span> Document Type - {info.doc_type ? info.doc_type : ""}</p>
                                        <p><span>*</span> Schedule / Item No / Plot No - {info.schedule ? info.schedule : ""}</p>
                                        <div className='mt-2'>
                                            {info && info.survey_no ? info.survey_no.map((survey, surveyindex) => (
                                                <div key={surveyindex}>
                                                    <table className='table-style'>
                                                        {surveyindex === 0 && (
                                                            <tr className='row-style'>
                                                                <th className='head-style'>S.No</th>
                                                                <th className='head-style'>S.No / T.S.No</th>
                                                                <th className='head-style'>Extent / Unit</th>
                                                            </tr>
                                                        )}
                                                        <tr className='row-style'>
                                                            <td className='data-style' style={{ width: "65px" }}>{surveyindex + 1}</td>
                                                            <td className='data-style' style={{ width: "181px" }}>{survey.survey_no ? survey.survey_no : ""}</td>
                                                            <td className='data-style'>{survey.extent ? survey.extent : ""} / {survey.unit ? survey.unit : ""}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )) : ""}
                                        </div>
                                    </div>
                                )) : (
                                    <div className='mt-5' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <p><b>Data not found</b></p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}