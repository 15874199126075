import moment from "moment";
import * as React from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { callApi } from "../../utils/api";
import {
  ADMIN_ROLE,
  EXECITUVE_ROLE,
  FRONTOFFICE_ROLE,
  MANAGER_ROLE,
} from "../../utils/role";
import AssignBankUser from "./AssignBankUser";
import FileSearchModal from "./FileSearchModal";

interface AllProps {
  history: any;
}
declare var $: any;
class ManageUnassignedFile extends React.Component<AllProps> {
  state = {
    selectVal: false,
    outreason: "",
    files: [],
    fileToBeAssigned: 0,
    isFileRefresh: false,
    loginfiles: [],
    waitingfiles: [],
    autoAssign: [],
    isLoading: false
  };
  interval: NodeJS.Timeout | null = null;
  componentDidMount() {
    this.getAutoAssignStatus();
    this.getFiles();
    this.getOutTime();
    this.ouTime();
    this.getFilesqueue();
    this.interval = setInterval(() => {
      this.getFilesqueue();
    }, 100000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getAutoAssignStatus = () => {
    const user: any = localStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFilesqueue = () => {
    this.setState({ isFileRefresh: false });
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const user_grade = user ? JSON.parse(user)["user_grade"] : "";
    const my_branchs = user ? JSON.parse(user)["my_branchs"] : "";
    let queue: any;
    if (this.state.autoAssign && this.state.autoAssign.find((data: any) => data.module_name == "Auto Assign" && data.status == true)) {
      queue = 1;
    } else {
      queue = 0;
    }
    let req = {
      status: 0, userId: userId, queue: queue, user_grade: user_grade, my_branchs: my_branchs
    }
    callApi("POST", "get_by_status.php", req)
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;

        const currentDate: any = new Date();
        const newFilesMoreThan20: any = [];
        const newFilesLessThan20: any = [];
        files.forEach((file: any) => {
          const fileDateTime = file.date_time.split("/");
          const fileDate = fileDateTime[0];
          const fileTime = fileDateTime[1];
          const [fileDay, fileMonth, fileYear] = fileDate.split("-");
          const [fileHour, fileMinute] = fileTime.split(":");
          const fileDateObject: any = new Date(
            parseInt(fileYear, 10),
            parseInt(fileMonth, 10) - 1,
            parseInt(fileDay, 10),
            parseInt(fileHour, 10),
            parseInt(fileMinute, 10)
          );
          const timeDifference = Math.floor(
            (currentDate - fileDateObject) / (1000 * 60)
          );
          if (timeDifference >= 20) {
            newFilesMoreThan20.push(file);
          }
          else {
            newFilesLessThan20.push(file);
          }
        });
        if (newFilesLessThan20 && newFilesLessThan20.length > 0) {
          newFilesLessThan20.forEach((file, index) => {
            Object.keys(file).forEach((fileKey) => {
              file[fileKey] = !file[fileKey]
                ? ""
                : typeof file[fileKey] !== "string"
                  ? String(file[fileKey])
                  : file[fileKey];
            });
            file.index = index + 1;
          });
        }
        if (newFilesMoreThan20 && newFilesMoreThan20.length > 0) {
          newFilesMoreThan20.forEach((file, index) => {
            Object.keys(file).forEach((fileKey) => {
              file[fileKey] = !file[fileKey]
                ? ""
                : typeof file[fileKey] !== "string"
                  ? String(file[fileKey])
                  : file[fileKey];
            });
            file.index = index + 1;
          });
        }
        this.setState({ waitingfiles: newFilesMoreThan20, isFileRefresh: true });
        this.setState({ loginfiles: newFilesLessThan20, isFileRefresh: true });

      })
      .catch((err) => {
        console.log(err);
      });
  };

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  };
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = localStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = localStorage.getItem("currentfileId");
    if (localStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  };

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = localStorage.getItem("currentfileId");
    const userid1: any = localStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    const outtime: any = localStorage.getItem("outtimeGet");
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(
      moment(intime, "HH:mm"),
      "seconds"
    );
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    } else {
      let obj = {
        file_id: JSON.parse(fileid1),
        user_id: JSON.parse(userid1),
        in_reason: JSON.parse(inreason),
        out_reason: this.state.outreason,
        intime: JSON.parse(intime),
        outtime: JSON.parse(outtime),
        insert_date: JSON.parse(outdate),
      };
      callApi("POST", "process_eod_insert.php", obj)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ isLoading: false });
          // localStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = localStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = localStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = localStorage.getItem("takenfile_id");
          const user: any = localStorage.getItem("user");
          const query_status: any = localStorage.getItem("query_raised_status");
          const file_completed = localStorage.getItem("file_completed_status");
          if (process_officer_id == user_id && take_file != "" && take_file === fileid1 && query_status == "false" && file_completed == "false") {
            this.fileout(fileid1);
          } else {
            localStorage.removeItem('currentfileId');
            localStorage.removeItem("query_raised_status");
            localStorage.removeItem("file_completed_status");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
          ToastsStore.error("Your Reason Not Stored");
        });
    }
  };

  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        localStorage.removeItem('currentfileId');
        // localStorage.removeItem("takenfile_id");
        localStorage.removeItem("query_raised_status");
        localStorage.removeItem("file_completed_status");
      });
  }
  onchangeReason = (e: any) => {
    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false });
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    } else if (e.target.value == "select") {
      this.setState({ setResponse: false });
    } else {
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getFiles = () => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "get_by_status.php", { status: 0, userId })
      .then((res) => res.data)
      .then((response) => {
        const files = response.data;
        if (files && files.length > 0) {
          files.forEach((file, index) => {
            Object.keys(file).forEach((fileKey) => {
              file[fileKey] = !file[fileKey]
                ? ""
                : typeof file[fileKey] !== "string"
                  ? String(file[fileKey])
                  : file[fileKey];
            });
            file.index = index + 1;
          });
        }
        this.setState({ files, isFileRefresh: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  assignFile = (modal: any) => {
    this.setState({ fileToBeAssigned: modal.id });
  };
  assignPickFile = (modal: any) => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const obj = {
      id: modal.id,
      assigned_to: userId,
    };
    callApi("POST", "self_assign.php", obj)
      .then((response) => response.data)
      .then((res) => {
        // const notifyData = {
        //   fileId: this.props.fileToBeAssigned,
        //   bank: "test_bank",
        //   branch: "test_branch",
        //   assignedTo: this.state.user,
        //   assignedBy: userId,
        //   notifyType: "message",
        // };
        // notifyToUser("user", {...notifyData,notifyType:'message'}, ["147"]);
        ToastsStore.success("File Assigned Successfully");
        setTimeout(() => {
          this.props.history.push("/file-dashboard/" + modal.id);
        }, 2000);
      });
  };
  emergentFile = (modal: any) => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";

    const emergent_quota: any = localStorage.getItem("emergent_quota");
    const quota: any = emergent_quota - 1;

    localStorage.setItem("emergent_quota", quota);
    const obj = {
      fileid: modal.id,
      emergent_status: 1,
      userid: userId,
      current_count: quota
    };
    callApi("POST", "emergent_update.php", obj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode == 200) {
          ToastsStore.success(res.message);
          this.getFilesqueue();
        } else {
          ToastsStore.success(res.message);
        }
      });
  };
  goToFileDashboard = (id) => {
    const url = "/unassigned-file";
    localStorage.setItem("url", url);
    const user: any = localStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    // if (role_id !== EXECITUVE_ROLE) {
    let obj = { id: id };
    localStorage.setItem("thisPopup", JSON.stringify(obj));
    localStorage.setItem("currentfileId", JSON.stringify(id));
    this.props.history.push("/file-dashboard/" + id);
    // }
  };
  fileSearch = (modal) => {
    this.setState({ isFileRefresh: false });
    callApi("POST", "file_search.php", modal)
      .then((res) => res.data)
      .then((response) => {
        const files: any = [];
        if (response.data && response.data.length > 0) {
          response.data.forEach((file: any, index: number) => {
            Object.keys(file).forEach((fileKey) => {
              file[fileKey] = !file[fileKey]
                ? ""
                : typeof file[fileKey] !== "string"
                  ? String(file[fileKey])
                  : file[fileKey];
            });
            file.index = index + 1;
            files.push(file);
          });
        }
        this.setState({ files, isFileRefresh: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const roleType = user ? JSON.parse(user)["roleType"] : "";

    const role_label = user ? JSON.parse(user)["role_label"] : "";
    const emergent_quota: any = localStorage.getItem("emergent_quota");
    const columns = [
      {
        header: "ID",
        key: "id",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>{file.index}</td>
        ),
      },
      {
        header: "Actions",
        td: (file) => {
          return (
            <div className="btn-group mb-2">
              {this.state.autoAssign && this.state.autoAssign.find(
                (data: any) => data.module_name === "Auto Assign" && data.status) ?
                <>
                  {file.emergent_status == 1 && emergent_quota > 0 ? (<>
                    {roleType == 1 || roleType == 2 &&
                      <button
                        type="button"
                        className="btn btn-sm"
                        style={{ marginRight: 10, background: "rgb(227 172 36)", padding: "0px" }}
                        // onClick={() => this.emergentFile(file)}
                        disabled
                      >
                        Emergent File
                      </button>
                    }
                  </>) : (<>
                    {roleType == 1 || roleType == 2 && emergent_quota > 0 && (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{ marginRight: 10, padding: "0px" }}
                        onClick={() => this.emergentFile(file)}
                      >
                        Emergent File
                      </button>
                    )}
                  </>)}

                </> : ""
              }
              {this.state.autoAssign && this.state.autoAssign.find(
                (data: any) => data.module_name === "Auto Assign" && data.status) ?
                <>
                  {role_label == "ADMIN" && userId == 1 || userId == 173 ?
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.assignFile(file)}
                      data-toggle="modal"
                      data-target="#assign-modal"
                    >
                      Assign To
                    </button> : ""}
                </> : ""}
              {this.state.autoAssign && this.state.autoAssign.find(
                (data: any) => data.module_name === "Auto Assign" && data.status == false) ?
                <>{[ADMIN_ROLE, FRONTOFFICE_ROLE, MANAGER_ROLE].includes(
                  role_id
                ) && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.assignFile(file)}
                      data-toggle="modal"
                      data-target="#assign-modal"
                    >
                      Assign To
                    </button>
                  )}</> : ""
              }


              {/* {[ADMIN_ROLE, MANAGER_ROLE, EXECITUVE_ROLE].includes(role_id) && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{ marginLeft: 10 }}
                  onClick={() => this.assignPickFile(file)}
                >
                  Pick File
                </button>
              )} */}
            </div>
          );
        },
      },
      {
        header: "File ID",
        key: "file_id",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.file_id}
          </td>
        ),
      },
      {
        header: "Bank Name",
        key: "bank_name",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.bank_name}{file.product_name ? <>({file.product_name})</> : ""}
          </td>
        ),
      },
      {
        header: "Property Owner Name",
        key: "property_ownername",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.property_ownername}
          </td>
        ),
      },
      {
        header: "File Location",
        key: "file_location",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.file_location}
          </td>
        ),
      },
      {
        header: "Law Hands Branch",
        key: "law_hand_branch",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.law_hand_branch}
          </td>
        ),
      },
      {
        header: "Login By",
        key: "login_by",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.login_by}
          </td>
        ),
      },
      {
        header: "Created Date",
        key: "date_time",
        td: (file) => (
          <td onClick={() => this.goToFileDashboard(file.id)}>
            {file.date_time}
          </td>
        ),
      },
    ];
    const data = this.state.files;
    const logindata = this.state.loginfiles;
    const waitingdata = this.state.waitingfiles;
    const { selectVal } = this.state;
    const emergentquota: any = localStorage.getItem("emergent_quota");
    const COLOR = emergentquota > 5 ? 'green' : 'red';
    // const user: any = localStorage.getItem("user");
    // const role_id = user ? JSON.parse(user)["role_id"] : "";
    // if (role_id !== FRONTOFFICE_ROLE) {
    //   columns.filter(itm => itm.header !== 'Actions');
    // }
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <div style={{ display: "flex" }}>
                            <h5 className="file-status-header" >
                              {this.state.autoAssign && this.state.autoAssign.find(
                                (data: any) => data.module_name === "Auto Assign" && data.status) ?
                                <>Queue Files Manage
                                </> : <>Unassigned Files Manage</>}
                            </h5>
                            {roleType == 1 || roleType == 2 && (<div style={{ display: "flex", marginLeft: "2%", marginTop: "2% " }}><p>Balance Emergent Quota - </p><span style={{ background: COLOR, color: "white", borderRadius: "50%", padding: "3px 7px", height: "23px" }}>{emergentquota}</span></div>)}
                          </div>
                          <div className="file-search-button">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#file-search-modal"
                            >
                              File Search
                            </button>
                          </div>
                        </div>
                        {/* THIS FOR OUT REASON MODAL */}
                        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                          <div className="modal-dialog ">
                            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
                              </div>
                              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                  className="custom-select"
                                  name="outreason"
                                  value={this.state.outreason}
                                  onChange={(e) => this.onchangeReason(e)}
                                  required
                                  id="select1"
                                  style={{ marginTop: '8px' }}
                                >
                                  <option value="select">Select Your Reason</option>
                                  <option value="Query Raised">Query Raised</option>
                                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                                  <option value="Hold ">Hold </option>
                                  <option value="Completed">Completed</option>
                                  <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                              </div>
                              <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.isLoading}
                                  onClick={this.outimeGet}
                                  style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                  type="button"
                                  className="btn btn-outline"
                                  onClick={this.BacktoFile}
                                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* OTHER REASON MODAL */}
                        <div
                          className="modal hide fade in"
                          id="another-reason"
                          data-backdrop="static"
                          data-keyboard="false"
                        >
                          <div className="modal-dialog ">
                            <div
                              className="modal-content"
                              style={{
                                borderRadius: "10px",
                                boxShadow: "0 0 15px #000",
                              }}
                            >
                              <h5
                                style={{
                                  marginTop: "31px",
                                  textAlign: "center",
                                }}
                              >
                                Please Type a Reason To Close The File.No:{" "}
                                {localStorage.getItem("currentfileId")}{" "}
                              </h5>
                              <div
                                className="modal-body"
                                style={{ padding: "30px 23px 29px 24px" }}
                              >
                                {/* <div className="col-md-5"> */}
                                <div
                                  className="form-group"
                                  style={{ width: "98%", margin: "auto" }}
                                >
                                  <textarea
                                    className="form-control"
                                    placeholder="Type Your Reason"
                                    name="outreason"
                                    onChange={this.onchange}
                                  />
                                  {selectVal ? (
                                    <p
                                      style={{
                                        color: "red",
                                        textAlign: "center",
                                        fontSize: "16px",
                                      }}
                                    >
                                      Please Select Your Reason{" "}
                                    </p>
                                  ) : null}
                                </div>
                                {/* </div> */}
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.outimeGet()}
                                  style={{ width: "76px", fontSize: "17px" }}
                                  disabled={this.state.isLoading}
                                >
                                  {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : "OK"}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline"
                                  onClick={() => this.previousPop()}
                                  // onClick={this.disableModalHandler}
                                  style={{
                                    width: "76px",
                                    fontSize: "17px",
                                    border: "1px solid black",
                                  }}
                                >
                                  {" "}
                                  Back
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {this.state.autoAssign && this.state.autoAssign.find(
                            (data: any) => data.module_name === "Auto Assign" && data.status) ?
                            <>
                              {this.state.isFileRefresh &&
                                <>
                                  <div className="card-body">
                                    <h5 className="file-status-header">Waiting Files (20+ mins)</h5>
                                    <ReactFlexyTable data={waitingdata} columns={columns} sortable globalSearch />
                                  </div>

                                  <div className="card-body">
                                    <h5 className="file-status-header">New Files</h5>
                                    <ReactFlexyTable
                                      data={logindata}
                                      columns={columns}
                                      sortable
                                      globalSearch
                                    />
                                  </div>
                                </>
                              }
                            </> :
                            <>
                              {this.state.isFileRefresh && (
                                <>
                                  <div className="card-body">
                                    <ReactFlexyTable
                                      data={data}
                                      columns={columns}
                                      sortable
                                      globalSearch
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AssignBankUser
          fileToBeAssigned={this.state.fileToBeAssigned}
          getFiles={() => this.props.history.push("/assigned-file/")}
          assignedFrom={"unassign"}
        />
        <FileSearchModal
          fileStatus={0}
          searchFileCallback={(data) => this.fileSearch(data)}
        />
      </section>
    );
  }
}
export default ManageUnassignedFile;
