import axios, { AxiosError } from "axios";
import { ToastsStore } from "react-toasts";

export const API_ENDPOINT = "https://traning.lawhands.org/law_hands/api/";
// export const API_ENDPOINT = "http://localhost/legal-live-apis/";

export async function callApi(method: string, path: string, data?: any) {
  const authKey = localStorage.getItem("authkey");
  const headers = {
    Authorization: `Bearer ${authKey}`,
    "Content-Type": "application/json",
    // Origin: window.location.origin,
  };

  try {
    if (method.toUpperCase() === "POST") {
      return await axios.post(API_ENDPOINT + path, data, { headers });
    } else if (method.toUpperCase() === "DELETE") {
      return await axios.delete(API_ENDPOINT + path, { headers });
    } else {
      return await axios.get(API_ENDPOINT + path, { headers });
    }
  } catch (error) {
    console.error("API call error:", error);

    const axiosError = error as AxiosError<{ error: string; status: number }>;
    
    if (axiosError.response?.status === 401) {
      console.log("Unauthorized - Redirecting to login");
      localStorage.clear(); 
      ToastsStore.error("Session Timeout");
      window.location.href = "/login"; 
    }
    throw error;
  }
}
