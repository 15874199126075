import React, { Component } from "react";
import { callApi } from "../../utils/api";
import ReactFlexyTable from "react-flexy-table";
class VettingComplete extends Component {
    state = {
        isFileRefresh: false,
        files: [],

    };
    componentDidMount() {
        this.getFiles();
    }
    getFiles = () => {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const fileType = "login_file";
        this.setState({ isFileRefresh: true });
        // callApi("POST", "get_by_status.php", { userId , fileType})
        //   .then(res => res.data)
        //   .then(response => {
        //     const files = response.data;
        //     if (files && files.length > 0) {
        //       files.forEach((file, index) => {
        //         Object.keys(file).forEach(fileKey => {
        //           file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
        //         })
        //         file.index = index + 1;
        //       })
        //     }
        //     this.setState({ files, isFileRefresh: true });
        //   })
        //   .catch(err => {
        //     console.log(err);
        //   })
    };

    render() {
        const columns = [
            {
                header: 'ID',
                key: 'id',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.index}</td>
            },
            {
                header: 'Applicant Name',
                key: 'applicant_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.file_id}</td>
            },
            {
                header: 'App Id',
                key: 'app_id',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.bank_name}</td>
            },
            {
                header: 'Date',
                key: 'date',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.borrower_name}</td>
            },
            {
                header: 'Status',
                key: 'status',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.property_ownername}</td>
            },
            {
                header: 'Vetting Officer',
                key: 'vetting_officer',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.law_hand_branch}</td>
            },
            {
                header: 'Created By',
                key: 'Created_by',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.login_by}</td>
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                this.editFile(file)
                            }
                        >
                            Edit
                        </button>
                    </div>
                }
            },
        ];
        const data = this.state.files;

        return (
            <section className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="file-status-header">Completed Files</h5>
                                                    <div className="file-search-button"><button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-toggle="modal"
                                                        data-target="#file-search-modal"
                                                    >
                                                        File Search
                                                    </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.isFileRefresh &&
                                                        <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    editFile(file: any): void {
        throw new Error("Method not implemented.");
    }
    goToFileDashboard(id: any): void {
        throw new Error("Method not implemented.");
    }

}
export default VettingComplete;