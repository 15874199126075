import React, { Component } from "react";
import { callApi, API_ENDPOINT } from "../../utils/api";
import { Link } from "react-router-dom";
export default class OverViewFullWidth extends Component {
  state = {
    report: "",
    rep_id: "",
    remote_users: [],
    isapproved: 0,
    office_users: [],
    documents: [],
    selectedDoc: ""
  };
  document = {
    base: "",
    numPages: 0,
    pageNumber: 1
  };
  rendered: any;
  componentDidMount() {
    this.getFileOverview();
    this.getDocList();
  }
  getFileOverview = () => {
    const props: any = this.props;
    callApi("POST", "final_report_get.php", {
      file_idfk: props.match.params.id,
      check: true
    })
      .then(res => res.data)
      .then(response => {
        if (response && response.data && response.data.length) {
          if (response.data[0].rep_id) {
            const state = response.data[0];
            this.setState({ ...state });
          } else {
            const props: any = this.props;
            const user: any = localStorage.getItem("user");
            const userId = user ? JSON.parse(user)["userId"] : "";
            callApi("POST", "template/tests/scrutiny_report.php", {
              file_idfk: props.match.params.id,
              check: true, userId
            })
              .then(res => res.data)
              .then(response => {
                this.setState({ report: "" });
                this.setState({ report: response.data });
              });
          }
          this.setState({ isapproved: response.data[0].isapproved });
        }
      });
  };
  getDocList = () => {
    const props: any = this.props;
    callApi("POST", "document_list.php", { id: props.match.params.id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          // const document = this.document;
          // document.base = API_ENDPOINT + response.data[0].base[0].base;
          // this.setState({ ...document });
          const documents = response.data;
          this.setState({
            documents,
            selectedDoc: documents[0].file_id + "~" + documents[0].type
          });
          this.getRevenueDocs(documents[0].file_id);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getRevenueDocs = id => {
    callApi("POST", "getbaseby_id.php", { id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const document = this.document;
          document.base = API_ENDPOINT + response.data[0].base[0].base;
          this.setState({ ...document });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  handleChange = e => {
    if (e.target.name === "selectedDoc") {
      this.getRevenueDocs(e.target.value.split("~")[0]);
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  downloadPDF = () => {
    const input: any = document.getElementById("report-content");
    let mywindow: any = window.open(
      "",
      "PRINT",
      "height=650,width=900,top=100,left=150"
    );
    mywindow.document.write(input.innerHTML);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  };
  navigateToFile = () => {
    const props: any = this.props;
    const type =
      this.state.selectedDoc.split("~")[1] === "ec_doc"
        ? "ec-form"
        : "scrutiny";
    const docId = this.state.selectedDoc.split("~")[0];
    const link = "/" + type + "/" + props.match.params.id + "/" + docId;
    props.history.push(link);
  };
  render() {
    const user: any = localStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const props: any = this.props;
    return this.state.report ? (
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Document</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="row form-document"
                            style={{ minHeight: "1084px", overflow: 'hidden' }}
                          >
                            {/* <div
                              id="report-content"
                              contentEditable={true}
                              dangerouslySetInnerHTML={{
                                __html: this.state.report
                              }}
                            /> */}
                            {/* <iframe className={'docx'} width="99%" height="990" frameBorder="0" src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.state.report}&embedded=true`}></iframe> */}
                            <iframe width="99%" height="990" frameBorder="0" src={this.state.report}></iframe>
                          </div>
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            overflow: "auto"
                          }}
                        >
                          <div className="container">
                            <div style={{ width: "40%" }}>
                              <label>Document</label>
                              <select
                                className="custom-select"
                                name="selectedDoc"
                                value={this.state.selectedDoc}
                                onChange={e => this.handleChange(e)}
                                required
                              >
                                <option value="">Select</option>
                                {this.state.documents &&
                                  this.state.documents.length
                                  ? this.state.documents.map(
                                    (doc: any, index: number) => {
                                      return (
                                        <option
                                          key={index}
                                          value={doc.file_id + "~" + doc.type}
                                        >
                                          {doc.label}
                                        </option>
                                      );
                                    }
                                  )
                                  : ""}
                              </select>
                              <button
                                className="btn btn-primary"
                                style={{
                                  float: "right",
                                  marginRight: "-40%"
                                }}
                                onClick={this.navigateToFile}
                              >
                                Edit
                              </button>
                            </div>
                            <br />
                            <iframe
                              src={this.document.base}
                              width="100%"
                              height="880px"
                            />
                          </div>
                          <Link
                            to={"/work-file/4/" + props.match.params.id}
                            className="btn btn-warning"
                            style={{ float: "right" }}
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
        ""
      );
  }
}
