import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import NotifyModal from "./common/NotifyModal/NotifyModal";
import Routes from "./Routes";
import { ApplicationState } from "./store";
import { API_ENDPOINT, callApi } from "./utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { FIREBASE_CONFIG, FIREBASE_PNotifyCountDown } from "./utils/constant";
// PNotify
import * as PNotify from "@pnotify/core";
import * as PNotifyConfirm from "@pnotify/confirm";
import * as PNotifyCountdown from "@pnotify/countdown";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import moment from "moment";
import { updatePNFirebaseReadStatus } from "./utils/firebase";
import { apiCallHelper } from "./utils/commonAPI";
import { defaultModules } from "@pnotify/core";
import CountdownTimer from "./components/file/CountdownTimer";
import { RefObject, createContext } from 'react';
import { ToastsStore } from "react-toasts";
declare var $: any;

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

export const CounterContext = createContext<number>(-1);

class App extends React.Component<MainProps, any> {
  interval: NodeJS.Timeout | null = null;
  countdownTimer: RefObject<CountdownTimer> = React.createRef();
  countdownTimerRef1: CountdownTimer | null = null;
  constructor(props: MainProps) {
    super(props);
    this.state = {
      notificationDetails: [],
      notifyData: [],
      pushNotifyData: [],
      userId: null,
      time_delay: {
        additional_minutes: 0,
        time_delay_reason: ""
      },
      initialTime: 1,
    };
    // Bind the handleChange method to the component instance
    this.handleChange = this.handleChange.bind(this);
    this.submitTimeDelay = this.submitTimeDelay.bind(this);
    this.countdownTimer = React.createRef();
  }

  componentDidMount() {
    initializeApp(FIREBASE_CONFIG);
    this.getFirebaseNotification();
    this.getFirebasePushNotification();
    document.addEventListener("userIdAdded", (e: any) => {
      if (e && e.detail && e.detail.userId) {
        this.getFirebaseNotification(e.detail.userId, "initial");
        this.getFirebasePushNotification(e.detail.userId);
      }
    });
    this.interval = setInterval(this.getTimerZero, 100);
  }

  componentWillUnmount() {
    this.intervalClear()
  }
  intervalClear() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  getTimerZero() {
    let initialTimeInMinutes: any = localStorage.getItem('updatedRemainingTime');
    if (initialTimeInMinutes == 0) {
      localStorage.removeItem('updatedRemainingTime');
      $("#balance-time").modal("show");
    }
  }
  handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      time_delay: {
        ...prevState.time_delay,
        [name]: value
      }
    }));
  }
  submitTimeDelay() {
    const getfileId: any = localStorage.getItem("currentfileId");
    const file_id = JSON.parse(getfileId);
    const { additional_minutes, time_delay_reason } = this.state.time_delay;
    localStorage.setItem('updatedRemainingTime', additional_minutes);
    const status: any = localStorage.getItem("dashboard_status");
    if (additional_minutes && time_delay_reason) {
      const requestData = {
        aditional_min: additional_minutes,
        aditional_min_reason: time_delay_reason,
        file_id: file_id,
        balance_min: 0,
        dashboard_status: status
      };
      callApi("POST", "file_time.php", requestData)
        .then((res) => res.data)
        .then((response) => {
          if (response.status == "200") {
            this.setState({ initialTime: additional_minutes }, () => { });
            $("#balance-time").modal("hide");
            // window.location.reload();
          } else { }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      $("#balance-time").modal("show");
      ToastsStore.error("Enter additional time & reason");
    }
  }

  getFirebaseNotification = (userId?, type?) => {
    const db = getDatabase();
    const user: any = localStorage.getItem("user");
    if (user) userId = JSON.parse(user)["userId"] || null;

    if (userId) {
      const starCountRefMain = ref(db, `notifications`);
      let notifyData: any = [];
      this.setState({ notificationDetails: [], notifyData: [] });
      onValue(starCountRefMain, (snapshot) => {
        if ((type && type === "initial") || localStorage.getItem("user")) {
          let notificationData: any = [];
          const data = snapshot.val();
          const starCountRef = ref(db, `notifications/${userId}`);
          let promise1 = new Promise((resolve, reject) => {
            onValue(starCountRef, (snapshot) => {
              const data = snapshot.val();
              // console.log("User Id NOT ", data);
              if (data && data.type && data.notify && data.notify.length > 0) {
                notificationData.push(data);
                resolve(true);
                // this.setNotificationData(data, userId);
              } else resolve(true);
            });
          });
          const starCountRefAll = ref(db, `notifications/all/`);
          let promise2 = new Promise((resolve, reject) => {
            onValue(starCountRefAll, (snapshot) => {
              const data = snapshot.val();
              if (data && data.type && data.notify && data.notify.length > 0) {
                // this.setNotificationData(data, userId);
                notificationData.push(data);
                resolve(true);
              } else resolve(true);
            });
          });

          const starCountRefGroup = ref(db, `notifications/group/`);
          let promise3 = new Promise((resolve, reject) => {
            onValue(starCountRefGroup, (snapshot) => {
              const data = snapshot.val();
              if (data && data.type && data.notify && data.notify.length > 0) {
                // this.setNotificationData(data, userId);
                notificationData.push(data);
                resolve(true);
              } else resolve(true);
            });
          });

          Promise.all([promise1, promise2, promise3])
            .then((res) => {
              // console.log(notificationData);
              this.setState(
                {
                  notificationDetails: notificationData,
                },
                () => {
                  this.setNotificationData(notificationData, userId);
                }
              );
            })
            .catch((err) => { });
        }

        // this.setState({
        //   notifyData, userId, notificationDetails: data
        // }, () => {
        //   if (notifyData.length > 0 && data.type === "modal")
        //     $("#NotifyModal").modal({ backdrop: 'static', keyboard: false });
        //   else if (notifyData.length > 0 && data.type === "popup") {
        //     notifyData.filter(val => {
        //       toast(<>
        //         <h5>{val.title}</h5>
        //         <p style={{ color: 'black' }}>{val.subtitle}</p>
        //       </>, {
        //         position: "bottom-right",
        //         autoClose: false,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //       });
        //     })
        //   }
        // })
      });
    }
  };
  updateNotifyDetails = (
    notifyData: any,
    text: string,
    type: string,
    userId: string
  ) => {
    if (!!notifyData) {
      if (type == "Accepted" && notifyData.title.includes("File Assigned")) {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let obj = {
          reason: `File Finished To ${text}`,
          notification_type: "assigned",
          type: "FileAssigned",
          title: `${notifyData.fileId} File accepted`,
          fileId: notifyData.fileId,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: userId,
          status: 1
        }
        callApi("POST", "pushnotification.php", obj)
          .then((response) => {
            this.timeUpdate(notifyData.fileId);
            updatePNFirebaseReadStatus(notifyData.id, userId, text, type, notifyData.updatedAt);
          })
          .catch((error) => {
            console.log("Error");
          });
      } else if (type == "Accepted" && notifyData.title.includes("Verification Assigned")) {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let obj = {
          reason: `File Verfication Finish To ${text}`,
          type: "VerificationAssigned",
          notification_type: "verificationassign",
          title: `${notifyData.fileId} File Verification Accepted`,
          fileId: notifyData.fileId,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: userId,
          status: 1
        }
        callApi("POST", "pushnotification.php", obj)
          .then((response) => {
            updatePNFirebaseReadStatus(notifyData.id, userId, text, type, notifyData.updatedAt);
          })
          .catch((error) => {
            console.log("Error");
          });
      } else if (type == "Rejected" && notifyData.title.includes("Verification Assigned")) {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let obj = {
          reason: `File Verfication Rejected for ${text}`,
          type: "VerificationAssigned",
          notification_type: "verificationassign",
          title: `${notifyData.fileId} File Verification Rejected`,
          fileId: notifyData.fileId,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: userId,
          status: 1
        }
        callApi("POST", "pushnotification.php", obj)
          .then((response) => {

            updatePNFirebaseReadStatus(notifyData.id, userId, text, type, notifyData.updatedAt);
          })
          .catch((error) => {
            console.log("Error");
          });
      } else {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let obj = {
          reason: `File Rejecting Reason :${text}`,
          notification_type: "assigned",
          type: "FileAssigned",
          title: `${notifyData.fileId} File Rejected`,
          fileId: notifyData.fileId,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          user_id: userId,
          status: 1
        }
        callApi("POST", "pushnotification.php", obj)
          .then((response) => {

            updatePNFirebaseReadStatus(notifyData.id, userId, text, type, notifyData.updatedAt);
          })
          .catch((error) => {
            console.log("Error");
          });
      }
    }
  };

  timeUpdate = (fileid) => {
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      file_accept_by: JSON.parse(loginuser),
      id: fileid,
      reason: "File Accept",
      created_by: JSON.parse(loginuser),
      color: "#edada0"
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }

  getFirebasePushNotification = (userId?: any) => {
    const FIREBASE_PUSH_DB_NAME = "push-notifications";
    const user: any = localStorage.getItem("user");
    if (user) userId = JSON.parse(user)["userId"] || null;
    let { pushNotifyData } = this.state;
    if (userId) {
      const firebaseDB = getDatabase();
      const firebaseDBRef = ref(firebaseDB, FIREBASE_PUSH_DB_NAME);
      pushNotifyData = [];
      const userRef = ref(firebaseDB, `${FIREBASE_PUSH_DB_NAME}/${userId}`);
      let promise1;
      onValue(firebaseDBRef, (snapshot) => {
        promise1 = new Promise((resolve, reject) => {
          onValue(userRef, (snapshot) => {
            pushNotifyData = [];
            const data = snapshot.val();
            pushNotifyData = data && data.notify ? [...data.notify] : [];
            // console.log("data", data);
            const latestPushNotifyData =
              data && data.notify ? [...data.notify] : [];
            if (latestPushNotifyData.length === 0) {
              pushNotifyData = [];
            } else {
              latestPushNotifyData.filter((notify) => {
                pushNotifyData.push(notify);
              });
            }
            // console.log("Check - ", latestPushNotifyData, pushNotifyData);
            resolve(true);
          });
        });
        promise1.then((response) => {
          if (pushNotifyData.length === 0) {
            // PNotify.defaultStack.close();
          } else {
            pushNotifyData.map((value) => {
              // To avoid duplicate notification - $(`.${value.id}`).length < 1
              if (value.status === "unread" && $(`.${value.id}`).length < 1) {
                if (value.notifyType === "response") {
                  const noticeValue = PNotify.notice({
                    title: `${value.title}`,
                    // text: `File Id : ${value.fileId}`
                    hide: false,
                    addClass: value.id,
                    closer: false,
                    sticker: false,
                    destroy: false,
                    modules: new Map([
                      [
                        Confirm,
                        {
                          confirm: true,
                          buttons: [
                            {
                              text: "Accept",
                              primary: true,
                              click: (notice) =>
                                notice.update({
                                  type: "info",
                                  text: "Enter due Date & Time (DD/MM/YYYY HH) (Hours in 24 format)",
                                  icon: true,
                                  closer: true,
                                  sticker: true,
                                  hide: true,
                                  modules: new Map([
                                    [
                                      Confirm,
                                      {
                                        prompt: true,
                                        promptMultiLine: true,
                                        promptValue: "",
                                        buttons: [
                                          {
                                            text: "Submit",
                                            primary: true,
                                            click: (notice, inputValue) => {

                                              if (!!inputValue) {
                                                const momentValue = moment(
                                                  inputValue,
                                                  "DD/MM/YYYY HH"
                                                );
                                                if (
                                                  momentValue.isValid() &&
                                                  momentValue.isAfter()
                                                ) {
                                                  this.updateNotifyDetails(
                                                    value,
                                                    inputValue,
                                                    "Accepted",
                                                    userId
                                                  );
                                                  if (
                                                    // To call API
                                                    value.updateType &&
                                                    value.updateData
                                                  ) {
                                                    apiCallHelper(
                                                      inputValue,
                                                      value.updateData,
                                                      value.updateType,
                                                      value,
                                                      "Accepted"
                                                    );
                                                  }
                                                  return notice.update({
                                                    title: `${value.fileId} Acceppted Successfully`,
                                                    text: `Date:${momentValue.format("DD-MM-YYYY(dddd, MMM)")}
                                                    Time:${momentValue.format("HH:mm")}`,
                                                    type: "success",
                                                    delay: 2000,
                                                    modules: new Map(PNotify.defaultModules),
                                                  });
                                                }
                                              }
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  ]),
                                }),
                            },
                            {
                              text: "Reject",
                              click: (notice) =>
                                notice.update({
                                  type: "info",
                                  text: "Enter reason for rejection",
                                  icon: true,
                                  closer: true,
                                  sticker: true,
                                  hide: true,
                                  modules: new Map([
                                    [
                                      Confirm,
                                      {
                                        prompt: true,
                                        promptMultiLine: true,
                                        promptValue: "",
                                        buttons: [
                                          {
                                            text: "Submit",
                                            primary: true,
                                            click: (notice, inputValue) => {
                                              if (inputValue) {
                                                this.updateNotifyDetails(
                                                  value,
                                                  inputValue,
                                                  "Rejected",
                                                  userId
                                                );
                                                if (
                                                  // To call API
                                                  value.updateType &&
                                                  value.updateData
                                                ) {
                                                  apiCallHelper(
                                                    inputValue,
                                                    value.updateData,
                                                    value.updateType,
                                                    value,
                                                    "Rejected",
                                                  );
                                                }
                                                return notice.update({
                                                  title: `${value.fileId} Rejected `,
                                                  text: inputValue,
                                                  type: "error",
                                                  delay: 2000,
                                                  modules: new Map(
                                                    PNotify.defaultModules
                                                  ),
                                                });
                                              }
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  ]),
                                }),
                            },
                          ],
                        },
                      ],
                    ]),
                  });
                } else if (value.notifyType === "message") {
                  const noticeValue = PNotify.notice({
                    title: `${value.title}`,
                    addClass: value.id,
                    hide: true,
                    closer: true,
                    sticker: false,
                    destroy: true,
                    modules: new Map(PNotify.defaultModules),
                    delay: 2000,
                  });
                  noticeValue.on("pnotify:afterClose", () => { updatePNFirebaseReadStatus(value.id, userId, "ok", "read") });
                } else if (value.notifyType === "notify") {
                  const noticeValue = PNotify.notice({
                    title: `${value.title}`,
                    text: "",
                    addClass: value.id,
                    icon: "fas fa-info-circle",
                    hide: false,
                    closer: false,
                    sticker: false,
                    destroy: false,
                    // delay: 2000,
                    modules: new Map([
                      ...PNotify.defaultModules,
                      [
                        PNotifyConfirm,
                        {
                          confirm: true,
                          buttons: [
                            {
                              text: "Ok",
                              primary: true,
                              click: (notice, inputValue) => {
                                notice.close();
                                updatePNFirebaseReadStatus(value.id, userId, "ok", "read")
                              }
                            },
                          ],
                        },
                      ],
                    ]),
                  });
                }
              }
            });
          }
        });
      });
    }
  };

  setNotificationData = (notificationData, userId) => {
    let modalNotifyData: any = [],
      popupNotifyData: any = [];

    notificationData.filter((data) => {
      userId = Number(userId);
      if (data.userType === "all") {
        data.notify.filter((val) => {
          if (
            !val.readList ||
            (val.readList && !val.readList.includes(userId))
          ) {
            if (data.type === "modal") modalNotifyData.push(val);
            else if (data.type === "popup") popupNotifyData.push(val);
          }
        });
      } else if (data.userType === "group") {
        data.notify.filter((val) => {
          if (val.usersList && val.usersList.includes(userId)) {
            if (
              (val.readList && !val.readList.includes(userId)) ||
              !val.readList
            ) {
              if (data.type === "modal") modalNotifyData.push(val);
              else if (data.type === "popup") popupNotifyData.push(val);
            }
          }
        });
      } else if (data.userType === "individual") {
        if (data.type === "modal") modalNotifyData = data.notify;
        else if (data.type === "popup") popupNotifyData = data.notify;
      }
    });

    this.setState(
      {
        notifyData: modalNotifyData,
        userId,
      },
      () => {
        if (modalNotifyData.length > 0)
          $("#NotifyModal").modal({ backdrop: "static", keyboard: false });
        else if (popupNotifyData.length > 0) {
          // popupNotifyData.filter(val => {
          //   toast(<>
          //     <h5>{val.title}</h5>
          //     <p style={{ color: 'black' }}>{val.subtitle}</p>
          //   </>, {
          //     position: "bottom-right",
          //     autoClose: false,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   });
          // })
        }
      }
    );
  };

  updateReadStatus = () => {
    let { notificationDetails, userId } = this.state;
    const db = getDatabase();
    let noticeRequest: any = {
      notices: [],
    };
    console.log("DDSDSD", notificationDetails.userType);
    userId = Number(userId);
    notificationDetails.filter((value) => {
      if (["all", "group"].includes(value.userType)) {
        if (value.notify && value.notify.length > 0) {
          value.notify = value.notify.filter((val) => {
            if (!val.readList) val.readList = [];
            if (value.userType === "group") {
              if (val.usersList && val.usersList.includes(userId)) {
                if (val.id)
                  noticeRequest.notices.push({
                    notice_id: val.id,
                  });
                if (val && !val.readList.includes(userId))
                  val.readList.push(userId);
              }
            } else {
              if (val.id)
                noticeRequest.notices.push({
                  notice_id: val.id,
                });
              if (val && !val.readList.includes(userId))
                val.readList.push(userId);
            }
            return val;
          });
          noticeRequest.userId = userId;
          console.log(value);
          set(ref(db, `notifications/${value.userType}`), value);
        }
      } else {
        value.notify.filter((val) => {
          if (val.id)
            noticeRequest.notices.push({
              notice_id: val.id,
            });
        });
        noticeRequest.userId = userId;
        set(ref(db, `notifications/${userId}`), {});
      }
      if (noticeRequest.notices.length > 0)
        callApi("POST", "notice_read.php", noticeRequest)
          .then((res) => res.data)
          .then((response) => { })
          .catch((err) => {
            console.log(err);
          });
    });
  };

  render(): React.ReactNode {
    const { notifyData, initialTime } = this.state;
    const process_officer: any = localStorage.getItem("process_office_id");
    const verification_officer: any = localStorage.getItem("verification_office_id");
    const userid1: any = localStorage.getItem("user1");
    let initialTimeInMinutes: any = localStorage.getItem('updatedRemainingTime');
    let initialCount;
    if (process_officer == userid1 || verification_officer == userid1 && initialTime && initialTime > 0) {
      initialCount = initialTime;
    } else {
      initialCount = -1;
    }
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={this.props.history}>
          <>
            <CounterContext.Provider value={initialCount}>
              <Routes />
              {process_officer == userid1 || verification_officer == userid1 && initialCount && initialCount > 0 && initialTimeInMinutes > 0 &&
                <CountdownTimer initialTime={initialCount} updatedTime={() => { }} />
              }
            </CounterContext.Provider>

            {notifyData && notifyData.length > 0 && (
              <NotifyModal
                notifyData={notifyData}
                updateReadStatus={this.updateReadStatus}
              />
            )}
            <ToastContainer
              position="bottom-right"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              style={{ width: "25%" }}
            />

            <div className="modal hide fade in" id="balance-time" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog ">
                <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                  <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason for Time Delay and Additional Minutes:  </h5>

                  <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                    <div className="form-group">
                      <label>Additional Minutes:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="minutes"
                        onChange={this.handleChange}
                        value={this.state.time_delay.additional_minutes}
                        name="additional_minutes"
                      />
                    </div>
                    <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                      <label>Reason:</label>
                      <textarea
                        typeof="text"
                        className="form-control"
                        placeholder="Type Your Reason"
                        onChange={this.handleChange}
                        value={this.state.time_delay.time_delay_reason}
                        name="time_delay_reason" autoFocus={true} />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      // data-dismiss="modal"
                      // aria-label="Close"
                      onClick={this.submitTimeDelay}
                      style={{ width: '76px', fontSize: '17px' }}
                    >Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
