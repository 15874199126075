import React from "react";
import { callApi } from "../../utils/api";
import { User } from "../user/ManageUser";
import MaskedInput from "react-text-mask";

interface AllProps {
    fileStatus: number;
    searchFileCallback: any
}
class FileSearchModal extends React.Component<AllProps> {
    state = {
        assigned:false,
        bankPrivate:false,
        branchName:false,
        showlawBranch: false,
        files: [],
        lawhands_branch: "",
        from_date: "",
        to_date: "",
        assigned_to: "",
        borrower_name: "",
        branch: "",
        bank_private_name: "",
        name: "",
        banks: [],
        branches: [],
        filteredBranches: [],
        lawhandsBranches: [],
        users: [],
        filteredUsers: []
    };
    componentDidMount() {

    }
    getBanks() {
        callApi("GET", "bank_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ banks: result.data });
            })
            .catch(err => {
                console.log(err);
            });
    }
    getBankBranches() {
        callApi("GET", "bank_branch_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ branches: result.data, filteredBranches: [] });
                if (this.state.bank_private_name) {
                    this.filterBranches(this.state.bank_private_name);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawhandsBranches: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    }
    filterBranches = bankid => {
        const filteredBranches = this.state.branches.filter((branch: any) => branch.bank_id === bankid);
        const state = this.state;
        state.filteredBranches = filteredBranches;
        this.setState({ ...state });
    };
    getFiles = () => {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const inputSearch = {
            user_id: userId,
            lawhands_branch: this.state.lawhands_branch,
            name: this.state.name,
            from_date: this.state.from_date,
            to_date: this.state.to_date,
            assigned_to: this.state.assigned_to,
            borrower_name: this.state.borrower_name,
            branch: this.state.branch,
            bank_private_name: this.state.bank_private_name,
            file_status: this.props.fileStatus
        }
        this.props.searchFileCallback(inputSearch)
    };
    getUsers = () => {
        callApi("GET", "user_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    handleChange = e => {
        if (e.target.name === "bank_private_name") {
            this.filterBranches(e.target.value);
        }
        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };
    showLaw = (type: any) => {
        if (type == 2) {
            console.log("113", type)
            this.setState({ showlawBranch: true })
            this.getLawHandsBranches();
        } else {
            console.log("120", type)
        }
    }
assignedTo=(type:any)=>{
    if (type == 2) {
        console.log("113", type)
        this.setState({ assigned: true })
        this.getUsers();
    } else {
        console.log("120", type)
    }
}
bankPrivate=(type:any)=>{
    if (type == 2) {
        console.log("113", type)
        this.setState({ bankPrivate: true })
        this.getBanks();
    } else {
        console.log("120", type)
    }
}
branchName=(type:any)=>{
    if (type == 2) {
        console.log("113", type)
        this.setState({ branchName: true })
        this.getBankBranches();
    } else {
        console.log("120", type)
    }
}
    render() {
        const { showlawBranch,assigned,bankPrivate,branchName } = this.state;
        return (
            <div
                className="modal fade"
                id="file-search-modal"
                role="dialog"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">File Search</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>Borrower Name</h6>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Borrower Name"
                                                        name="borrower_name"
                                                        value={this.state.borrower_name}

                                                        onChange={e => this.handleChange(e)}
                                                    />
                                                </div>
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>Law Hands Branch Name</h6>
                                                    <select
                                                        className="custom-select"
                                                        id="lawhands_branch"
                                                        name="lawhands_branch"
                                                        onClick={showlawBranch ? () => this.showLaw(1) : () => this.showLaw(2)}
                                                        onChange={this.handleChange}
                                                        value={this.state.lawhands_branch}
                                                    >
                                                        <option value="">Select</option>
                                                        {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                                            ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                                                return (
                                                                    <option key={index} value={lawBranch.id}>
                                                                        {lawBranch.name}
                                                                    </option>
                                                                );
                                                            })
                                                            : ""}
                                                    </select>
                                                </div>
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>Employee Name</h6>
                                                    <select
                                                        className="custom-select"
                                                        name="assigned_to"
                                                        onClick={assigned ? () => this.assignedTo(1) : () => this.assignedTo(2)}
                                                        onChange={e => this.handleChange(e)}
                                                        value={this.state.assigned_to}
                                                    >
                                                        <option value="">Select</option>
                                                        {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                                            ? this.state.filteredUsers.map((itm: User) => {
                                                                return <option key={itm.id} value={itm.id}>{itm.name}</option>;
                                                            })
                                                            : ""}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>Bank Name</h6>
                                                    <select
                                                        className="custom-select"
                                                        name="bank_private_name"
                                                        onChange={this.handleChange}
                                                        onClick={bankPrivate ? () => this.bankPrivate(1) : () => this.bankPrivate(2)}
                                                        value={this.state.bank_private_name}
                                                    >
                                                        <option value="">Select</option>
                                                        {this.state.banks && this.state.banks.length
                                                            ? this.state.banks.map((bank: any, index: number) => {
                                                                return (
                                                                    <option key={index} value={bank.id}>
                                                                        {bank.name}
                                                                    </option>
                                                                );
                                                            })
                                                            : ""}
                                                    </select>
                                                </div>
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>Bank Branch Name</h6>
                                                    <select
                                                        className="custom-select"
                                                        name="branch"
                                                        onChange={this.handleChange}
                                                        onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                                        value={this.state.branch}
                                                    >
                                                        <option value="">Select</option>
                                                        {this.state.branches && this.state.branches.length
                                                            ? this.state.filteredBranches.map(
                                                                (branch: any, index: number) => {
                                                                    return (
                                                                        <option key={index} value={branch.id}>
                                                                            {branch.name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )
                                                            : ""}
                                                    </select>
                                                </div>
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>Property Owner Name</h6>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Property Owner Name"
                                                        name="name"
                                                        value={this.state.name}
                                                        onChange={e => this.handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>From Date</h6>
                                                    <MaskedInput
                                                        className="form-control"
                                                        mask={[
                                                            /[0-3]/,
                                                            /\d/,
                                                            ".",
                                                            /[0-1]/,
                                                            /\d/,
                                                            ".",
                                                            /[1-2]/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/
                                                        ]}
                                                        placeholder="dd.mm.yyyy"
                                                        value={this.state.from_date}
                                                        onChange={e => this.handleChange(e)}
                                                        name="from_date"
                                                    />
                                                </div>
                                                <div className="col-xl-4 col-md-12">
                                                    <h6>To Date</h6>
                                                    <MaskedInput
                                                        className="form-control"
                                                        mask={[
                                                            /[0-3]/,
                                                            /\d/,
                                                            ".",
                                                            /[0-1]/,
                                                            /\d/,
                                                            ".",
                                                            /[1-2]/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/
                                                        ]}
                                                        placeholder="dd.mm.yyyy"
                                                        value={this.state.to_date}
                                                        onChange={e => this.handleChange(e)}
                                                        name="to_date"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={this.getFiles}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default FileSearchModal;
