import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { callApi } from "../../utils/api";

const ExcelUserReport = () => {
  const [userData, setUserData] = useState([]);
  
  const getTeamType = (type) => {
    switch (type) {
      case "1":
        return "Alpha Team";
      case "2":
        return "Bravo Team";
      case "3":
        return "Cobra Team";
      case "4":
        return "Delta Team";
      default:
        return "";
    }
  };

  const listUser = () => {
    callApi("GET", "user_get_excel.php")
      .then((res) => res.data)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportExcelFile = async () => {
    if (!Array.isArray(userData) || userData.length === 0) {
      console.error("No data available to export:", userData);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Define columns with headers
    worksheet.columns = [
      { header: "S.No", key: "s_no", width: 10 },
      { header: "Name", key: "name", width: 30 },
      { header: "Email address", key: "email", width: 30 },
      { header: "Mobile Number", key: "mob_no", width: 15 },
      { header: "Branch Name", key: "branch", width: 15 },
      { header: "My Branch Name", key: "my_branchs", width: 15 },
      { header: "Role Type", key: "role", width: 15 },
      { header: "Team Type", key: "teamType", width: 15 },
      { header: "Team Leader", key: "report_to", width: 15 },
      { header: "Device Limit", key: "deviceIdLimit", width: 25 },
      { header: "Device ID", key: "deviceId", width: 30 },
      { header: "Browser Name", key: "browser", width: 30 },
      { header: "Login Time", key: "loginTime", width: 30 },
      { header: "Logout Time", key: "logoutTime", width: 30 },
    ];

    // Apply alignment and color to header cells
    worksheet.getRow(1).eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow color
      };
      cell.font = { bold: true, size: 14 };
    });

    const flattenedData = userData.map((file, index) => {
      const my_branchs = file.my_branchs?.map(
          (doc, docIndex) => `${docIndex + 1}. ${doc.branch}`
        )?.join("\n") || "-";
      const deviceId = file.items?.map(
          (doc, docIndex) => `${docIndex + 1}. ${doc.deviceId}`
        )?.join("\n") || "-";
      const browser = file.items?.map(
          (doc, docIndex) => `${docIndex + 1}. ${doc.browser}`
        )?.join("\n") || "-";
      const loginTime = file.items?.map(
          (doc, docIndex) => `${docIndex + 1}. ${doc.loginTime}`
        )?.join("\n") || "-";
      const logoutTime = file.items?.map(
          (doc, docIndex) => `${docIndex + 1}. ${doc.logoutTime}`
        )?.join("\n") || "-";
   
      return {
        s_no: index + 1,
        name: file.name || "",
        email: file.email || "",
        mob_no: file.mob_no || "",
        branch: file.branch || "",
        my_branchs: my_branchs || "",
        role: file.role || "",
        teamType: getTeamType(file.teamType) || "",
        report_to: file.report_to || "",
        deviceIdLimit: file.deviceIdLimit || "",
        deviceId: deviceId || "",
        browser: browser || "",
        loginTime: loginTime || "",
        logoutTime: logoutTime || "",
      };
    });

    // Add data rows with custom cell alignment and wrap text
    flattenedData.forEach((item) => {
      const row = worksheet.addRow(item);
      row.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
      });
    });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, "0")}-${(
      currentDate.getMonth() + 1
    ).toString().padStart(2, "0")}-${currentDate.getFullYear()}`;

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `Employee Report ${formattedDate}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  // Fetch data when component mounts
  useEffect(() => {
    listUser();
  }, []);

  return (
    <>
      <button className="user-excel-download-btn excel-download-btn" onClick={exportExcelFile}>
        Download as Excel
      </button>
    </>
  );
};

export default ExcelUserReport;