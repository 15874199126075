import React from 'react';
import { Redirect, Route } from 'react-router-dom';
export default class AuthGuard extends React.Component {
  isAuthenticated = () => {
    const user = localStorage.getItem("user");
    const authkey = localStorage.getItem("authkey");
    return !!user && !!authkey;
  };

  handleRender = (props) => {
    if (this.isAuthenticated()) {
      const { component: ComposedComponent } = this.props;
      return <ComposedComponent {...props} />;
    }
    
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
    );
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.handleRender} />;
  }
}