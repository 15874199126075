import * as React from "react";
import Loader from "react-loader-spinner";
import { callApi } from "../../utils/api";
import { History } from "history";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { Link } from "react-router-dom";


interface LoginState {
  email: string;
  password: string;
  otp: string[];
  timer: number;
  isResendVisible: boolean;
  showOtpPage: boolean;
  isOtpButtonDisabled: boolean;
  isResendButtonDisabled: boolean;
}

class Login extends React.Component<{ history: History }, LoginState> {
  timerInterval: NodeJS.Timeout | null = null;
  state = {
    email: "",
    password: "",
    otp: ["", "", "", ""],
    timer: 60,
    isResendVisible: false,
    showOtpPage: false,
    isOtpButtonDisabled: false,
    isResendButtonDisabled: false,
  };

 
  getOrCreateDeviceId = () => {
    let deviceId = Cookies.get("deviceId");

    if (!deviceId) {
      deviceId = uuidv4();
      // const expirationTimeInDays = 30 / (24 * 60); // Expire in 30 minutes
      Cookies.set("deviceId", deviceId, { expires: 365 }); // Expires in 1 year
    }

    return deviceId;
  };

 

   getBrowserName = () => {
    const userAgent = navigator.userAgent;
    console.log("userAgent -->", userAgent);

    let osType = "Unknown OS";

    // Detect OS
    if (/Windows/i.test(userAgent)) {
        osType = "Windows";
    } else if (/Mac OS X/i.test(userAgent)) {
        osType = "MacOS";
    } else if (/Android/i.test(userAgent)) {
        osType = "Android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        osType = "iOS";
    } else if (/Linux/i.test(userAgent)) {
        osType = "Linux";
    }

    // Detect Browser
    let browserName = "Unknown Browser";

    if (/mobile/i.test(userAgent)) {
        if (userAgent.includes("Edg/")) {
            browserName = "Edge Mobile";
        } else if (userAgent.includes("Chrome")) {
            browserName = "Chrome Mobile";
        } else if (userAgent.includes("Firefox")) {
            browserName = "Firefox Mobile";
        } else if (userAgent.includes("Safari")) {
            browserName = "Safari Mobile";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            browserName = "Internet Explorer Mobile";
        }
        osType = "Mobile"; // If it's a mobile device, override OS type
    } else {
        if (userAgent.includes("Edg/")) {
            browserName = "Edge";
        } else if (userAgent.includes("Chrome")) {
            browserName = "Chrome";
        } else if (userAgent.includes("Firefox")) {
            browserName = "Firefox";
        } else if (userAgent.includes("Safari")) {
            browserName = "Safari";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            browserName = "Internet Explorer";
        }
    }

    return `${osType} ${browserName}`;
};

// Function to get the user's current location
getLocation = () => {
  return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  resolve({
                      lat: position.coords.latitude,
                      long: position.coords.longitude,
                  });
              },
              (error) => {
                  console.error("Error getting location:", error);
                  reject(error);
              }
          );
      } else {
          reject(new Error("Geolocation is not supported by this browser."));
      }
  });
};

  login = async () => {
    // const deviceId = this.getDeviceId();
    const deviceId = this.getOrCreateDeviceId();

    let location:any = {};

    try {
        // Get the user's location
        location = await this.getLocation();
    } catch (error) {
        // Handle case where location is not available
        console.warn("Proceeding without location data.");
    }

    const loginInput = {
      email: this.state.email,
      password: this.state.password,
      deviceId,
      browser: this.getBrowserName(),
      ...location, 
    };
    callApi("POST", "login.php", loginInput)
      .then(res => res.data)
      .then(response => {
        // this.setState({ isloading: false });
        // console.log("25", response.data.admin_manager.length);
        if(response.data){
          localStorage.setItem("authkey", response.data.auth_key);          
        // let userTel = response.data.admin_manager.split(",");
        // localStorage.setItem("managerId", JSON.stringify(response.data.admin_manager.split(",")));
        localStorage.setItem("branch_manager", JSON.stringify(response.data.branch_manager.split(",")));
        localStorage.setItem("senior_relational", JSON.stringify(response.data.senior_manager.split(",")));
        localStorage.setItem("reginal_relational", JSON.stringify(response.data.reginol_manager.split(",")));
        localStorage.setItem("emergency_team", JSON.stringify(response.data.admin_manager.split(",")));
        localStorage.setItem("process_manager", JSON.stringify(response.data.admin_manager.split(",")));
        localStorage.setItem("vetting_officer", JSON.stringify(response.data.vetting_officer.split(",")));
        localStorage.setItem("scan_officer", JSON.stringify(response.data.login_officer.split(",")));
        localStorage.setItem("Query_officer", JSON.stringify(response.data.query_officer.split(",")));
        localStorage.setItem("takenfile_id", JSON.stringify(response.data.taken_file));
        localStorage.setItem("emergent_quota", response.data.emergent_quota);
        localStorage.setItem("user_grade", response.data.user_grade);
        localStorage.setItem("e_grade", response.data.e_grade);
        localStorage.setItem("v_grade", response.data.v_grade);
        localStorage.setItem("verification_to_addon", JSON.stringify(false));
        localStorage.setItem("from_deviation", JSON.stringify(false));
        // localStorage.setItem("frontofficeid", JSON.stringify(response.data.frount_officer.split(",")));
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("user1", JSON.stringify(response.data.userId));
        }
        if (response && response.status === 200) {

          if (response.data && response.data.userId) {
            this.setStatus(response.data.userId);
            console.log("43", response.data.userId);
            const event = new CustomEvent('userIdAdded', { detail: { userId: response.data.userId } });
            document.dispatchEvent(event);
          }
          const role_id = response.data.role_id;
          const roleType = response.data.roleType;
          const r_grade = response.data.r_grade;
          this.props.history.push("/dashboard");
        }  else {
          console.log("else response.error", response.message);
          if (response.message == "OTP has been sent to your mail for your new device login.") {
            this.otpSend();
            this.setState({ showOtpPage: true });
            ToastsStore.success(response.message);
            this.startTimer();
          }else if(response.message == "Reset your password every 60 days via Forgot Password to continue access."){
            window.confirm("For security reasons, please reset your password every 60 days using 'Forgot Password' to keep your access.");
          }else if(response.message == "Device limit exceeded. Only allowed devices can log in."){
            window.confirm("Device limit exceeded. Only allowed devices can Login.");
          }else{
            ToastsStore.error(response.message);
          }

        }
      })
      .catch(err => {
        console.log(err);
        // this.setState({ isloading: false });
      });
  };
  setStatus = (userid1) => {
    const user: any = localStorage.getItem("user");
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    console.log("221", curentTime);
    let obj = {
      user_id: userid1,
      status: 1,
      file_no: "Still not taken any file",
      in_time: curentTime,
      reason: "Just now LoggedIn",
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: "Just now Logged in",
      bank_name: "Just now Logged in",
    }
    callApi("POST", "live_track_insert.php", obj)
      .then(res => res.data)
      .then(response => {
        console.log("53", response);
      })
  }

  onchange = <K extends keyof LoginState>(e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value } as Pick<LoginState, K>);
  };

   startTimer = () => {
      this.setState({ timer: 60, isResendVisible: false });
  
      this.timerInterval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.timer <= 1) {
            clearInterval(this.timerInterval!);
            return { timer: 0, isResendVisible: true }; // Explicitly return `isResendVisible`
          }
          return { timer: prevState.timer - 1, isResendVisible: prevState.isResendVisible }; // Ensure it's always included
        });
      }, 1000);
    };
  
  
    handleChangeOtp = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      let newOtp = [...this.state.otp];
      newOtp[index] = e.target.value;
      this.setState({ otp: newOtp });
  
      if (e.target.value && index < 3) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
        if (nextInput) nextInput.focus();
      }
    };
  
    handleOtpSubmit = () => {
      const enteredOtp = this.state.otp.join("");
      if (enteredOtp.length < 4) {
        ToastsStore.error("Please enter a 4-digit OTP");
        return;
      }
      const deviceIdNew = this.getOrCreateDeviceId();
      let req = { deviceId: deviceIdNew, otp: enteredOtp };
      this.setState({ isOtpButtonDisabled: true });
      callApi("POST", "deviceOtpVerify.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response.success) {
            this.setState({ isOtpButtonDisabled: false });
            ToastsStore.success("OTP Verified Successfully!");
            this.login();
          } else {
            this.setState({ isOtpButtonDisabled: false });
            ToastsStore.error("Invalid OTP!");
            this.setState({ otp: ["", "", "", ""] });
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({ isOtpButtonDisabled: false });
        });
    };
  
    handleResend = () => {
      this.setState({ isResendButtonDisabled: true });
      const deviceIdNew = this.getOrCreateDeviceId();
      let reqest = { 
        email:this.state.email ,
        deviceId:deviceIdNew,
      };
      callApi("POST", "deviceOtpSend.php", reqest)
        .then((res) => res.data)
        .then((response) => {
          if (response.success) {
            this.setState({ isResendButtonDisabled: false });
            ToastsStore.success("Mail Sent Successfully!");
            this.startTimer();
          } else {
            this.setState({ isResendButtonDisabled: false });
            ToastsStore.error("Device Id does not match!");
          }
        })
        .catch((err) => console.log(err));
  
    };
  
    otpSend = () => {
      const deviceIdNew = this.getOrCreateDeviceId();
      let reqest = { 
        email:this.state.email ,
        deviceId:deviceIdNew,
      };
      callApi("POST", "deviceOtpSend.php", reqest)
          .then((res) => res.data)
          .then((response) => {
              if (response.success) {
                ToastsStore.success("Mail Sent Successfully!");
               
              } else {
                ToastsStore.error("Email does not match!");
              }
          })
          .catch((err) => console.log(err));
    }

  render() {
    return (
      <div className="auth-wrapper aut-bg-img-side cotainer-fiuid align-items-stretch">
        {/* <Loader type="Puff" color="#00BFFF" height="100" width="100" />{" "} */}
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="row align-items-center w-100 align-items-stretch bg-white">
          <div className="d-none d-lg-flex col-md-8 aut-bg-img d-md-flex justify-content-center">
            <div className="col-md-8 d-flex">
              <div className="auth-content d-flex align-items-stretch">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators justify-content-start mx-0">
                    <li
                      data-target="#carouselExampleCaptions"
                      data-slide-to="0"
                      className="active"
                    />
                    <li
                      data-target="#carouselExampleCaptions"
                      data-slide-to="1"
                    />
                    <li
                      data-target="#carouselExampleCaptions"
                      data-slide-to="2"
                    />
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <h1 className="text-white mb-5">Login in Law Hands</h1>
                    </div>
                    <div className="carousel-item">
                      <h1 className="text-white mb-5">Login in Law Hands</h1>
                    </div>
                    <div className="carousel-item">
                      <h1 className="text-white mb-5">Login in Law Hands</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 align-items-stret h-100 justify-content-center">
          {!this.state.showOtpPage && (
            <div className=" auth-content">
              <img
                src="../assets/images/logo-dark.png"
                alt=""
                className="img-fluid mb-4"
              />
              <h4 className="mb-3 f-w-400">Login into your account</h4>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="feather icon-mail" />
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  onChange={this.onchange}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="feather icon-lock" />
                  </span>
                </div>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  onChange={this.onchange}
                />
              </div>

                <p className='forget'>  <Link to='/forgot-password' className='text-cta'><>Forgot Password?</> </Link>
                            </p>

              {/* <div className="form-group mt-2">
                <div className="checkbox checkbox-primary d-inline">
                  <input
                    type="checkbox"
                    name="checkbox-p-1"
                    id="checkbox-p-1"
                  />
                  <label className="cr">Save credentials</label>
                </div>
              </div> */}
              <button className="btn btn-primary mb-4" onClick={this.login}>
                Login
              </button>
              {/* <p className="mb-2 text-muted">
                Forgot password?{" "}
                <a href="auth-reset-password.html" className="f-w-400">
                  Reset
                </a>
              </p>
              <p className="mb-0 text-muted">
                Don’t have an account?{" "}
                <a href="sign.html" className="f-w-400">
                  Signup
                </a>
              </p> */}
            </div>
          )}

          
{this.state.showOtpPage && (
                <div className=" auth-content">
              <div className="otp-card">
                <h2 className="otp-heading">Enter OTP</h2>
                <div className="otp-inputs">
                  {this.state.otp.map((data, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength={1}
                      className="otp-input"
                      value={this.state.otp[index]}
                      onChange={(e) => this.handleChangeOtp(e, index)}
                    />
                  ))}
                </div>
                <button className="submit-button" disabled={this.state.isOtpButtonDisabled} onClick={this.handleOtpSubmit}>Verify OTP</button>
                <p className="timer-text">{this.state.timer > 0 ? `Resend OTP in ${this.state.timer}s` : ""}</p>
                {this.state.isResendVisible && <button className="resend-button" disabled={this.state.isResendButtonDisabled} onClick={this.handleResend}>Resend OTP</button>}
              </div>
              </div>
            )}
            
          </div>
        </div>
      </div>
    );
  }
}

export default Login;