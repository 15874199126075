import { Empty } from "antd";
import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import { callApi } from "../../utils/api";
import { getFileStatus } from '../../utils/fileStatus';
import VerifyFileModal from "./VerifyFileModal";
import app from "../../App";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import moment from 'moment';
interface AllProps {
  match: any;
  history: any;
}
declare var $: any;

export default class SearchResults extends Component<AllProps> {
  state = {
    filesbank: "",
    selectVal: false,
    setResponse: true,
    files: [],
    isFileRefresh: false,
    fileToBeVerified: 0,
    currentFileid: '',
    status: "",
    data: '',
    OutTime: 0,
    inTime: 0,
    inreason: "",
    outreason: "",
    autoAssign: [],
    isLoading: false
  };
  appRef: app | null = null;

  componentDidMount() {
    this.getFiles();
    this.getOutTime();
    this.ouTime();
    this.getAutoAssignStatus();
  }
  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  UNSAFE_componentWillReceiveProps() {
    this.getFiles();
  }
  GotoWorkFile = (id, process_office_id) => {
    console.log("process_office_id", process_office_id);
    let process_officer_id = JSON.stringify(process_office_id);
    localStorage.setItem("process_office_id", process_officer_id);
    const userid1: any = localStorage.getItem("user1");
    const url = `/search-results/file_name/${id}`;
    localStorage.setItem("url", url);
    let obj = { file_id: id };
    localStorage.setItem("thisPopup", JSON.stringify(obj));
    localStorage.setItem("currentfileId", JSON.stringify(id));
    const take_file: any = localStorage.getItem("takenfile_id");
    const user: any = localStorage.getItem("user");
    // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
    const getfileId: any = localStorage.getItem("currentfileId");
    if (process_officer_id == userid1 && take_file != "" && take_file == getfileId) {
      this.timeUpdate(id, "workfile");
      console.log("process officer");
    } else {
      console.log("not process officer");
    }
    this.props.history.push("/work-file/7/" + id);
  };

  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = localStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = localStorage.getItem("currentfileId");
    console.log("54", JSON.parse(getfileId))
    console.log("32", getfileId);
    if (localStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = localStorage.getItem("currentfileId");
    const userid1: any = localStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    let updatedRemainingTime: any = localStorage.getItem('updatedRemainingTime');
    let updatedtime = Math.round(updatedRemainingTime);
    const status: any = localStorage.getItem("dashboard_status");
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent,
        "balance_min": updatedtime,
        "dashboard_status": status
      }

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          this.setState({ isLoading: false });
          localStorage.removeItem('updatedRemainingTime');
          console.log("76", response);
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = localStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = localStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          let file_id = JSON.parse(fileid1);
          const take_file: any = localStorage.getItem("takenfile_id");
          const user: any = localStorage.getItem("user");
          // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
          const getfileId: any = localStorage.getItem("currentfileId");
          if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
            console.log(`${process_officer_id}---${user_id}`);

            this.timeUpdate(file_id, "fileout");
            console.log("process officer");
          } else {
            localStorage.removeItem('currentfileId');
          }
          if (this.appRef) {
            this.appRef.intervalClear();
            localStorage.removeItem('updatedRemainingTime');
            console.log("file out");
          }
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }

  timeUpdate = (fileid, type) => {
    console.log(`${fileid}---${type}`);

    const loginuser: any = localStorage.getItem("user1");
    let req;
    this.state.autoAssign.find(
      (data: any) => {
        if (data.module_name === "Auto Assign" && data.status == true) {
          if (type == "workfile") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              taken_status: 1,
              reason: "Work Inprogress",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == "filedash") {
            req = {
              // file_dash_by: JSON.parse(loginuser),
              // id: fileid,
              // taken_status: 1,
              // reason: "File Dashboard",
              // created_by: JSON.parse(loginuser)
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              reason: "Work Inprogress",
              taken_status: 1,
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else {
            req = {
              id: fileid,
              reason: "File Out",
              created_by: JSON.parse(loginuser)
            }
            localStorage.removeItem("currentfileId");
            // localStorage.removeItem("takenfile_id");
          }
        } else {
          if (type == "workfile") {
            req = {
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              reason: "Work Inprogress",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else if (type == "filedash") {
            req = {
              // file_dash_by: JSON.parse(loginuser),
              // id: fileid,
              // reason: "File Dashboard",
              // created_by: JSON.parse(loginuser)
              work_in_progress_by: JSON.parse(loginuser),
              id: fileid,
              reason: "Work Inprogress",
              created_by: JSON.parse(loginuser),
              color: "#aceaaf"
            }
          } else {
            req = {
              id: fileid,
              reason: "File Out",
              created_by: JSON.parse(loginuser)
            }
          }
        }
      })
    console.log("file out request", req);

    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }

  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  // openPoUp = () => {
  // }
  getFiles = () => {
    //console.log(this.props);
    callApi("POST", "search.php", {
      [this.props.match.params.name]: this.props.match.params.searchValue
    })
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ filesbank: files })
        console.log("158", files);
        if (files && files.length > 0) {
          files.forEach((file, index) => {
            Object.keys(file).forEach(fileKey => {
              file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
            })
            file.index = index + 1;
          })
        }
        this.setState({ files, isFileRefresh: true });
      })
      .catch(err => {
        console.log(err);
      });
  };
  goToFileDashboard = (id, process_office_id) => {
    console.log("process_office_id", process_office_id);
    let process_officer_id = JSON.stringify(process_office_id);
    localStorage.setItem("process_office_id", process_officer_id);
    const userid1: any = localStorage.getItem("user1");
    const url = `/search-results/file_name/${id}`;
    localStorage.setItem("url", url);
    const fileBank: any = this.state.filesbank;
    fileBank.forEach(element => {
      if (element.id == id) {
        localStorage.setItem("bankName", element.bank_name);
      }
    });
    let obj = { "id": id }
    localStorage.setItem("thisPopup", JSON.stringify(obj))
    localStorage.setItem("currentfileId", JSON.stringify(id));
    const take_file: any = localStorage.getItem("takenfile_id");
    const user: any = localStorage.getItem("user");
    // const taken_file = user ? JSON.parse(user)["taken_file"] : take_file;
    const getfileId: any = localStorage.getItem("currentfileId");
    if (process_officer_id == userid1 && take_file != "" && take_file == getfileId) {
      this.timeUpdate(id, "filedash");
      console.log("process officer");
    } else {
      console.log("not process officer");
    }
    this.props.history.push("/file-dashboard/" + id);
  };
  assignFile = (modal: any) => {
    this.setState({ fileToBeVerified: modal.id });
  };
  getBillVettingComplete(file) {
    this.setState({ currentFileid: file.id });
    const obj = {
      id: file.id
    }
    callApi("POST", "bill_get_vetting_complete.php", obj)
      .then((res) => res.data)
      .then((result) => {
        const data = result.data
        if (data && data.length > 0) {
          this.setState({ status: data[0].vetting_status === "1" });
        }

        if (result.data) {
        } else {
        }
      });
  }
  updateBillVettingComplete(status) {
    callApi("POST", "bill_vetting_complete.php", {
      file_id: this.state.currentFileid,
      status,
    })
      .then((res) => res.data)
      .then((result) => {
        if (result.data) {
          this.vettingcompleteupdatetime();
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  vettingcompleteupdatetime() {
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      vetting_completed_time: currenttime,
      vetting_completed_date: currentdate,
      vetting_completed_by: JSON.parse(loginuser),
      id: this.props.match.params.id
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }
  render() {
    const { status: vettingStatus, setResponse } = this.state;
    const columns = [
      {
        header: 'ID',
        key: 'index',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.index}</td>
      },
      {
        header: 'Borrower Name',
        key: 'borrower_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.borrower_name}</td>
      },
      {
        header: 'Property Owner Name',
        key: 'property_ownername',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.property_ownername}</td>
      },
      {
        header: 'File Name',
        key: 'file_id',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.file_id}</td>
      },
      {
        header: 'Status',
        key: 'file_status',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }><div>{getFileStatus(file.file_status)}</div></td>
      },
      {
        header: 'Bank Name',
        key: 'bank_name',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.bank_name}</td>
      },
      {
        header: 'Process Officer',
        key: 'assigned_to',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.assigned_to}</td>
      },
      {
        header: 'Verified Officer',
        key: 'verified_by',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.verified_by}</td>
      },
      {
        header: 'Fronent Officer',
        key: 'login_by',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.login_by}</td>
      },
      {
        header: 'Estimate Time',
        key: 'estimate_time',
        td: (file) => <td onClick={() =>
          this.goToFileDashboard(file.id, file.process_office_id)
        }>{file.estimate_time}</td>
      },
      {
        header: "Actions",
        td: (file) => {
          return <div className="btn-group mb-2 mr-2">
            <button
              type="button"
              className="btn btn-primary"
            >
              Action
            </button>
            <button
              type="button"
              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">
                Toggle Dropdown
              </span>
            </button>
            <div
              className="dropdown-menu"
              x-placement="top-start"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                transform:
                  "translate3d(88px, -2px, 0px)"
              }}
            >
              <button
                onClick={() => this.GotoWorkFile(file.id, file.process_office_id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "block",
                  margin: "auto",
                }}
              >
                Work File
              </button>
              <button
                onClick={() => this.goToFileDashboard(file.id, file.process_office_id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "block",
                  margin: "auto",
                }}
              >
                Dashboard
              </button>
              <a
                className="dropdown-item"
                style={{ float: "right" }}
                data-toggle="modal"
                data-target="#delete-bank"
                onClick={() => this.getBillVettingComplete(file)}
              >
                Vetting Completed
              </a>
              {file.file_status === '6' &&
                <a
                  className="dropdown-item"
                  onClick={() =>
                    this.assignFile(file)
                  }
                  data-toggle="modal"
                  data-target="#verify-modal"
                  data-whatever="@mdo"
                >
                  Verify
                </a>
              }
            </div>
          </div>
        }
      }
    ];
    const data = this.state.files;
    const { selectVal } = this.state;
    return (
      <section className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />{" "}
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Search Results</h5>
                        </div>
                        <div className="card-body">
                          {this.state.isFileRefresh &&
                            <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VerifyFileModal
          fileToBeVerified={this.state.fileToBeVerified}
          getFiles={this.getFiles}
          assignedFrom={"unassign"}
        /><div
          className="modal fade"
          id="delete-bank"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" style={{ textAlign: "center" }}>Vetting Completed</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {vettingStatus && (<h6>Your vetting report is completed please contact our support team</h6>)}

                {!vettingStatus && (
                  <>
                    <h6>If you want to complete the vetting report ?</h6>
                    <div className="form-group"></div>
                  </>
                )}
              </div>
              {!vettingStatus && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => this.updateBillVettingComplete(1)}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => this.updateBillVettingComplete(0)}
                  >
                    No
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* THIS FOR OUT REASON MODAL */}
        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                {/* </div> */}

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: '76px', fontSize: '17px' }}
                  disabled={this.state.isLoading}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
