import React, { Component } from "react";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { callApi } from "../../utils/api";
import BankForm from "./BankForm";
import DSAForm from "./DSAForm";
import PropertyOwnerForm from "./PropertyOwnerForm";
import UploadDocument from "./uploadDocument";
import { cloneDeep } from "lodash";
import Axios from "axios";
import moment from "moment";
import { notifyToUser } from "../../utils/firebase";
import { pushnotificationApi } from "../../utils/pushNotify";
import { MANAGER_ROLE } from "../../utils/role";
import { stat } from "fs";
declare var $: any;
interface AllProps {
  match: any;
  history: any;
}
interface Title {
  isValid: boolean;
  doc_no: string;
  doc_type: string;
  name: string;
  schedule: string;
  survey_no_extent: {
    extent: string;
    survey_no: string;
    sur_extent_unit: string;
  }[];
}
interface PrivatelistItem {
  id: string;
  name: string;
  mobile: string;
  email: string;
  id_key: string;
}
class AddFile extends Component<AllProps> {
  documentFormat = {
    base: "",
    base_url: "",
    doc_path: "",
    docNoYear: "",
    particular: "xerox",
    type: "",
    details: {},
    fileNames: [],
    isSavedFile: false,
    isValid: true,
    doc_type: ""
  };
  state = {
    bankName: "",
    bankid: '',
    bank_private: "",
    name: "",
    file_id: "",
    base: [],
    borrower_name: "",
    contact_number: "",
    loan_amount: "",
    app_id: "",
    redirect: false,
    manage: false,
    isNext: false,
    isLoading: false,
    doctype: "",
    doctypes: [],
    docToPreview: "",
    isNewFile: false,
    categoriesList: [],
    required: false,
    isbtnload: false,
    products: [],
    filteredProduct: [],
    product_id: "",
    lawhands_branch: "",
    branch: "",
    bank_name: "",
    global_search: "",
    deeds: [],
    type: "title_deed",
    opinion_type: "1",
    // titlelist: [],
    titlelist: [] as Title[],
    privateList: [] as PrivatelistItem[],
    userHintAboutFile: "",
    outreason: "",
    selectVal: false,
    file_idfk: this.props.match.params.id,
    extentUnits: [],
  };
  componentDidMount() {
    this.listBankproduct();
    this.getDocTypes();
    this.getCategories();
    this.getOutTime();
    this.getExtentUnits();
    this.ouTime();
    const isNewFile = Number(this.props.match.params.id) === 0;
    if (!isNewFile) {
      setTimeout(() => {
        this.getFileDetail();
      }, 1000);
    }
    this.setState({ isNewFile });
    const globalSearchValue = localStorage.getItem('global_search');
    const booleanGlobalSearchValue = globalSearchValue === 'true';
    this.getDeeds();
    this.setState({ global_search: booleanGlobalSearchValue })
  }
  getDeeds = () => {
    callApi("POST", "deed_master_get.php", { category: this.state.type })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const deeds = response.data;
          this.setState({ deeds });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      window.location.reload();
    }
  }
  componentWillUnmount() {
    localStorage.setItem('global_search', JSON.stringify(false));
  }
  getExtentUnits = () => {
    const extentUnits = [
      {
        id: 'Sq.Ft',
        name: 'Sq.Ft'
      },
      {
        id: 'Sq.Yards',
        name: 'Sq.Yards'
      },
      {
        id: 'Sq.Meter',
        name: 'Sq.Meter'
      },
      {
        id: 'Acres',
        name: 'Acres'
      },
      {
        id: 'Cents',
        name: 'Cents'
      },
      {
        id: 'Hectares',
        name: 'Hectares'
      },
      {
        id: 'Ground',
        name: 'Ground'
      },
      {
        id: 'Kuzhi',
        name: 'Kuzhi'
      },
      {
        id: 'Kaani',
        name: 'Kaani'
      },
      {
        id: 'Ares',
        name: 'Ares'
      },
      {
        id: 'Veesam',
        name: 'Veesam'
      },
      {
        id: 'Santhiyar',
        name: 'Santhiyar'
      },
    ]
    this.setState({ extentUnits })
  };
  listBankproduct = () => {
    this.setState({ isFileRefresh: false });
    callApi("GET", "bank_product_get.php")
      .then(res => res.data)
      .then(response => {
        const files = response.data;
        this.setState({ products: files });
      })
      .catch(err => {
        console.log(err);
      });
  };
  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = localStorage.getItem("currentfileId");

    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = localStorage.getItem("currentfileId");
    if (localStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }
  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = localStorage.getItem("currentfileId");
    const userid1: any = localStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    const outtime: any = localStorage.getItem("outtimeGet");
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent
      }
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          this.setState({ isLoading: false });
          // localStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = localStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = localStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          const take_file: any = localStorage.getItem("takenfile_id");
          const user: any = localStorage.getItem("user");
          const getfileId: any = localStorage.getItem("currentfileId");
          if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
            this.fileout(fileid1);
          } else {
            localStorage.removeItem('currentfileId');
          }
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }
  fileout = (fileid1) => {
    let fileId = JSON.parse(fileid1);
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      id: fileId,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        localStorage.removeItem('currentfileId');
        // localStorage.removeItem("takenfile_id");
      });
  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getCategories = () => {
    callApi("POST", "category.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ categoriesList: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleDocumentDetailsChange = (value, index, key) => {
    console.log(`${index}---${value}---${key}`);

    if (key == "docNoYear") {

      const isValidInput = /^[0-9/-]*$/.test(value);

      if (!isValidInput) {
        // Display an error message or handle the invalid input as needed
        console.error("Invalid input. Please enter only numeric characters, slash, or hyphen.");
        return;
      }
      let baseArray = cloneDeep(this.state.base);
      baseArray[index] = {
        ...baseArray[index],
        [key]: value,
      };
      this.setState({ base: baseArray });
    } else {

      let baseArray = cloneDeep(this.state.base);
      baseArray[index] = {
        ...baseArray[index],
        [key]: value,
      };
      this.setState({ base: baseArray });
      console.log("upload_doc_base", baseArray);
    }
  };
  handleDocumentFilesChange = (files, index, parentDetails) => {
    this.setState({ isbtnload: true });
    let baseArray = cloneDeep(this.state.base);
    const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
    if (Number(fileSize) > 26214400) {
      ToastsStore.error("File(s) size shouldn't exceed 25 MB");
    } else {
      callApi("POST", "s3_upload.php", {
        id: Number(this.props.match.params.id),
        rowId: parentDetails.id,
        base: files.base64,
      })
        .then((res) => {
          if (res.data && res.data.file) {
            baseArray[index] = {
              ...baseArray[index],
              base: res.data.file_name,
              base_url: res.data.url,
              doc_path: files.base64
            };
            this.setState({ base: baseArray });
            this.setState({ isbtnload: false });
          } else {
            ToastsStore.error("Failed to upload file(s)");
          }
        })
        .catch((err) => {
          ToastsStore.error("Failed to upload file(s)");
        });
    }
  };
  addUploadDocument = () => {
    let baseArray = cloneDeep(this.state.base);
    baseArray.push(this.documentFormat);
    this.setState({ base: baseArray });
  };
  deleteDocumentDetails = (index) => {
    const baseArray = cloneDeep(this.state.base);
    baseArray.splice(index, 1);
    this.setState({ base: baseArray });
  };
  deleteFile = (index) => {
    const baseArray = cloneDeep(this.state.base);
    baseArray[index] = {
      ...baseArray[index],
      details: {},
      base: "",
    };
    this.setState({ base: baseArray });
  };
  getFileDetail = () => {
    callApi("POST", "front_office_get.php", { id: this.props.match.params.id })
      .then((res) => res.data)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.length &&
          response.data[0].arr &&
          response.data[0].arr.length
        ) {
          const state = response.data[0].arr[0];
          state.contact_number = response.data[0].contact_number;
          state.loan_amount = response.data[0].loan_amount;
          state.base = response.data[0].base
            ? response.data[0].base.map((base) => {
              return {
                ...base,
                isSavedFile: true,
              };
            })
            : [];
          this.filterProduct(response.data[0].arr[0].bank_name);
          if (state.bank_private === "Bank") {
            state.privateList = response.data[0].Dsa;
            state.brolist = response.data[0].brolist;
            state.titlelist = response.data[0].titlelist;
          } else if (state.bank_private === "DSA") {
            state.dsalist = response.data[0].Dsa;
          } else if (state.bank_private === "Private") {
            state.privateList = response.data[0].Dsa;
          }
          this.setState({ ...state });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  filterProduct = bankid => {
    if (bankid) {
      const filteredProduct = this.state.products.filter(
        (branch: any) => branch.bank_id === bankid
      );
      const state = this.state;
      state.filteredProduct = filteredProduct;
      this.setState({ ...state });
    } else {
      this.setState({ product_id: "" });
    }
  };
  getDocTypes = () => {
    callApi("GET", "type.php")
      .then((res) => res.data)
      .then((response) => {
        if (response && response.data && response.data.length) {
          this.setState({ doctypes: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  navigateToDocs = (state: any) => {
    this.setState({ ...state, ...state });
    this.setState({ bankid: state.bank_name })
  };
  formatBytes = (a, b = 2) => {
    if (0 === a) {
      return "0 Bytes";
    }
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
      " " +
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    );
  };
  handleFiles = (files: any, type: string, upload) => {
    let base: any = this.state.base ? this.state.base : [];
    if (Number(this.props.match.params.id)) {
      base = [];
    }
    const fileSize = [...files.fileList].reduce((a, b) => +a + +b.size, 0);
    if (Number(fileSize) > 26214400) {
      ToastsStore.error("File(s) size shouldn't exceed 25 MB");
    } else {
      if (upload === "multi") {
        files.base64.map((file: any, index: number) => {
          base.push({ base: file, details: files.fileList[index], type });
        });
      } else {
        base.push({ base: files.base64, details: files.fileList[0], type });
      }
      this.setState({ redirect: true });
      if (Number(this.props.match.params.id)) {
        this.setState({ isLoading: true });
        callApi("POST", "file_upload.php", {
          id: Number(this.props.match.params.id),
          base,
        })
          .then((res) => {
            this.setState({ isLoading: false });
            this.getFileDetail();
            ToastsStore.success("File(s) Uploaded Successully");
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            ToastsStore.error("Failed to upload file(s)");
          });
      }
    }
  };
  deleteDoc = (modal, index) => {
    const base = this.state.base;
    base.splice(index, 1);
    this.setState({ base });
  };
  previewDoc = (url) => {
    this.setState({ docToPreview: url });
  };
  onchangetype(e: any, id: any) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  }

  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  validateRequiredFileds = (e, status) => {
    this.setState({ required: true });
    const state: any = this.state;
    // uploaddocument validation checking statement
    let isValid;
    if (this.state.isNewFile) {
      isValid = this.newvalidateDocuments();
      console.log("new File");
    }
    else {
      console.log("login File");
      isValid = this.validateDocuments();
    }
    //title info validation checking statement
    let isTitleValid;
    if (this.state.titlelist && this.state.titlelist.length > 0) {
      isTitleValid = this.validateTitleList();
    }

    const { titlelist, privateList } = this.state;
    // Update privatelist with names from titlelist
    for (let i = 0; i < titlelist.length; i++) {
      if (i < privateList.length) {
        privateList[i].name = titlelist[i].name;
      } else {
        privateList.push({
          "name": titlelist[i].name,
          "mobile": "",
          "email": "",
          "id": "",
          "id_key": ""
        });
      }
    }
    this.setState({ privateList })

    if (isTitleValid && isValid && state.borrower_name !== "" && state.bank_name !== "" && state.product_id !== "" && state.branch !== "" && state.lawhands_branch !== "" && state.contact_number !== "" && state.loan_amount !== "") {
      this.saveFile(e, status);
      // ToastsStore.error("success");
      console.log("state", this.state);

    } else {
      ToastsStore.error("Please fill all the required fields");
      return;
    }
  };
  validateTitleList = () => {
    const titleList = this.state.titlelist;
    let isValid = true;
    for (let i = 0; i < titleList.length; i++) {
      const title = titleList[i];
      if (
        !title.doc_no ||
        !title.doc_type ||
        !title.name ||
        !title.schedule ||
        !this.validateSurveyNoExtent(title)
      ) {
        isValid = false;
        if (title) {
          title.isValid = false;
        }
      } else {
        if (title) {
          title.isValid = true;
        }
      }
    }
    this.setState({ titlelist: titleList });
    return isValid;
  };
  validateSurveyNoExtent = (title) => {
    if (!title.survey_no_extent || title.survey_no_extent.length === 0) {
      return false;
    }
    for (let i = 0; i < title.survey_no_extent.length; i++) {
      const survey = title.survey_no_extent[i];
      if (!survey.extent || !survey.survey_no || !survey.sur_extent_unit) {
        return false;
      }
    }
    return true;
  };

  validateRequiredFieldsForGlobalSearch = (e, status) => {
    this.setState({ required: true });
    const state: any = this.state;
    if (state.borrower_name !== "" && state.bank_name !== "" && state.product_id !== "" && state.branch !== "" && state.lawhands_branch !== "" && state.contact_number !== "" && state.loan_amount !== "") {
      this.saveFile(e, status);
    } else {
      // ToastsStore.error("Please fill all the required fields");
      return;
    }
  };

  newvalidateDocuments = () => {
    let base: any = this.state.base;
    let isValid = true;
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (document.type == "deed" || document.type == "mother_doc" || document.type == "title_deed" || document.type == "ec_doc") {
        if (!document.docNoYear || !document.doc_type || !document.type || !document.particular) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else if (document.type == "rev_rec_doc" || document.type == "sale_agreement") {
        if (!document.doc_type || !document.type || !document.particular) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else {
        isValid = false;
        base[i].isValid = false;
      }
    }
    return isValid;
  };

  validateDocuments = () => {
    let base: any = this.state.base;
    let isValid = true;
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (document.type == "deed" || document.type == "mother_doc" || document.type == "title_deed" || document.type == "ec_doc") {
        if (!document.docNoYear || !document.doc_type || !document.type || !document.particular || !document.base) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else if (document.type == "rev_rec_doc" || document.type == "sale_agreement") {
        if (!document.doc_type || !document.type || !document.particular || !document.base) {
          isValid = false;
          base[i].isValid = false;
        } else {
          base[i].isValid = true;
        }
      } else {
        isValid = false;
        base[i].isValid = false;
      }
    }
    return isValid;
  };

  updatePostData = async (postData, banks, branches) => {
    try {
      const bankName =
        banks &&
        banks.length &&
        banks.filter((value) => value.id === postData.bank_name);
      const bankBranch =
        branches &&
        branches.length &&
        branches.filter((value) => value.id === postData.branch);
      const lawHandsBranchName =
        postData &&
        postData.lawhandsBranches &&
        postData.lawhandsBranches.filter(
          (value) => value.id === postData.lawhands_branch
        );
      const customerMobileNumber =
        postData.brolist && !!postData.brolist.length
          ? postData.brolist[0].mobile
          : null;
      if (
        bankName &&
        bankName.length &&
        bankBranch &&
        bankBranch.length &&
        lawHandsBranchName &&
        lawHandsBranchName.length
      ) {
        const PostData = await Axios.post(
          "http://law.elawhands.com/api/postdata.php",
          {
            action: "insert_filelogin",
            date: moment().format("YYYY-MM-DD"),
            service_from: postData.bank_private,
            branch_id: lawHandsBranchName[0].name.trim(),
            bank_id: `${bankName[0].name + "-" + bankBranch[0].name}`,
            customer_name: `${postData.borrower_name}-${customerMobileNumber ? customerMobileNumber : ""
              }`,
          }
        );
      }
    } catch (err) {
      console.log("Post Data Error -> ", err);
      // ToastsStore.error("");
    }
  };

  saveFile = (e, status) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const state: any = this.state;
    const bankList = state.banks;

    const branchList = state.branches;
    delete state.banks;
    delete state.branches;
    delete state.filteredBranches;
    delete state.required;
    state.id = Number(this.props.match.params.id);
    state.file_status = status;
    const user: any = localStorage.getItem("user");
    const report_to = user ? JSON.parse(user)["report_to"] : "";
    state.userId = user ? JSON.parse(user)["userId"] : "";
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const loginuser: any = localStorage.getItem("user1");
    if (state.id) {
      let req;
      if (status == 0) {
        req = {
          scaned_time: currenttime,
          scaned_date: currentdate,
          scaned_by: JSON.parse(loginuser),
          id: this.props.match.params.id,
          reason: "Scan Completed",
          created_by: JSON.parse(loginuser),
          color: "#afa5bd"
        }
        callApi("POST", "time_update.php", req)
          .then(res => res.data)
          .then(response => {

          });

        let request = {
          file_id: this.props.match.params.id,
          doc_count: state.base.length
        }
        callApi("POST", "file_time.php", request)
          .then(res => res.data)
          .then(response => {

          });
      }
      callApi("POST", "file_update.php", state)
        .then((res) => res.data)
        .then((response) => {
          localStorage.setItem('global_search', JSON.stringify(false));
          if (response.office && !response.file) {
            // const notifyData = {
            //   fileId: this.props.match.params.id,
            //   title: "New File Login",
            //   // bank: "test_bank",
            //   // branch: "test_branch",
            //   notifyType: "message",
            // };
            // notifyToUser("user", { ...notifyData, notifyType: "message" }, [
            //   "147",
            // ]);

            this.props.history.push("/pending-file");
          } else if (response.office && response.file) {
            this.props.history.push("/unassigned-file");
          } else {
            ToastsStore.error("File not updated. Please try again!");
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    } else {
      bankList.forEach(element => {
        if (element.id == state.bank_name) {
          this.setState({ bankName: element.name })
        }
      });
      callApi("POST", "file_create.php", state)
        .then((res) => res.data)
        .then((response) => {
          this.fileLogin(response.id);
          ToastsStore.success("File Created Successfully");
          const user: any = localStorage.getItem("user");
          const userId = user ? JSON.parse(user)["userId"] : "";
          const userName = user ? JSON.parse(user)["firstName"] : "";
          const notifyData = {
            title: `${this.state.bankName} New File Created`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
          };
          let user_id = userId;
          pushnotificationApi(notifyData, user_id);
          const branchManger: any = localStorage.getItem("branch_manager");
          const branch_Manager = JSON.parse(branchManger);
          const queryOfficer: any = localStorage.getItem("Query_officer");
          const query_ooficer = JSON.parse(queryOfficer);
          const Scan_officer: any = localStorage.getItem("scan_officer");
          const sacanofficer = JSON.parse(Scan_officer);
          const process_mennager: any = localStorage.getItem("process_manager");
          const processManager = JSON.parse(process_mennager);
          if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);

            const branchManager = branch_Manager.join(",");
            let obj = {
              user_id: branchManager,
              reason: "New File Created",
              ...notifyData
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, query_ooficer);
            const queryOfficer = query_ooficer.join(",");
            let obj = {
              // notification_type:"filecreate",
              user_id: queryOfficer,
              ...notifyData,
              reason: "New File Created"
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, sacanofficer);
            const scanOfficer = sacanofficer.join(",");
            let obj = {
              // notification_type:"filecreate",
              user_id: scanOfficer,
              ...notifyData,
              reason: "New File Created"
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(processManager) && processManager.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
            const pManager = processManager.join(",");
            let obj = {
              // notification_type:"filecreate",
              user_id: pManager,
              ...notifyData,
              reason: "New File Created"
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }

          if (
            (response.office && !response.file) ||
            (response.office && response.file)
          ) {
            this.updatePostData(state, bankList, branchList);
          }
          if (response.office && !response.file) {


            this.props.history.push("/pending-file");
          } else if (response.office && response.file) {
            this.props.history.push("/unassigned-file");
          } else {
            ToastsStore.error("File not created. Please try again!");
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    }
  };
  fileLogin(id) {
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      login_time: currenttime,
      login_date: currentdate,
      login_by: JSON.parse(loginuser),
      id: id,
      reason: "Login",
      created_by: JSON.parse(loginuser),
      color: "#aa8986"
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {

      });
  }
  render() {
    // if (this.state.redirect) {
    //   if (this.state.manage) {
    //     return <Redirect to={"/Front_office_manage"} />;
    //   }
    // }
    const globalSearchValue = localStorage.getItem('global_search');
    return (
      <div className="pcoded-main-container">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_CENTER}
        />
        {/* {this.state.isLoading && (
          <div className="spin-container">
            <span className="imgLoader" />
          </div>
        )} */}

        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div id="form">
                    <div className="list-group">
                      <div className="list-group-item py-3" data-acc-step>
                        <h5 className="mb-0" data-acc-title>
                          Front Office
                        </h5>
                        <div data-acc-content>
                          <form className="my-3" method="POST">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Bank or Private</label>
                                  <select
                                    className="custom-select"
                                    name="bank_private"
                                    value={this.state.bank_private}
                                    onChange={this.handleChange}
                                    required
                                  >
                                    <option>Select the Option</option>
                                    <option value="Bank">Bank</option>
                                    <option value="DSA">DSA</option>
                                    <option value="Private">
                                      Property Owner
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Type of Loan<span style={{ color: "red" }}>*</span><span className="inputEgMsg">Eg. Agri / Takeover / Normal</span></label>
                                  <select
                                    className={this.state.required && this.state.doctype === '' ? 'custom-select form-select is-invalid' : 'form-select custom-select'}
                                    name="doctype"
                                    value={this.state.doctype}
                                    onChange={this.handleChange}
                                    required
                                  >
                                    <option>Select type of loan</option>
                                    {this.state.doctypes &&
                                      this.state.doctypes.length > 0 &&
                                      this.state.doctypes.map((opt: any) => {
                                        return (
                                          <option key={opt.id} value={opt.id}>
                                            {opt.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  <div className="invalid-feedback">*Required Field</div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>File Type<span style={{ color: "red" }}>*</span></label>
                                  <select
                                    className={this.state.required && this.state.opinion_type === '' ? 'custom-select form-select is-invalid' : 'custom-select form-select'}
                                    name="opinion_type"
                                    onChange={this.handleChange}
                                    value={this.state.opinion_type}
                                    required >
                                    <option>Select the File Type</option>
                                    <option value="1">New-Login</option>
                                    <option value="2">Second Opinion</option>
                                    <option value="3">Relegal</option>
                                    <option value="4">Topup</option>
                                    <option value="5">Project / APF</option>
                                    <option value="6">Final Report</option>
                                    <option value="7">Supplementary Opinion</option>
                                  </select>
                                  <div className="invalid-feedback">*Required Field</div>
                                </div>
                              </div>
                            </div>
                            {this.state.bank_private === "Bank" && (
                              <BankForm
                                initialValue={this.state}
                                navigateToDocs={(e: any) =>
                                  this.navigateToDocs(e)
                                }
                                required={this.state.required}
                              />
                            )}
                            {this.state.bank_private === "DSA" && (
                              <DSAForm
                                initialValue={this.state}
                                navigateToDocs={(e: any) =>
                                  this.navigateToDocs(e)
                                }
                                required={this.state.required}
                              />
                            )}
                            {this.state.bank_private === "Private" && (
                              <PropertyOwnerForm
                                initialValue={this.state}
                                navigateToDocs={(e: any) =>
                                  this.navigateToDocs(e)
                                }
                                required={this.state.required}
                              />
                            )}
                          </form>
                        </div>
                      </div>
                      <div className="list-group-item py-3" data-acc-step>
                        {globalSearchValue != "true" && (
                          <h5 className="mb-0" data-acc-title>
                            Document Upload
                          </h5>
                        )}
                        <div>
                          {globalSearchValue != "true" && (
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm upload-document-button"
                                onClick={() => this.addUploadDocument()}
                              >
                                Add Document +
                              </button>
                            </div>
                          )}
                          {globalSearchValue != "true" && this.state.base.length > 0 &&
                            this.state.base.map((document, index) => (
                              <UploadDocument
                                baseDetails={document}
                                handleDocumentDetailsChange={
                                  this.handleDocumentDetailsChange
                                }
                                handleDocumentFilesChange={
                                  this.handleDocumentFilesChange
                                }
                                deleteDocumentDetails={
                                  this.deleteDocumentDetails
                                }
                                deleteFile={this.deleteFile}
                                previewDoc={this.previewDoc}
                                categoriesList={this.state.categoriesList}
                                deedList={this.state.deeds}
                                index={index}
                                newfile={this.state.isNewFile}
                                isbtnload={this.state.isbtnload}
                              />
                            ))}
                          <div className="my-3">
                            {globalSearchValue != "true" && !this.state.isNewFile && (
                              <button
                                type="submit"
                                className="btn btn-success float-right"
                                onClick={(e) =>
                                  this.validateRequiredFileds(e, 0)
                                }
                                style={{ margin: "16px 0px" }}
                                disabled={this.state.isLoading}
                              >
                                {this.state.isLoading && (
                                  <i className="fa fa-spinner fa-spin"></i>
                                )}
                                Save & Submit
                              </button>
                            )}
                            <button
                              type="submit"
                              className="btn btn-primary float-right"
                              onClick={(e) => {
                                if (globalSearchValue != "true") {
                                  this.validateRequiredFileds(e, -1);
                                } else {
                                  this.validateRequiredFieldsForGlobalSearch(e, -1);
                                }
                              }}
                              style={{ margin: "16px 15px" }}
                              disabled={this.state.isLoading}
                            >
                              {this.state.isLoading && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="preview-document"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Document Preview</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  width="100%"
                  height="400px"
                  src={this.state.docToPreview}
                ></iframe>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({ docToPreview: "" });
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* THIS FOR OUT REASON MODAL */}
        <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
              </div>
              <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                <select
                  className="custom-select"
                  name="outreason"
                  value={this.state.outreason}
                  onChange={(e) => this.onchangeReason(e)}
                  required
                  id="select1"
                  style={{ marginTop: '8px' }}
                >
                  <option value="select">Select Your Reason</option>
                  <option value="Query Raised">Query Raised</option>
                  <option value=" Re-Query Raised"> Re-Query Raised</option>
                  <option value="Hold ">Hold </option>
                  <option value="Completed">Completed</option>
                  <option value="Other">Others</option>

                </select>
                {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
              </div>
              <div className="modal-footer" style={{ marginTop: '27px' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.state.isLoading}
                  onClick={this.outimeGet}
                  style={{ width: '76px', fontSize: '17px' }}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={this.BacktoFile}
                  style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                > Back To File</button>
              </div>
            </div>
          </div>
        </div>
        {/* OTHER REASON MODAL */}
        <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
              <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                {/* <div className="col-md-5"> */}
                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                  <textarea
                    className="form-control"
                    placeholder="Type Your Reason"
                    name="outreason" onChange={this.onchange} />
                  {this.state.selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                </div>
                {/* </div> */}

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.outimeGet()}
                  style={{ width: '76px', fontSize: '17px' }}
                  disabled={this.state.isLoading}
                > {this.state.isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : "OK"}</button>
                <button
                  type="button"
                  className="btn btn-outline"

                  onClick={() => this.previousPop()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                > Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddFile;
