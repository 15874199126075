import DatePicker from "antd/lib/date-picker";
import React, { Component } from "react";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { callApi } from "../../utils/api";
import { User } from "../user/ManageUser";
import VerifyFileModal from "./VerifyFileModal";
import Select from 'react-select';
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from 'react-toasts';
import moment from "moment";
declare var $: any;

interface AllProps {
    history: any;
}
export default class BranchSearchResults extends Component<AllProps> {
    state = {
        filesbank: "",
        selectVal: false,
        files: [],
        lawhands_branch: "",
        from_date: "",
        to_date: "",
        assigned_to: "",
        borrower_name: "",
        branch: "",
        bank_private_name: "",
        product_id: "",
        name: "",
        banks: [],
        branches: [],
        filteredBranches: [],
        lawhandsBranches: [],
        users: [],
        filteredUsers: [],
        isFileRefresh: false,
        file_name: '',
        fileToBeVerified: 0,
        OutTime: 0,
        inTime: 0,
        inreason: "",
        outreason: "",
        products: [],
        filteredProduct: [],
        time: 0,
        isLoading: false
    };

    componentDidMount() {
        this.getBanks();
        this.getLawHandsBranches();
        this.getBankBranches();
        // this.getFiles();
        this.ReloadPage();
        this.getUsers();
        this.getOutTime();
        this.ouTime();
        this.listBankproduct();
    }

    listBankproduct = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "bank_product_get.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                this.setState({ products: files, filteredProduct: files });
            })
            .catch(err => {
                console.log(err);
            });
    };

    previousPop = () => {
        $("#out_reason").modal("show");
        $("#another-reason").modal("hide");
    }
    BacktoFile = () => {
        $("#out_reason").modal("hide");
        const fileid1: any = localStorage.getItem("currentfileId");

        this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
    }
    getOutTime = () => {
        let date = new Date();
        let stratTime = date.getTime();
        localStorage.setItem("startTime", JSON.stringify(stratTime))
        let outDate = date.toLocaleDateString();
        localStorage.setItem("OutDateGet", JSON.stringify(outDate));
        var Railway = date.getMinutes();
        if (Railway < 10) {
            var TotalMinutes = "0" + Railway;
            let ouTtime = date.getHours() + ":" + TotalMinutes;
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }
        else {
            let ouTtime: any = date.getHours() + ":" + date.getMinutes();
            localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
        }

    }
    ouTime = () => {
        const getfileId: any = localStorage.getItem("currentfileId");
        console.log("54", JSON.parse(getfileId))
        console.log("32", getfileId);
        if (localStorage.getItem("currentfileId")) {
            $("#out_reason").modal("show");
        }
    }

    outimeGet = () => {
        this.setState({ isLoading: true });
        const fileid1: any = localStorage.getItem("currentfileId");
        const userid1: any = localStorage.getItem("user1");
        const inreason: any = localStorage.getItem("in_reason");
        const intime: any = localStorage.getItem("IntimeGet");
        console.log("70", intime);
        const outtime: any = localStorage.getItem("outtimeGet");
        console.log("73", outtime);
        const outdate: any = localStorage.getItem("OutDateGet");
        const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
        console.log('Second Spn', secondsSpent);
        if (this.state.outreason == "") {
            this.setState({ selectVal: true });
        }
        else {
            let obj = {
                "file_id": JSON.parse(fileid1),
                "user_id": JSON.parse(userid1),
                "in_reason": JSON.parse(inreason),
                "out_reason": this.state.outreason,
                "intime": JSON.parse(intime),
                "outtime": JSON.parse(outtime),
                "insert_date": JSON.parse(outdate),
                "total_seconds": secondsSpent
            }

            // console.log("72",obj);
            callApi("POST", "process_eod_insert.php", obj)
                .then(res => res.data)
                .then(response => {
                    console.log("76", response);
                    this.setState({ isLoading: false });
                    // localStorage.removeItem("currentfileId");
                    localStorage.removeItem("in_reason");
                    localStorage.removeItem("outreason");
                    localStorage.removeItem("IntimeGet");
                    localStorage.removeItem("OutDateGet");
                    $("#out_reason").modal("hide");
                    $("#another-reason").modal("hide");
                    ToastsStore.success("Your Reason Saved Successfully");
                    let process_officerId: any = localStorage.getItem("process_office_id");
                    let process_officer_id = JSON.parse(process_officerId);
                    const userid1: any = localStorage.getItem("user1");
                    let user_id = JSON.parse(userid1);
                    const take_file: any = localStorage.getItem("takenfile_id");
                    const user: any = localStorage.getItem("user");
                    const getfileId: any = localStorage.getItem("currentfileId");
                    if (process_officer_id == user_id && take_file != "" && take_file == getfileId) {
                        this.fileout(fileid1);
                    } else {
                        localStorage.removeItem('currentfileId');
                    }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ isLoading: false });
                    ToastsStore.error("Your Reason Not Stored");
                })
        }
    }
    fileout = (fileid1) => {
        let fileId = JSON.parse(fileid1);
        const loginuser: any = localStorage.getItem("user1");
        let req = {
            id: fileId,
            reason: "File Out",
            created_by: JSON.parse(loginuser)
        }
        callApi("POST", "time_update.php", req)
            .then(res => res.data)
            .then(response => {
                localStorage.removeItem('currentfileId');
                // localStorage.removeItem("takenfile_id");
            });
    }
    onchangeReason = (e: any) => {

        if (e.target.value == "Other") {
            this.setState({ in_reason: "", selectVal: false })
            $("#out_reason").modal("hide");
            $("#another-reason").modal("show");
        }
        else if (e.target.value == "select") {
            this.setState({ setResponse: false })
        }
        else {
            // this.setState({})
            this.setState({ [e.target.name]: e.target.value, selectVal: false });
        }
    };
    onchange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
    };
    ReloadPage = () => {
        let obj = localStorage.getItem("gloablSearch");
        callApi("POST", "branch_search.php", obj)
            .then(res => res.data)
            .then(response => {
                console.log("50", localStorage.removeItem("globalSearch"));
                const files = response.data;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                this.setState({ files, isFileRefresh: true });
                localStorage.removeItem("globalSearch");
            })
            .catch(err => {
                console.log(err);
            });
    };

    getBanks() {
        callApi("GET", "bank_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ banks: result.data });
            })
            .catch(err => {
                console.log(err);
            });
    }
    getBankBranches() {
        callApi("GET", "bank_branch_get.php")
            .then(res => res.data)
            .then(result => {
                this.setState({ branches: result.data, filteredBranches: [] });
                if (this.state.bank_private_name) {
                    this.filterBranches(this.state.bank_private_name);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    getLawHandsBranches() {
        callApi("GET", "get_branch.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ lawhandsBranches: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    }
    filterBranches = bankid => {
        const filteredBranches = this.state.branches.filter((branch: any) => branch.bank_id === bankid);
        const state = this.state;
        state.filteredBranches = filteredBranches;
        this.setState({ ...state });
        console.log("branch state", this.state);

    };
    getFiles = () => {
        this.setState({ isFileRefresh: false });
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        let obj: any = {
            user_id: userId,
            lawhands_branch: this.state.lawhands_branch,
            name: this.state.name,
            from_date: this.state.from_date,
            to_date: this.state.to_date,
            assigned_to: this.state.assigned_to,
            borrower_name: this.state.borrower_name,
            branch: this.state.branch,
            bank_private_name: this.state.bank_private_name,
            bank_product: this.state.product_id,
            id: this.state.file_name
        };
        localStorage.setItem("gloablSearch", JSON.stringify(obj));
        callApi("POST", "branch_search.php", obj)
            .then(res => res.data)
            .then(response => {
                console.log("126", localStorage.getItem("gloablSearch"));
                const files = response.data;
                console.log("248", files);
                this.setState({ filesbank: files })
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : typeof file[fileKey] !== 'string' ? String(file[fileKey]) : file[fileKey];
                        })
                        file.index = index + 1;
                    })
                }
                this.setState({ files, isFileRefresh: true });
            })
            .catch(err => {
                console.log(err);
            });
    };
    getUsers = () => {
        callApi("GET", "user_get.php")
            .then(res => res.data)
            .then(response => {
                this.setState({ users: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    handleChange1 = (e: any, propName?, fieldType?) => {
        const lawhands_branch: any = this.state.lawhands_branch;
        let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
        let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
        if (fieldName === "lawhands_branch") {
            this.setState({ lawhands_branch });
            this.setState({ [fieldName]: fieldValue });
        }
        const assigned_to: any = this.state.assigned_to;
        if (fieldName === "assigned_to") {
            const assigned_to = fieldValue;
            this.setState({ [assigned_to]: fieldValue });
        }
        const bank_private_name = this.state.bank_private_name;
        if (fieldName === "bank_private_name") {
            this.filterBranches(fieldValue);

            this.setState({ product_id: "" })
            this.filterProduct(fieldValue);
            const state = this.state;
            state[fieldName] = fieldValue;
        }
        if (fieldName === "product_id") {
            console.log("e-", e.product_id);
            let newProductId = e.product_id;
            this.setState({ product_id: newProductId }, () => {
                console.log("newProductId", newProductId);
            });
            this.filterBranchesproduct(fieldValue);
        }
        this.setState({ bank_private_name });
        console.log("this.state", this.state);
        console.log(`${fieldName} ---- ${fieldValue}`);

        if (fieldName != "product_id") {
            this.setState({ [fieldName]: fieldValue });
        }
        // branchdetail[e.target.name] = e.target.value;
    };
    filterProduct = bankid => {
        if (bankid) {
            const filteredProduct = this.state.products.filter(
                (branch: any) => branch.bank_id === bankid
            );
            const state = this.state;
            state.filteredProduct = filteredProduct;
            this.setState({ ...state });
        } else {
            // this.setState({ product_id: "" })
        }
    };
    filterBranchesproduct = productid => {
        const filteredBranches = this.state.branches.filter((branch: any) => {
            return branch.product_id === productid && branch.bank_id === this.state.bank_private_name;
        });

        this.setState({ filteredBranches: filteredBranches });

        console.log("filteredBranches", filteredBranches);
    };

    handleChange = e => {
        if (e.target.name === "to_date") {
            this.setState({ to_date: e.target.value })
        }
        if (e.target.name === "from_date") {
            this.setState({ from_date: e.target.value })
        }
        if (e.target.name === "bank_private_name") {
            this.filterBranches(e.target.value);
        }
        if (e.target.name === "lawhands_branch") {
            const branch = e.target.value;
            const filteredUsers = this.state.users.filter(
                (itm: any) => itm.branch_id === branch
            );
            this.setState({ filteredUsers });
        }
        this.setState({ [e.target.name]: e.target.value });
    };
    goToFileDashboard = id => {
        const url = "/branch-search";
        localStorage.setItem("url", url);
        const bankName: any = this.state.filesbank;
        console.log("317", bankName);
        if (Array.isArray(bankName)) {
            bankName.forEach(element => {
                console.log("320", element);
                localStorage.setItem("bankName", element.bank_name);
            });
        }
        let obj = {
            "file_id": id
        }
        localStorage.setItem("thisPopup", JSON.stringify(obj))
        localStorage.setItem("currentfileId", JSON.stringify(id));
        this.props.history.push("/file-dashboard/" + id);
    };
    editFile = (modal) => {
        this.props.history.push("/file/add/" + modal.id);
        localStorage.setItem('global_search', JSON.stringify(true));
    };
    assignFile = (modal: any) => {
        this.setState({ fileToBeVerified: modal.id });
    };
    handleDateChange = (date, dateStr, type) => {
        // console.log(date, dateStr);
        this.setState({ [type]: dateStr });
    };
    render() {
        const filteredProduct = [
            { value: "", label: "" },
            ...this.state.filteredProduct
        ];
        const columns = [
            {
                header: 'S.No',
                key: 'serial',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.serial}</td>
            },
            {
                header: 'File No',
                key: 'file_id',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.file_id}</td>
            },
            {
                header: 'Bank Name',
                key: 'bank_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.bank_name} {file.product_name ? <>({file.product_name})</> : ""}</td>
            },
            {
                header: 'Bank Branch Name',
                key: 'bank_branch_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.bank_branch_name}</td>
            },
            {
                header: 'Borrower Name',
                key: 'borrower_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.borrower_name}</td>
            },
            {
                header: 'Property Owner Name',
                key: 'owner_name',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.owner_name}</td>
            },
            {
                header: 'Login By',
                key: 'login_by',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.login_by}</td>
            },
            {
                header: 'Process Officer Name',
                key: 'assigned_to',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.assigned_to}</td>
            },
            {
                header: 'Created Date',
                key: 'created_date',
                td: (file) => <td onClick={() =>
                    this.goToFileDashboard(file.id)
                }>{file.created_date}</td>
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                        >
                            Action
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle Dropdown
                            </span>
                        </button>
                        <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                    "translate3d(88px, -2px, 0px)"
                            }}
                        >
                            <a
                                className="dropdown-item"
                                onClick={() =>
                                    this.editFile(file)
                                }
                            >
                                Edit
                            </a>
                            {file.file_status === '6' &&
                                <a
                                    className="dropdown-item"
                                    onClick={() =>
                                        this.assignFile(file)
                                    }
                                    data-toggle="modal"
                                    data-target="#verify-modal"
                                    data-whatever="@mdo"
                                >
                                    Verify
                                </a>
                            }
                        </div>
                    </div>
                }
            }
        ];
        const data = this.state.files;
        let processedData;
        if (Array.isArray(data)) {
            processedData = data.map((report: any, index) => ({
                serial: index + 1,
                id: report.id,
                file_id: report.file_id,
                bank_name: report.bank_name,
                borrower_name: report.borrower_name,
                product_name: report.product_name ? report.product_name : "",
                bank_branch_name: report.bank_branch_name ? report.bank_branch_name : "",
                owner_name: report.owner_name ? report.owner_name : "",
                lawhands_branch_name: report.lawhands_branch_name ? report.lawhands_branch_name : "",
                login_by: report.login_by ? report.login_by : "",
                assigned_to: report.assigned_to ? report.assigned_to : "",
                created_date: report.created_date ? report.created_date : ""
            }));
        }
        const { selectVal } = this.state;
        const lawhandsBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.lawhandsBranches
        ];
        const banksoptions = [
            { value: "", label: "All" },
            ...this.state.banks
        ];
        const usersoptions = [
            { value: "", label: "All" },
            ...this.state.users
        ];
        const filteredBranchesoptions = [
            { value: "", label: "All" },
            ...this.state.filteredBranches
        ];
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />{" "}
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Search</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>File No</h6>
                                                                            <input
                                                                                type="text"
                                                                                id="file_name"
                                                                                className="form-control"
                                                                                placeholder="File No"
                                                                                name="file_name"
                                                                                value={this.state.file_name}
                                                                                onChange={e => this.handleChange(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Borrower Name</h6>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Borrower Name"
                                                                                name="borrower_name"
                                                                                value={this.state.borrower_name}
                                                                                onChange={e => this.handleChange(e)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Law Hands Branch Name</h6>
                                                                            {this.state.lawhandsBranches && this.state.lawhandsBranches.length > 0 && this.state.lawhandsBranches.map((opt: any) => {
                                                                                opt.label = opt.name; opt.value = opt.id
                                                                            }) &&
                                                                                <Select options={lawhandsBranchesoptions} name="lawhands_branch"
                                                                                    value={this.state.lawhandsBranches.find((o: any) => o.value === this.state.lawhands_branch)}
                                                                                    onChange={(e) => this.handleChange1(e, 'lawhands_branch', 'singleselect')}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Employee Name</h6>
                                                                            {this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
                                                                                opt.label = opt.name; opt.value = opt.id
                                                                            }) &&
                                                                                <Select options={usersoptions} name="assigned_to"
                                                                                    value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
                                                                                    onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Bank Name</h6>
                                                                            {this.state.banks && this.state.banks.length > 0 && this.state.banks.map((opt: any) => {
                                                                                opt.label = opt.name; opt.value = opt.id
                                                                            }) &&
                                                                                <Select options={banksoptions} name="bank_private_name"
                                                                                    value={this.state.banks.find((o: any) => o.value === this.state.bank_private_name)}
                                                                                    onChange={(e) => this.handleChange1(e, 'bank_private_name', 'singleselect')}
                                                                                />
                                                                            }
                                                                        </div>
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Product</h6>
                                                                            {this.state.products && this.state.products.map((opt: any) => {
                                                                                opt.label = opt.product_name; opt.value = opt.id
                                                                            }) &&
                                                                                <Select options={filteredProduct} name="product_id"
                                                                                    value={this.state.filteredProduct.find((o: any) => o.value === this.state.product_id)}
                                                                                    onChange={(e) => this.handleChange1(e, 'product_id', 'singleselect')}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Bank Branch Name</h6>
                                                                            {this.state.branches && this.state.branches.length > 0 && this.state.branches.map((opt: any) => {
                                                                                opt.label = opt.name; opt.value = opt.id
                                                                            }) &&
                                                                                <Select options={filteredBranchesoptions} name="branch"
                                                                                    value={this.state.filteredBranches.find((o: any) => o.value === this.state.branch)}
                                                                                    onChange={(e) => this.handleChange1(e, 'branch', 'singleselect')}
                                                                                />
                                                                            }

                                                                        </div>

                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>Property Owner Name</h6>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Property Owner Name"
                                                                                name="name"
                                                                                value={this.state.name}
                                                                                onChange={e => this.handleChange(e)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>From Date</h6>
                                                                            <DatePicker format={'DD.MM.YYYY'}
                                                                                onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'from_date')}
                                                                            />
                                                                        </div>
                                                                        <div className="col-xl-4 col-md-12">
                                                                            <h6>To Date</h6>
                                                                            <DatePicker format={'DD.MM.YYYY'} onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'to_date')} />
                                                                        </div>
                                                                        <div className="col-xl-4 col-md-12 mt-4">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-primary"
                                                                                onClick={this.getFiles}
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Search Results</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="dt-responsive table-responsive">
                                                        {this.state.isFileRefresh &&
                                                            <ReactFlexyTable data={processedData} columns={columns} sortable globalSearch showExcelButton />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* THIS FOR OUT REASON MODAL */}
                <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                                <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
                            </div>
                            <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                                <select
                                    className="custom-select"
                                    name="outreason"
                                    value={this.state.outreason}
                                    onChange={(e) => this.onchangeReason(e)}
                                    required
                                    id="select1"
                                    style={{ marginTop: '8px' }}
                                >
                                    <option value="select">Select Your Reason</option>
                                    <option value="Query Raised">Query Raised</option>
                                    <option value=" Re-Query Raised"> Re-Query Raised</option>
                                    <option value="Hold ">Hold </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Other">Others</option>

                                </select>
                                {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            <div className="modal-footer" style={{ marginTop: '27px' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.outimeGet}
                                    style={{ width: '76px', fontSize: '17px' }}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"
                                    onClick={this.BacktoFile}
                                    style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                                > Back To File</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* OTHER REASON MODAL */}
                <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                            <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
                            <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                                {/* <div className="col-md-5"> */}
                                <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                                    <textarea
                                        className="form-control"
                                        placeholder="Type Your Reason"
                                        name="outreason" onChange={this.onchange} />
                                    {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                                </div>
                                {/* </div> */}

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.outimeGet()}
                                    style={{ width: '76px', fontSize: '17px' }}
                                    disabled={this.state.isLoading}
                                > {this.state.isLoading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                ) : "OK"}</button>
                                <button
                                    type="button"
                                    className="btn btn-outline"

                                    onClick={() => this.previousPop()}
                                    // onClick={this.disableModalHandler}
                                    style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                                > Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <VerifyFileModal
                    fileToBeVerified={this.state.fileToBeVerified}
                    getFiles={this.getFiles}
                    assignedFrom={"unassign"}
                />
            </section>
        );
    }
}
