//import * as React from "react";
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import FileSaver from 'file-saver';

import { callApi } from "../../utils/api";
import * as XLSX from 'xlsx';
import MaskedInput from "react-text-mask";
import Select from 'react-select';
import ReactFlexyTable from "react-flexy-table";
import FileSearchModal from "../file/FileSearchModal";
import { PendingFile } from "../file/ManagePendingFile";
import './process_eod.css'
import './Mis.css'
import { User } from "../user/ManageUser";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import { V } from "jointjs";
import {
	ToastsContainer,
	ToastsContainerPosition,
	ToastsStore
} from "react-toasts";

declare var $: any;

interface AllProps {
	match: any;
	history: any;
	router: any;
	route: any;
	id: any;
}




class LawhandsMis extends React.Component<AllProps> {


	state = {

		backReason: "",
		userVAlue: false,
		banks: [],
		bank_name: "",
		selectVal: false,
		setResponse: true,
		myJSON: '',
		lookup: '',
		fileId: '',
		sampleId: "",
		id: "",
		fromDate: "",
		toDate: "",
		files: [],
		fileNo: '',
		lawhandsBranches: [],
		// lawHandsBranch: "",
		lawhands_branch: "",
		assigned_to: "",
		users: [],
		total: '',
		green: '',
		red: '',
		outreason: "",
		bankBranch: false,
		bank_private_name: "",
		filteredBranches: [],
		branch: "",
		branches: [],
		branchName: false,
		lhBranchShow: false,
		lawBranchName: false,
		lawHandsBranch: "",
		showBank: false,
		bankName: false,
		products: [],
		filteredProduct: [],
		product_id: ""
		// fromDate: moment().format('YYYY-MM-DD'),
		// toDate: moment().format('YYYY-MM-DD'),

	};
	obj: any;
	table: any;
	componentDidMount() {
		this.reGetvalue()
		this.getLawHandsBranches()
		this.getUsers()
		this.getOutTime();
		this.ouTime();
		this.getBanks();
		this.getBankBranches();
		// this.fetchData();
		this.listBankproduct();
	}
	listBankproduct = () => {
		this.setState({ isFileRefresh: false });
		callApi("GET", "bank_product_get.php")
			.then(res => res.data)
			.then(response => {
				const files = response.data;
				this.setState({ products: files });
			})
			.catch(err => {
				console.log(err);
			});
	};
	previousPop = () => {
		$("#out_reason").modal("show");
		$("#another-reason").modal("hide");
	}
	BacktoFile = () => {
		$("#out_reason").modal("hide");
		const fileid1: any = localStorage.getItem("currentfileId");

		this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
	}
	// fetchData = async () => {
	// 	try {
	// 		const response = await fetch('http://13.232.234.192/law_hands/api/total_report.php');
	// 		const jsonData = await response.json();
	// 		this.setState({ data: jsonData });
	// 	} catch (error) {
	// 		console.log('Error:', error);
	// 	}
	// };

	getOutTime = () => {
		let date = new Date();
		let stratTime = date.getTime();
		localStorage.setItem("startTime", JSON.stringify(stratTime))
		let outDate = date.toLocaleDateString();
		localStorage.setItem("OutDateGet", JSON.stringify(outDate));
		var Railway = date.getMinutes();
		if (Railway < 10) {
			var TotalMinutes = "0" + Railway;
			let ouTtime = date.getHours() + ":" + TotalMinutes;
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}
		else {
			let ouTtime: any = date.getHours() + ":" + date.getMinutes();
			localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
		}
	}
	ouTime = () => {
		const getfileId: any = localStorage.getItem("currentfileId");
		console.log("54", JSON.parse(getfileId))
		console.log("32", getfileId);
		if (localStorage.getItem("currentfileId")) {
			$("#out_reason").modal("show");
		}
	}

	outimeGet = () => {
		const fileid1: any = localStorage.getItem("currentfileId");
		const userid1: any = localStorage.getItem("user1");
		const inreason: any = localStorage.getItem("in_reason");
		const intime: any = localStorage.getItem("IntimeGet");
		console.log("70", intime);
		const outtime: any = localStorage.getItem("outtimeGet");
		console.log("73", outtime);
		const outdate: any = localStorage.getItem("OutDateGet");
		const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
		console.log('Second Spn', secondsSpent);
	}




	onchange = (e: any) => {
		this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
	};

	reGetvalue = () => {
		// $("#processeodTable").fnClearTable(this)
		let obj = localStorage.getItem("EodReport");
		console.log("55", obj);
		callApi("POST", "process_eod.php", obj)
			.then(res => {
				let data = [];
				localStorage.removeItem("EodReport");
				console.log(localStorage.removeItem("EodReport"));
				if (res.data && res.data.responseJson && res.data.responseJson.data) {
					data = res.data.responseJson.data;
				}
				this.setState({ files: data ? data : [] });

				const user: any = localStorage.getItem("user");
				const role_id = user ? JSON.parse(user)["role_id"] : "";
				const user_id = user ? JSON.parse(user)["userId"] : null;
				const userDetails = user ? JSON.parse(user) : "";
				const currentDate = new Date();
				var dateString = moment(currentDate).format('DD-MM-YYYY')

				$("#processeodTable").DataTable({
					dom: 'Bfrtip',
					//buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
					buttons: [{ extend: 'excel', text: "Download As Excel" }]

				});
			})
			.catch(err => {
				console.log(err);
				this.setState({ files: [] });
			})
	}
	getUsers = () => {
		const user: any = localStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";
		const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
		const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
		if (isAdmin) {
			callApi("GET", "user_get.php")
				.then(res => res.data)
				.then(response => { this.setState({ users: response.data }); })
				.catch(err => {
					console.log(err);
				});
		}
		else {
			callApi("POST", "team_leader.php", { "user_id": user_id })
				.then(res => res.data)
				.then(response => {
					console.log("210", response);
					this.setState({ users: response.data });

				})
				.catch(err => {
					console.log(err);
				});
		}
	};
	goToFileDashboard = fileName => {
		const url = "/process-eod";
		localStorage.setItem("url", url);
		let obj = { "file_id": fileName }
		localStorage.setItem("thisPopup", JSON.stringify(obj))
		localStorage.setItem("currentfileId", JSON.stringify(fileName));
		this.props.history.push("/file-dashboard/" + fileName);
	};

	getLawHandsBranches() {
		callApi("GET", "get_branch.php")
			.then(res => res.data)
			.then(response => {
				this.setState({ lawhandsBranches: response.data });
			})
			.catch(err => {
				console.log(err);
			});
	}

	getEodTableData = () => {
		var table = $('#processeodTable').DataTable();
		table.destroy();
		const user: any = localStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";
		const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
		const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
		console.log("this.state--->>", this.state);

		let obj: any = {
			// id: this.state.id,
			from_date: this.state.fromDate,
			to_date: this.state.toDate,
			emp_id: this.state.assigned_to,
			branch: this.state.lawhands_branch,
			bank: this.state.bank_name,
			bank_branch: this.state.branch,
			product_id: this.state.product_id,
		};

		callApi("POST", "total_report.php", obj)
			.then(res => {
				console.log("116", res);
				let data = [];
				if (res.data && res.data.responseJson && res.data.responseJson.data) {
					data = res.data.responseJson.data;

				}
				this.setState({ files: data ? data : [] });
				const user: any = localStorage.getItem("user");
				const role_id = user ? JSON.parse(user)["role_id"] : "";
				const user_id = user ? JSON.parse(user)["userId"] : null;
				const userDetails = user ? JSON.parse(user) : "";
				const currentDate = new Date();
				var dateString = moment(currentDate).format('DD-MM-YYYY')

				if ($.fn.dataTable.isDataTable('#processeodTable')) {
					this.table = $('#processeodTable').DataTable({
						retrieve: true,
						paging: true,
						dom: 'Bfrtip',
						buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
						//buttons: [{ extend: 'excel', text: "Download As Excel" }]
					});
				}
				else {
					this.table = $('#processeodTable').DataTable({
						paging: true,
						dom: 'Bfrtip',
						buttons: [{ extend: 'excel', text: "Download As Excel", title: `'${userDetails.firstName}' + ('${dateString}')` }]
						//buttons: [{ extend: 'excel', text: "Download As Excel" }]

					});
				}
			})
			.catch(err => {
				console.log(err);
				this.setState({ files: [] });
			})
	}

	getBanks() {
		callApi("GET", "bank_get.php")
			.then(res => res.data)
			.then(result => {
				this.setState({ banks: result.data });
			})
			.catch(err => {
				console.log(err);
			});
	}
	handleChange1 = (e, propName?, fieldType?) => {
		let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
		let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
		if (fieldName === "lawhands_branch") {
			const branch = fieldValue;
			const filteredUsers = this.state.users.filter(
				(itm: any) => itm.branch_id === branch
			);
			this.setState({ [fieldName]: fieldValue });
			this.setState({ filteredUsers });
		}
		if (fieldName === "assigned_to") {
			const employee_id = fieldValue;
			this.setState({ [fieldName]: fieldValue });
		}
		if (fieldName === 'bank_name') {
			const bank_name = fieldValue;
			const state = this.state;
			state[fieldName] = fieldValue;
			this.filterProduct(fieldValue);
		}
		if (fieldName === "product_id") {
			let newProductId = e.product_id;
			this.setState({ [fieldName]: newProductId }, () => {
				console.log("this.state.product_id", this.state.product_id);
			});
		}
		if (fieldName === "branch") {
			const branch = fieldValue;
			console.log("267", branch);
			this.setState({ [fieldName]: fieldValue });
		}
	}
	productFilter = (fieldValue) => {
		this.filterProduct(fieldValue);
	}
	filterProduct = (bankid: any) => {
		if (bankid) {
			const filteredProduct = this.state.products.filter(
				(branch: any) => branch.bank_id === bankid
			);
			const state = this.state;
			state.filteredProduct = filteredProduct;
			this.setState({ ...state });
		} else {
			this.setState({ product_id: "" })
		}
	};
	handleChange = (e, stateName?, type?) => {
		if (e.target.name === "bank_name") {
			this.setState({ todate: e.target.value })
		}
		if (e.target.name === "toDate") {
			this.setState({ todate: e.target.value })
		}
		if (e.target.name === "fromDate") {
			this.setState({ fromdate: e.target.value })
		}
		if (e.target.name === "id") {
			this.setState({ id: e.target.value })
		}
		if (e.target.name === "lawhands_branch") {
			const branch = e.target.value;
			const filteredUsers = this.state.users.filter(
				(itm: any) => itm.branch_id === branch
			);
			this.setState({ filteredUsers });
		}
		this.setState({ [e.target.name]: e.target.value });
	};
	handleDateChange = (date, dateStr, type) => {
		// console.log(date, dateStr);
		this.setState({ [type]: dateStr });
	};



	handleExportToExcel = () => {
		const response = [
			{
				"s.no": 1,
				"organaization_name": "Axis Bank Ltd (HL)",
				"region_name": "Salem",
				"branch_name": "Salem",
				"Lan_no": "",
				"LH_fileNo": "SLM-29606",
				"customer_name": "RAMACHANDRAN",
				"property_owner_name": "Priyadharshini",
				"firstset_receivedDocList": [
					{ "mergedValue": "mother_doc-3433-2009 MD\n" },
					{ "mergedValue": "title_deed-4904-2022 TD\n" },
					{ "mergedValue": "ec_doc-EC\n" },
					{ "mergedValue": "rev_rec_doc-PATTA\n" },
					{ "mergedValue": "rev_rec_doc-PLAN\n" },
					{ "mergedValue": "rev_rec_doc-SURVE REV\n" }
				],
				"first_doc_submit_date": "2022-10-20 17:19:40",
				"first_query": [
					["1] Flow starting with A-Register, Next mother Deed Doc.No-3433/2009 Sale Deed, Then Title Deed Doc.No-4904/2022-"],
					["2] Old A-Register Extract for S.No-46/5A-13 Years Flow"],
					["3] Death, Legal Heir Certificate of Mr.Ramasamy Reddi-13 Years Flow"],
					["4] Original verification-Bank Policy"],
					["1] Flow starting with A-Register, Next mother Deed Doc.No-3433/2009 Sale Deed, Then Title Deed Doc.No-4904/2022-"],
					["3] Death, Legal Heir Certificate of Mr.Ramasamy Reddi-13 Years Flow"],
					["4] Original verification-Bank Policy"]
				],
				"draft_preliminary_report": "2022-10-20 17:59:09",
				"query_pending": [
					["1] Flow starting with A-Register, Next mother Deed Doc.No-3433/2009 Sale Deed, Then Title Deed Doc.No-4904/2022-"],
					["3] Death, Legal Heir Certificate of Mr.Ramasamy Reddi-13 Years Flow"],
					["4] Original verification-Bank Policy"],
					["1] Flow starting with A-Register, Next mother Deed Doc.No-3433/2009 Sale Deed, Then Title Deed Doc.No-4904/2022-"],
					["3] Death, Legal Heir Certificate of Mr.Ramasamy Reddi-13 Years Flow"]
				],
				"query_final": [
					["2] Old A-Register Extract for S.No-46/5A-13 Years Flow-2022-10-20 17:59:09"],
					["4] Original verification-Bank Policy-2022-12-29 23:09:14"]
				],
				"Final_report_released_date": "",
				"Final_status": ""
			}
		];
		const flattenObject = (obj, prefix = '') => {
			const flattened = {};
			for (const key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					const propName = prefix ? `${prefix}_${key}` : key;
					if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
						const nested = flattenObject(obj[key], propName);
						Object.assign(flattened, nested);
					} else {
						flattened[propName] = obj[key];
					}
				}
			}
			return flattened;
		};

		const flattenedResponse = response.map(obj => flattenObject(obj));
		const worksheet = XLSX.utils.json_to_sheet(flattenedResponse);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx' });
		const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		FileSaver.saveAs(data, 'response.xlsx');
	};
	getBankBranches() {
		callApi("GET", "bank_branch_get.php")
			.then((res) => res.data)
			.then((result) => {
				this.setState({ branches: result.data, filteredBranches: [] });
				if (this.state.bank_name) {
					this.filterBranches(this.state.bank_name);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}
	filterBranches = (bankid) => {
		const filteredBranches = this.state.branches.filter(
			(branch: any) => branch.bank_id === bankid
		);
		const state = this.state;
		state.filteredBranches = filteredBranches;
		this.setState({ ...state });
	};
	lawBranchName = (type: any) => {
		if (type == 2) {
			console.log("431", type)
			this.setState({ lhBranchShow: true })
			this.getLawHandsBranches();

		} else {
			console.log("436", type)
		}
	}
	bankName = (type: any) => {
		if (type == 2) {
			console.log("431", type)
			this.setState({ showBank: true })
			this.getBanks();
		} else {
			console.log("445", type)
		}
	}
	branchName = (type: any) => {
		if (type == 2) {
			console.log("450", type)
			this.setState({ bankBranch: true })
			this.getBankBranches();
		} else {
			console.log("454", type)
		}
	}
	render() {
		const columns = [
			{
				header: 'S.No',
				key: 'index',
				td: (file) => <td onClick={() =>
					this.goToFileDashboard(file.id.slice(3))
				}>{file.index}</td>
			},
			{
				header: 'Team Lead Name',
				key: 'team_leader',
				td: (file) =>
					<h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.team_leader}</h6>
			},
			{
				header: 'Process Officer Name',
				key: 'process_officer',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.process_officer}</h6>
			},

			{
				header: 'Process Start Date',
				key: 'process_date',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.process_date}</h6>
			},
			{
				header: "File No",
				key: 'id',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.id}</h6>

			},
			{
				header: 'File Branch',
				key: 'lawhands_branch',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.lawhands_branch}</h6>
			},
			{
				header: 'Bank Name',
				key: 'bank_name',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.bank_name}</h6>
			},
			{
				header: 'New Login ',
				key: 'intime_reason',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.intime_reason}</h6>
			},
			{

				header: 'Highlights',
				key: 'highlight',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.highlight === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Data Entry',
				key: 'date_enter',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.date_enter === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Flow Chart',
				key: 'flowchart',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.flowchart === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			},
			{
				header: 'Queries',
				key: 'query',
				td: (file) => <div onClick={() => this.goToFileDashboard(file.id.slice(3))} className={file.query === "YES" ? "text-c-green1" : "text-c-red1"}></div>
			}, {
				header: 'Status',
				key: 'outtime_reason',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.outtime_reason}</h6>
			}, {
				header: 'Total Mints',
				key: 'total_minutes',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.total_minutes}:Mints</h6>
			},
			{
				header: 'File Start Time',
				key: 'intime',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.intime}</h6>
			},
			{
				header: 'File End Time',
				key: 'outtime',
				td: (file) => <h6 onClick={() => this.goToFileDashboard(file.id.slice(3))}>{file.outtime}</h6>
			}

		];
		const data = this.state.files;
		const user: any = localStorage.getItem("user");
		const role_id = user ? JSON.parse(user)["role_id"] : "";
		const user_id = user ? JSON.parse(user)["userId"] : null;
		const userDetails = user ? JSON.parse(user) : "";

		const isFront = userDetails.role_id === "3" || userDetails.role_id === '4';
		const { toDate, setResponse, selectVal, bankBranch, branchName, showBank, lawBranchName, lhBranchShow, bankName } = this.state;
		const lawhandsBranchesoptions = [
			{ value: "", label: "All" },
			...this.state.lawhandsBranches
		];
		const usersoptions = [
			{ value: "", label: "All" },
			...this.state.users
		];
		const banksoptions = [
			{ value: "", label: "All" },
			...this.state.banks
		];
		const filteredBranchesoptions = [
			{ value: "", label: "All" },
			...this.state.filteredBranches
		];
		const filteredProduct = [
			{ value: "", label: "" },
			...this.state.filteredProduct
		];
		return (
			<section className="pcoded-main-container">
				<ToastsContainer
					store={ToastsStore}
					position={ToastsContainerPosition.TOP_RIGHT}
				/>{" "}
				<div className="pcoded-wrapper">
					<div className="pcoded-content">
						<div className="pcoded-inner-content">
							<div className="main-body">
								<div className="page-wrapper">
									<div className="row">
										<div className="col-sm-12">
											<div className="card" style={{ overflow: 'hidden' }}>
												<div className="card-header">
													<h2 className="file-status-header" style={{ display: "flex", justifyContent: "center" }}>Lawhands Mis report</h2>
													<div className="row mb-3">
														<div className="col-xl-2 col-md-2">
															<h6>From</h6>
															<DatePicker format={'DD-MM-YYYY'}
																onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'fromDate')} />
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>To</h6>
															<DatePicker format={'DD-MM-YYYY'}
																onChange={(date, dateStr) => this.handleDateChange(date, dateStr, 'toDate')} />
														</div>

														<div className="col-xl-2 col-md-2">
															<h6>LH Branch</h6>
															{this.state.lawhandsBranches &&
																this.state.lawhandsBranches.length > 0 &&
																this.state.lawhandsBranches.map((opt: any) => {
																	opt.label = opt.name;
																	opt.value = opt.id;
																}) && (
																	<Select
																		options={lawhandsBranchesoptions}
																		name="lawhands_branch"
																		value={this.state.lawhandsBranches.find(
																			(o: any) =>
																				o.value === this.state.lawHandsBranch
																		)}

																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"lawhands_branch",
																				"singleselect"
																			)
																		}
																	/>
																)}
														</div>

														<div className="col-xl-2 col-md-2" >
															<h6>Bank Name</h6>
															{this.state.banks &&
																this.state.banks.length > 0 &&
																this.state.banks.map((opt: any) => {
																	opt.label = opt.name;
																	opt.value = opt.id;
																}) && (
																	<Select

																		options={banksoptions}
																		name="bank_name"
																		value={this.state.banks.find(
																			(o: any) =>
																				o.value === this.state.bank_private_name
																		)}
																		// onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"bank_name",
																				"singleselect"
																			)
																		}
																	/>
																)}
														</div>
														<div className="col-xl-2 col-md-2">
															{/* <div className="form-group"> */}
															<h6>Product</h6>
															{this.state.products && this.state.products.map((opt: any) => {
																opt.label = opt.product_name; opt.value = opt.id
															}) &&
																<Select options={filteredProduct} name="product_id"
																	value={this.state.filteredProduct.find((o: any) => o.value === this.state.product_id ? this.state.product_id : "")}
																	onChange={(e) => this.handleChange1(e, 'product_id', 'singleselect')}
																/>
															}
															{/* </div> */}
														</div>
														<div className="col-xl-2 col-md-2">
															<h6>Bank Branch</h6>
															{this.state.branches &&
																this.state.branches.length > 0 &&
																this.state.branches.map((opt: any) => {
																	opt.label = opt.name;
																	opt.value = opt.id;
																}) && (
																	<Select
																		options={filteredBranchesoptions}
																		name="branch"
																		value={this.state.filteredBranches.find(
																			(o: any) => o.value === this.state.branch
																		)}
																		onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
																		onChange={(e) =>
																			this.handleChange1(
																				e,
																				"branch",
																				"singleselect"
																			)
																		}
																	/>
																)
															}
														</div>

														{/* <div className="col-xl-2 col-md-2">
															<button
																type="submit"
																className="btn btn-primary"
																style={{ float: 'right', marginTop: "25px" }}
																onClick={() => this.getEodTableData()}
															>
																Search
															</button>
														</div> */}

													</div>
													<div className="row">
														<div className="col-xl-2 col-md-2">
															<h6>Employee Name</h6>
															{this.state.users && this.state.users.length > 0 && this.state.users.map((opt: any) => {
																opt.label = opt.name; opt.value = opt.id

															}) &&
																<Select options={usersoptions} name="assigned_to"
																	value={this.state.users.find((o: any) => o.value === this.state.assigned_to)}
																	onChange={(e) => this.handleChange1(e, 'assigned_to', 'singleselect')}
																	required
																/>
															}
														</div>
														<div className="col-xl-10 col-md-10 p-3">
															<button
																type="submit"
																className="btn btn-primary"
																style={{ float: 'right' }}
																onClick={() => this.getEodTableData()}
															>
																Search
															</button>
														</div>
													</div>
													{/* <div className="row">
														<div className="col-xl-12 col-md-12 p-3">
															<button
																type="submit"
																className="btn btn-primary"
																style={{ float: 'right' }}
																onClick={() => this.getEodTableData()}
															>
																Search
															</button>
														</div>
													</div> */}
												</div>
												<div className="card-body">
													<div className="dt-responsive table-responsive" style={{ overflow: 'auto' }}>
														<table id="processeodTable" className="table table-striped table-bordered nowrap">
															<thead>
																<tr>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>Sl.No</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>Organisation Name</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>Region Name</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>Bank Branch Name</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>LAN No</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>LH File No</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>Customer Name</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>Property Owner Name</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>1st Set -Received Doc list</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>1st set Doc Submitted <br />Date with Time Login</th>
																	<th style={{ position: 'sticky', top: 0, background: 'white' }}>1st-Queries</th>
																	<th style={{ ...{ width: '20px' }, ...{ position: 'sticky', top: 0, zIndex: 1, background: 'white' } }}>
																		Draft/preliminary <br />report Released with  <br />Query - Date & Time
																	</th>
																	{/* <th style={{ width: '100px' }} >Draft/preliminary  report <br />Released with Query - Date & Time</th> */}
																	<th style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>Current Pending Queries</th>
																	<th style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>Final Query Doc submission<br /> by IFBL Date with Time</th>
																	<th style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>Final Report released<br /> Date with Time</th>
																	<th style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}>Final Status Queries <br /> not cleared / Final Report Pending<br /> / Report Released </th>

																</tr>
															</thead>
															<tbody>

																{this.state.files && this.state.files.length
																	? this.state.files.map(
																		(query: any, index: number) => {
																			return (
																				<tr className="query-table-row cursor-pointer" key={index}>
																					<td className="text-align-center">{index + 1}</td>
																					<td className="click">
																						{query.organaization_name}{query.product_name ? <>({query.product_name})</> : ""}
																					</td>
																					<td className="click">
																						{query.region_name}
																					</td>
																					<td className="click">
																						{query.branch_name}
																					</td>
																					<td className="click">
																						{query.Lan_no}
																					</td>
																					<td className="click">
																						{query.LH_fileNo}
																					</td>
																					<td className="click">
																						{query.customer_name}
																					</td>
																					<td className="click">
																						{query.property_owner_name}
																					</td>
																					<td className="click">
																						{query.firstset_receivedDocList && query.firstset_receivedDocList.length > 0 ? (query.firstset_receivedDocList.map((list: any, index: any) => {
																							const mergedValueWithLineBreaks = list.mergedValue.replace(/\n/g, "\r\n");
																							return (
																								<p key={index}>{index + 1 + ")"}{mergedValueWithLineBreaks}</p>
																							);
																						})) : (
																							<p>Nil</p>
																						)}
																					</td>
																					<td className="click">
																						{query.first_doc_submit_date}
																					</td>

																					<td className="click">
																						{query.first_query && query.first_query.length > 0 ? (query.first_query.map((list: any, index: any) => {
																							const mergedValueWithLineBreaks = list.mergedValue.replace(/\n/g, "\r\n");
																							return (
																								<p key={index}>{mergedValueWithLineBreaks}</p>
																							);
																						})) : (
																							<p>Nil</p>
																						)}
																					</td>

																					<td className="click">
																						{query.draft_preliminary_report}
																					</td>

																					<td className="click">
																						{query.query_pending && query.query_pending.length > 0 ? (query.query_pending.map((list: any, index: any) => {
																							const mergedValueWithLineBreaks = list.mergedValue.replace(/\n/g, "\r\n");
																							return (
																								<p key={index}>{mergedValueWithLineBreaks}</p>
																							);
																						})) : (
																							<p>Nil</p>
																						)}
																					</td>
																					<td className="click">
																						{query.query_final ? query.query_final : "Nil"}
																					</td>

																					<td className="click">
																						{query.Final_report_released_date ? query.Final_report_released_date : "Nil"}
																					</td>
																					<td className="click">
																						{query.Final_status}

																					</td>
																				</tr>
																			);
																		}
																	)
																	: ""}


															</tbody>

														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</section>
		);
	}
}


export default LawhandsMis;

function x(arg0: string, x: any) {
	throw new Error("Function not implemented.");
}
