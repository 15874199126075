import { CircularProgress } from "@material-ui/core";
import PDFViewer from "mgr-pdf-viewer-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import "./work-file.css"
import { setTimeout } from "timers";
import { API_ENDPOINT, callApi } from "../../utils/api";
import { ADMIN_ROLE, FRONTOFFICE_ROLE, MANAGER_ROLE } from "../../utils/role";
import ReactFileReader from "react-file-reader";
import { saveAs } from "file-saver";
import { notifyToUser } from "../../utils/firebase";
import moment from "moment";
import { pushnotificationApi } from "../../utils/pushNotify";
import CountdownTimer from "../file/CountdownTimer";

declare var $: any;
interface AllProps {
  id: any;
  isFinal: boolean;
  checkOverviewApproval: any;
  history: any;
  fileId: any;
}
export default class Overview extends Component<AllProps> {
  state = {
    showTitle: true,
    isComplete: true,
    report: "",
    rep_id: "",
    remote_users: [],
    isapproved: 0,
    office_users: [],
    responseURL: "",
    pdfPreview: "",
    realFileidfk: "",
    file: "",
    pdfurl: "",
    enableRefresh: true,
    isApprove: false,
    isVerify: false,
    action: "",
    reason: "",
    uploadFile: {
      base64: "",
      fileList: [{ name: "" }],
    },
    updateDownloadData: {
      reasonForDownload: [],
      updatedDetails: [],
    },
    lastUploadedDocument: {
      pdf: null,
      docx: null,
    },
    timer: 0,
    blockdownload: false,
  };
  rendered: any;
  componentDidMount() {
    this.getFileOverview();
    this.blockdownload();
    if (this.props.isFinal) {
      this.getUsers();
    }
    this.checkApproveVerify();
    this.getUpdateReasonData();
    setInterval(() => {
      this.incrementTimer();
    }, 1000);
  }
  incrementTimer = () => {
    const { timer } = this.state;
    this.setState({ timer: timer + 1 });
  };
  blockdownload = () => {
    callApi("POST", "block_download_status.php", {
      file_idfk: this.props.id,
    })
      .then(res => res.data)
      .then(response => {
        this.setState({ blockdownload: response['responseJson'].data[0].block_download_status })
        console.log('blockdownload___>>>', this.state, response['responseJson'].data[0].block_download_status);
      })
      .catch(err => {
        console.log(err);
      });
  };
  getFileOverview = () => {
    callApi("POST", "final_report_get.php", {
      file_idfk: this.props.id,
      check: false,
    })
      .then((res) => res.data)
      .then((response) => {
        let realFileidfk = response.data[0].file_idfk;

        this.setState({
          realFileidfk: 0,
        });
        this.delaylaoding(realFileidfk);
        // let pdfurl1 = API_ENDPOINT +'api/doc_securty/'+'LAW-'+this.state.realFileidfk+'.pdf';
        // const tryRequire = (pdfurl1) => {
        //   try {
        //    return require(`${pdfurl1}`);
        //   } catch (err) {
        //    return null;
        //   }
        // };
        if (response.data[0].file_idfk !== null) {
          callApi(
            "POST",
            "print_preview.php?id=" + response.data[0].file_idfk,
            0
          )
            .then((res) => res.data)
            .then((response) => {
              // console.log(response.data);
              let previewURL = response.data;
              let previewEcho = API_ENDPOINT + "api/" + previewURL;

              let pdfPreview = response.data[0].file_idfk;
              this.setState({
                pdfPreview,
              });
              // try {
              //   console.log(require(`${previewEcho}`));
              //  } catch (err) {
              //   console.log(null);
              //  }
            });
        }
        // if (response && response.data && response.data.length) {
        //   if (response.data[0].rep_id) {
        //     const state = response.data[0];
        //     this.setState({ ...state });
        //   } else {
        //     this.scrutinyReport();
        //   }
        //   this.setState({ isapproved: response.data[0].isapproved });
        // }
        var docOverview = localStorage.getItem("overviewDocument");
        console.log("docOverview-------->>>", docOverview);
        console.log("currentFile-------->>>", localStorage.getItem("currentFile"));
        if (this.props.id == localStorage.getItem("currentFile")) {
          if (docOverview != null) {
            this.setState({ report: docOverview, enableRefresh: true });
            $("#root").unbind();
          } else {
            this.scrutinyReport();
          }
        } else {
          this.scrutinyReport();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  scrutinyReport = () => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const timeoutDuration = 100000;

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        // window.location.reload();
        this.setState({ timer: 0 });
        this.incrementTimer();
        reject(new Error('Timeout'));
      }, timeoutDuration);
    });

    Promise.race([
      callApi("POST", "template/tests/scrutiny_report.php", {
        file_idfk: this.props.id,
        check: false,
        userId,
      }).then((res) => res.data),
      timeoutPromise
    ]).then((response: any) => {
      if (response && response.data) {
        console.log("response.data------>>>>>", response);
        localStorage.setItem("overviewDocument", response.data);
        var currentFile = response.file_name.split("-");
        localStorage.setItem("currentFile", currentFile[1]);
        this.setState({ report: response.data, enableRefresh: true });
        $("#root").unbind();
      } else {
        this.setState({ enableRefresh: false });
        this.scrutinyReport();
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ enableRefresh: false });
      this.scrutinyReport();
    });
  };

  delaylaoding = (realFileidfk) => {
    setTimeout(() => {
      console.log(realFileidfk);
      this.setState({
        realFileidfk,
      });
    }, 10000);
  };
  resetOverview = () => {
    callApi("POST", "report_delete.php", { id: this.props.id })
      .then((res) => res.data)
      .then((response) => {
        ToastsStore.success("Reset success!");
        this.getFileOverview();
      });
  };
  downloadPDF = () => {
    const input: any = document.getElementById("report-content");
    let mywindow: any = window.open(
      "",
      "PRINT",
      "height=650,width=900,top=100,left=150"
    );
    mywindow.document.write(input.innerHTML);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    ToastsStore.success("Downloaded Successfully!");
    return true;
  };
  saveReport = (status) => {
    // const report: any = document.getElementById("report-content");
    const reportInput: any = this.state;
    const user: any = localStorage.getItem("user");
    reportInput.userId = user ? JSON.parse(user)["userId"] : "";
    // reportInput.report = report.innerHTML;
    reportInput.file_idfk = this.props.id;
    reportInput.status = status;
    delete reportInput.isapproved;
    delete reportInput.office_users;
    delete reportInput.remote_users;
    callApi("POST", "final_report.php", reportInput)
      .then((res) => res.data)
      .then((response) => {
        if (status === 0)
          ToastsStore.success("Saved & Submitted Successfully!");
        else ToastsStore.success("Saved Successfully!");
        this.getFileOverview();
        this.props.checkOverviewApproval();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getUsers = () => {
    callApi("POST", "office_details.php", {
      id: this.props.id,
    })
      .then((res) => res.data)
      .then((response) => {
        this.setState({ office_users: response.data });
      });
  };

  getUpdateReasonData = () => {
    const reasonData: any = this.state.updateDownloadData;
    callApi("POST", "dashboard_log.php", { id: this.props.id })
      .then((res) => res.data)
      .then((result) => {
        let { lastUploadedDocument } = this.state;
        reasonData.reasonForDownload = result.data.reasonForDownload;
        // Object.keys(result.data[0][1]).map(key => result.data[0][1][key]);
        reasonData.updatedDetails = result.data.updatedDetails;
        if (reasonData.updatedDetails && reasonData.updatedDetails.length > 0) {
          lastUploadedDocument = reasonData.updatedDetails[0].url[0];
        }
        // result.data[0][0];
        console.log("reasonData", reasonData);
        this.setState({ updateDownloadData: reasonData, lastUploadedDocument });
      })
      .catch((err) => {
        console.log("Errororor", err);
        // ToastsStore.error("Failed to get ");
      });
  };

  //Download docs from external api
  downloadDocs = () => {
    const { lastUploadedDocument } = this.state;

    if (lastUploadedDocument.docx) {
      callApi("POST", "get_branch_code.php", {
        id: this.props.id,
      })
        .then((res) => res.data)
        .then((response) => {
          console.log(response);
          const name = `${response.data[0].lawhands_branch}-${this.props.id}.docx`;
          lastUploadedDocument.docx && saveAs(lastUploadedDocument.docx, name);
        });
    } else {
      callApi("POST", "template/tests/shell.php", {
        id: this.props.id,
        responseType: "blob",
      })
        .then((res) => res.data)
        .then((response) => {
          //ToastsStore.success("Downloaded Successfully!");
          window.open(
            API_ENDPOINT + "template/tests/shell.php?id=" + this.props.id,
            "_self"
          );
        });
    }
    // ToastsStore.success("Downloaded Successful!");
  };

  selectUser = (e: any, user: any) => {
    const remote_users: any = this.state.remote_users;
    if (e.target.checked) {
      remote_users.push({ email: user.email, name: user.name });
    } else {
      remote_users.filter((itm) => itm.email !== user.email);
    }
    this.setState({ remote_users });
  };
  sendMail = () => {
    const mailInput: any = Object.create({});
    mailInput.fod_fk = this.props.id;
    mailInput.remote_users = this.state.remote_users;
    const user: any = localStorage.getItem("user");
    mailInput.user_idfk = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "report_mail.php", mailInput)
      .then((res) => res.data)
      .then((response) => { })
      .catch((err) => {
        console.log(err);
      });
  };
  approveFile = () => {
    const mailInput: any = Object.create({});
    mailInput.fod_fk = this.props.id;
    mailInput.status = this.state.isapproved ? 0 : 1;
    const user: any = localStorage.getItem("user");
    mailInput.user_idfk = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "report_approve.php", mailInput)
      .then((res) => res.data)
      .then((response) => {
        if (this.state.isapproved) ToastsStore.success("Reworked");
        else ToastsStore.success("Approved");
        this.getFileOverview();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onEditorChange(evt) {
    this.setState({
      report: evt.editor.getData(),
    });
  }
  checkApproveVerify = () => {
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    callApi("POST", "get_approved_status.php", {
      id: this.props.id,
      userId,
    })
      .then((res) => res.data)
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.setState({
            isApprove: Number(response.data[0].is_approved),
            isVerify: Number(response.data[0].is_verified),
          });
        }
      });
  };
  fileVerification = () => {
    callApi("POST", "set_verify.php", {
      id: this.props.id,
      status: this.state.isVerify ? 0 : 1,
    })
      .then((res) => res.data)
      .then((response) => {
        this.checkApproveVerify();
      });
  };
  fileApproval = () => {
    callApi("POST", "set_approve.php", {
      id: this.props.id,
      status: this.state.isApprove ? 0 : 1,
    })
      .then((res) => res.data)
      .then((response) => {
        this.checkApproveVerify();
      });
  };
  actionHandle = (action) => {
    this.setState({ action });
  };
  handleReasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };
  validateReason = () => {
    if (this.state.reason === "") {
      ToastsStore.error("Please Enter Reason");
    } else {
      if (this.state.action === "download") {
        this.downloadDoc();
      } else {
        this.uploadDoc();
      }
    }
  };
  downloadDoc = () => {
    $("#reason-modal").modal("hide");
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const obj = {
      fileid: this.props.id,
      reason: this.state.reason,
      createdBy: userId,
    };
    callApi("POST", "download_log.php", obj)
      .then((res) => res)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // ToastsStore.success("work file Q & A are saved succesfully")
          ToastsStore.success("Reason saved successfully");
          this.downloadDocs();
          this.clearModal();
        } else {
          ToastsStore.error("Error in storing, Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  uploadFile = (file: any) => {
    console.log(file);
    this.setState({ uploadFile: file });
  };
  uploadDoc = () => {
    $("#reason-modal").modal("hide");
    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const obj = {
      fileId: this.props.id,
      reason: this.state.reason,
      createdBy: userId,
      file: this.state.uploadFile.base64.split(",")[1],
      errorTypes: ["1", "2"],
      errorSection: ["1", "2"],
    };
    callApi("POST", "template/tests/upload_log.php", obj)
      .then((res) => res)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          this.getUpdateReasonData();
          this.setState({ isComplete: false });
          // ToastsStore.success("work file Q & A are saved succesfully")
          ToastsStore.success("Reason saved successfully");
          this.clearModal();
        } else {
          ToastsStore.error("Error in storing, Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  clearModal = () => {
    this.setState({
      reason: "",
    });
  };
  enableModalHandler = () => {
    if (this.state.blockdownload == true) {
      this.completeFlowHandler();
    } else {
      $("#complete-process").modal("show");
    }
  };
  close = () => {
    $("#processComplete").modal('hide');
    // localStorage.removeItem("currentfileId");
    setTimeout(() => {
      this.props.history.push("/inprogress-file");
    }, 2000);
  }

  overviewtime() {
    const loginuser: any = localStorage.getItem("user1");
    var now = new Date();
    var date = now.toLocaleDateString();
    var time = now.toLocaleTimeString();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const currenttime = `${hours}:${minutes}:${seconds}`;
    const currentdate = moment(date).format("YYYY-MM-DD");
    const obj = {
      id: this.props.id,
      typingcompleted_time: currenttime,
      typingcompleted_date: currentdate,
      typingcompleted_by: JSON.parse(loginuser),
      reason: "Typing Completed",
      created_by: JSON.parse(loginuser),
      color: "#edada0"
    };
    callApi("POST", "time_update.php", obj)
      .then(res => res.data)
      .then(response => {

      });
  }
  countdownTimerRef: CountdownTimer | null = null;
  completeFlowHandler = () => {
    this.setState({ showTitle: true })

    const user: any = localStorage.getItem("user");
    const userId = user ? JSON.parse(user)["userId"] : "";
    const report_to = user ? JSON.parse(user)["report_to"] : "";
    // file status 6 => moved to "files to be verfied"
    callApi("POST", "file_status.php", {
      id: this.props.fileId,
      file_status: 6,
    })
      .then((res) => res)
      .then((response) => {
        console.log(response);
        this.setState({ showTitle: false })
        if (response.status === 200) {
          localStorage.setItem("file_completed_status", JSON.stringify(true));
          if (this.countdownTimerRef) {
            this.countdownTimerRef.clearInterval();
            console.log("file out");
          }
          this.overviewtime();
          $("#processComplete").modal('show');
          const user: any = localStorage.getItem("user");
          const userId = user ? JSON.parse(user)["userId"] : "";
          const userName = user ? JSON.parse(user)["firstName"] : "";
          const notifyData = {
            title: `${this.props.fileId}Typing Completed By ${userName}`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
          };
          pushnotificationApi(notifyData, userId);
          const branchManger: any = localStorage.getItem("branch_manager");
          const branch_Manager = JSON.parse(branchManger);
          const queryOfficer: any = localStorage.getItem("Query_officer");
          const query_ooficer = JSON.parse(queryOfficer);
          const Scan_officer: any = localStorage.getItem("scan_officer");
          const sacanofficer = JSON.parse(Scan_officer);
          const process_mennager: any = localStorage.getItem("process_manager");
          const processManager = JSON.parse(process_mennager);
          if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
            const branchManager = branch_Manager.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: branchManager,
              ...notifyData,
              reason: `${this.props.fileId}Typing Completed`
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "notify" }, query_ooficer);
            const OfficerQuery = query_ooficer.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: OfficerQuery,
              ...notifyData,
              reason: `${this.props.fileId}Typing Completed`
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }
          // if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
          //   notifyToUser("user", { ...notifyData, notifyType: "message" }, sacanofficer);
          // }
          if (Array.isArray(processManager) && processManager.length > 0) {
            notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
            const ProcessManager = processManager.join(",");
            let obj = {
              notification_type: "typingcomplete",
              user_id: ProcessManager,
              ...notifyData,
              reason: `${this.props.fileId}Typing Completed`
            }
            callApi("POST", "pushnotification.php", obj)
              .then((res) => res.data)
              .then((response) => {
              })
              .catch((err) => {
                console.log(err);
              });
          }


        } else {
          ToastsStore.error("Error in completing the file , Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const user: any = localStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const {
      action,
      reason,
      uploadFile,
      lastUploadedDocument,
      timer,
      isComplete,
      showTitle
    } = this.state;
    return this.state.report ? (
      <>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        {this.props.isFinal ? (
          <>
            {this.state.isapproved ? (
              <>
                <a
                  className="btn btn-primary"
                  href={API_ENDPOINT + "api/print.php?id=" + this.props.id}
                  target="_blank"
                >
                  Download
                </a>
                <a
                  className="btn btn-primary md-trigger"
                  data-toggle="modal"
                  href="#myModal"
                >
                  Mail To Users
                </a>
              </>
            ) : (
              <>
                <button
                  className="btn btn-primary"
                  style={{ cursor: "not-allowed" }}
                  disabled={true}
                >
                  Download
                </button>
                <button
                  className="btn btn-primary"
                  style={{ cursor: "not-allowed" }}
                  disabled={true}
                >
                  Mail To Users
                </button>
              </>
            )}
            {(role_id === MANAGER_ROLE || role_id === ADMIN_ROLE) && (
              <button
                className={
                  this.state.isapproved ? "btn btn-danger" : "btn btn-success"
                }
                onClick={this.approveFile}
              >
                {this.state.isapproved ? "Rework" : "Approve"}
              </button>
            )}
            {this.state.isapproved ? (
              <a
                className="btn btn-primary"
                href={API_ENDPOINT + "api/print.php?id=" + this.props.id}
                target="_blank"
              >
                Vetting Report
              </a>
            ) : ("")}
          </>
        ) : (
          <>
            {this.state.blockdownload == false ? (
              <>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => this.actionHandle("download")}
                  data-toggle="modal"
                  data-target="#reason-modal"
                  style={{ fontWeight: "bold", borderWidth: "2px" }}
                >
                  Download Doc <i className="fa fa-download" />
                </button>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => this.actionHandle("upload")}
                  data-toggle="modal"
                  data-target="#reason-modal"
                  style={{ fontWeight: "bold", borderWidth: "2px" }}
                >
                  Upload Doc <i className="fa fa-upload" />
                </button>
              </>) :
              (<></>)
            }
            <button
              className="btn btn-outline-dark"
              onClick={() =>
                this.props.history.push("/work-file-qa/" + this.props.id)
              }
              style={{ fontWeight: "bold", borderWidth: "2px" }}
            >
              Work File Q&amp;A
            </button>
            <button
              className="btn btn-outline-dark"
              onClick={() =>
                this.props.history.push("/work-file-details/" + this.props.id)
              }
              style={{ fontWeight: "bold", borderWidth: "2px" }}
            >
              Check
            </button>
            {isComplete && (
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: "0px", float: "right" }}
                onClick={this.enableModalHandler}
              >
                Complete
              </button>
            )}

            {!isComplete && (
              <>
                <button
                  onClick={() => this.completeFlowHandler()}
                  type="button"
                  className="btn btn-primary"
                  style={{ marginTop: "0px", float: "right" }}
                >
                  Complete
                </button>
              </>
            )}
          </>
        )}
        <div className="modal" id="reason-modal">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                {action === "download" ? (
                  <h5 className="modal-title">Reason For Download</h5>
                ) : (
                  <h5 className="modal-title">Reason For Upload</h5>
                )}
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  {action === "download" ? (
                    <label>Enter Reason*</label>
                  ) : (
                    <React.Fragment>
                      <label>Enter Updated Informations*</label>
                      <ReactFileReader
                        fileTypes={[".doc", ".docx"]}
                        base64={true}
                        // multipleFiles={true}
                        handleFiles={(e) => this.uploadFile(e)}
                      >
                        <button
                          className="ml2 btn btn-success btnupload"
                        // disabled={this.state.isFlowChartUploading}
                        >
                          {/* {this.state.isFlowChartUploading ? (
                                          <i className="fa fa-spinner fa-spin"></i>
                                      ) : <i className="icon feather icon-upload" />} */}
                          Select File
                        </button>
                      </ReactFileReader>
                      <span>{uploadFile.fileList[0].name}</span>
                    </React.Fragment>
                  )}
                  <textarea
                    className="form-control mt-10"
                    name="reason"
                    value={reason}
                    onChange={this.handleReasonChange}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn " data-dismiss="modal">
                  Cancel
                </button>
                {action === "download" ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.validateReason}
                  >
                    Download
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.validateReason}
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "10px" }} />
        {this.props.isFinal ? (
          <PDFViewer
            document={{
              url:
                API_ENDPOINT +
                "api/doc_securty/" +
                "LAW-" +
                this.state.realFileidfk +
                ".pdf",
            }}
          />
        ) : (
          <iframe
            width="100%"
            height="600"
            frameBorder="0"
            src={this.state.report}
          ></iframe>
        )}
        <div className="modal" id="myModal">
          <div>
            <ToastsContainer
              store={ToastsStore}
              position={ToastsContainerPosition.TOP_RIGHT}
            />
          </div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Send Mail</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {this.state.office_users && this.state.office_users.length
                        ? this.state.office_users.map((user: any, index) => {
                          return (
                            <div
                              className="custom-control custom-checkbox"
                              key={index}
                            >
                              <input
                                type="checkbox"
                                className=""
                                onChange={(e) => this.selectUser(e, user)}
                              />
                              <label className="">
                                {user.name + " - " + user.email}
                              </label>
                            </div>
                          );
                        })
                        : ""}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group" />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={this.sendMail}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="confirmReset">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Confirm Reset</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <p> Are you sure you want to reset document?</p>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={this.resetOverview}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal hide fade in" id="processComplete" data-backdrop="static" data-keyboard="false">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
              <div className="modal-body">
                <h5 style={{ textAlign: 'center' }}> Thanks For Complete Your File:{this.props.id}</h5>
              </div>
              <div className="modal-footer">
                {showTitle ? null : (<button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.close()}
                  // onClick={this.disableModalHandler}
                  style={{ width: '50%', fontSize: '17px', margin: 'auto' }}
                > Ok</button>)}

              </div>


            </div>
          </div>
        </div>

        {/* This is for modal */}
        <div className="modal" id="complete-process">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-body">
                <h3>Without upload document you can't complete the file</h3>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                // onClick={this.disableModalHandler}
                >
                  {" "}
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      // <CircularProgress style={{ marginLeft: '50%' }} />
      <div className="text-center">
        <h4>
          <span>Loading... {timer} seconds</span>
        </h4>
      </div>
    );
  }
}
