import React, { Component, LegacyRef, RefObject } from 'react';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from "react-toasts";
import { callApi, API_ENDPOINT } from "../../utils/api";
import { CircularProgress } from "@material-ui/core";
import ReactFileReader from "react-file-reader";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { isEmpty } from 'lodash';
import { saveAs } from "file-saver";

import GeneralVerification from './generalVerification';
import SurveyNoVerification from './surveyNoVerification';
import Deed from './deed';
import LegalHerisVerification from './legalHerisVerify';
import ECVerification from './ecVerify';
import MissingLOD from './missingLOD';
import Verification from './verification';
import CourtDocVerify from './courtDocVerify';
import VerificationEOD from '../work-file/verificationEod';

import DataModel from './sopDataModel';
import PdfHighlighter from '../PDF-Highlighter';
import moment from 'moment';
import { notifyToUser } from '../../utils/firebase';
import { pushnotificationApi } from '../../utils/pushNotify';
import Flowchart from './flowchart';
import { ADMIN_ROLE, FRONTOFFICE_ROLE, MANAGER_ROLE } from "../../utils/role";
import CountdownTimer from '../../components/file/CountdownTimer';
import { toast } from 'react-toastify';
declare var $: any;

interface AllProps {
    history: any,
    match: any
}
export class VerificationList extends Component<AllProps> {

    saveFlowDeed: any;
    state = {
        showTitle: false,
        isComplete: true,
        base: "",
        data: DataModel,
        report: "",
        numPages: 0,
        isapproved: 0,
        action: "",
        reason: "",
        uploadFile: {
            base64: "",
            fileList: [{ name: "" }],
            autoQuestionsList: [],
            manualQuestionsList: [],
            currentAutoColumnNumber: 1,
            currentManualColumnNumber: 1,
            activeTab: "ManualView",
        },
        verification: {
            // isComplete: false,
            highlight: false,
            dataEntry: false,
            flowChart: false,
            hintFile: false,
            errorIdentified: false,
            noOflod: "",
            errorSection: [],
            otherSection: "",
            errorTypes: [],
            errorDetails: "",
            totalTime: 0,
            userId: '',
            fileId: ''
        },
        type: "",
        allDocuments: [],
        selectedDoc: "",
        isHightlightsLoaded: false,
        documentWidth: 50,
        documentHeight: 600,
        pinchEnabled: false,
        enableTouchPadPinch: false,
        velocityEqualToMove: false,
        enableVelocity: false,
        rotation: 0,
        disabled: true,
        docToPreview: false,
        highlights: [],
        deeds: [],
        updateDownloadData: {
            reasonForDownload: [],
            updatedDetails: []
        },
        lastUploadedDocument: {
            pdf: null,
            docx: null
        },
        shellTemplate: null,
        lastScrutinyDocument: null,
        blockdownload: "",
        hints: [],
        selectedCurrentView: null,
        isGraphModalShown: false,
        isManualDataAvailable: true,
        isManualClicked: false,
        isQuestionsExpanded: false,
        autoQuestionsList: [],
        manualQuestionsList: [],
        currentAutoColumnNumber: 1,
        activeTab: "ManualView",
        currentManualColumnNumber: 1,
        isFlowChartExpanded: true,
        activeMainTab: "",
        flowGraphData: null,
        isflowGraphDataupdated: false,
        deedData: [],
        currentQuestionsList: [],
        initialTimeInMinutes: 0,
        updatedRemainingTime: 0,
        verificationEOD: false,
    };
    document = {
        base: "",
        numPages: 0,
        pageNumber: 1
    };

    remarksRef: LegacyRef<HTMLTextAreaElement> = React.createRef();
    autoGraph: any;
    manualGraph: any;
    manualPaper: any;

    countdownTimerRef: CountdownTimer | null = null;
    componentDidMount() {
        this.setState({ data: DataModel });
        // this.getScrutinizedDocs();
        this.getFileOverview();
        this.getFormData();
        this.getDeeds();
        // this.getRevenueDocs();
        this.getAllDocuments();
        this.getUpdateReasonData()
        this.getScrutinyReport();
        this.liveinsert();
        this.blockdownload();
        this.getHints();
        this.getqueData();
        this.getVerifiyTargetTime();
        localStorage.setItem("dashboard_status", "2");
        localStorage.setItem("file_Id", this.props.match.params.id);
        // localStorage.setItem("currentfileId", this.props.match.params.id);
        localStorage.setItem("verifyfileId", this.props.match.params.id);
        // $("#balance-time").modal("show");
    }
    componentWillUnmount() {
        if (this.countdownTimerRef) {
            this.countdownTimerRef.clearInterval();
        }
    }

    getVerifiyTargetTime = () => {
        const verification_officer: any = localStorage.getItem("verification_office_id");
        const userid1: any = localStorage.getItem("user1");
        const userid = JSON.parse(userid1);
        callApi("POST", "get_verify_target_time.php", { file_id: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                if (response.statusCode == 200) {

                    if (verification_officer == userid) {
                        if (response.data[0].target_min == 0) {
                            $("#balance-time").modal("show");
                        } else {
                            let initialTimeInMinutes: any = localStorage.getItem('updatedRemainingTime');
                            if (initialTimeInMinutes > 0) {
                                this.setState(
                                    (prevState: any) => ({
                                        initialTimeInMinutes: initialTimeInMinutes
                                    }), () => { }
                                );
                            } else if (response.data) {
                                this.setState(
                                    {
                                        initialTimeInMinutes: response.data[0].target_min
                                    }, () => {
                                        console.log("response.data[0].target_min", response.data[0].target_min);

                                    }
                                );
                            } else {
                                this.setState(
                                    {
                                        initialTimeInMinutes: 0
                                    }, () => { }
                                );
                            }
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    getqueData = () => {
        callApi("POST", "get_flowchart.php", {
            fileId: this.props.match.params.id
        })
            .then((res) => res.data)
            .then((response) => {
                if (response.data && response.data) {
                    let manualQuestionList = response.data.manualQuestion;
                    if (manualQuestionList) {
                        this.setState({ manualQuestionsList: JSON.parse(manualQuestionList) });
                    }
                    console.log("getqueData------------->", manualQuestionList);
                }

            })
            .catch((err) => {
                console.log(err);
            });

    }

    getHints = () => {
        callApi("POST", "hint_get.php", { id: this.props.match.params.id })
            .then(res => res.data)
            .then(result => {
                this.setState({ hints: result.data })
            })
            .catch(err => {
                console.log(err);
            });
    }


    blockdownload = () => {
        callApi("POST", "block_download_status.php", {
            file_idfk: this.props.match.params.id
        })
            .then(res => res.data)
            .then(response => {
                this.setState({ blockdownload: response['responseJson'].data[0].block_download_status })
                console.log('blockdownload___>>>', this.state.blockdownload);
            })
            .catch(err => {
                console.log(err);
            });
    };

    getUpdateReasonData = () => {
        const reasonData: any = this.state.updateDownloadData;
        callApi('POST', 'dashboard_log.php', { id: this.props.match.params.id })
            .then(res => res.data)
            .then(result => {
                let { lastUploadedDocument } = this.state;
                reasonData.reasonForDownload = result.data.reasonForDownload;
                reasonData.updatedDetails = result.data.updatedDetails
                if (reasonData.updatedDetails && reasonData.updatedDetails.length > 0) {
                    lastUploadedDocument = reasonData.updatedDetails[0].url[0];
                }
                this.setState({ updateDownloadData: reasonData, lastUploadedDocument });
            })
            .catch(err => {
                console.log("Errororor", err)
                // ToastsStore.error("Failed to get ");
            });
    }

    getAllDocuments = () => {
        callApi("POST", "all_doc_no_list.php", { id: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                const documentList: any = response.data;
                if (documentList.length > 0) {
                    const documentValue = response.data[0] ? response.data[0]["base_id"] : null;
                    if (documentValue) {
                        this.setState({ selectedDoc: documentValue }, () => {
                            this.viewDocument(documentValue);
                        })
                    }
                }
                this.setState({ allDocuments: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };

    viewDocument = (id) => {
        this.setState({ isHightlightsLoaded: false });
        callApi("POST", "getbaseby_id.php", { id })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    this.getScrutinizedDocs(id);
                    const document = this.document;
                    document.base = response.data[0].base[0].base;
                    this.setState({ ...document, selectedDoc: id, base: response.data[0].base[0].base }, () => this.setState({ isHightlightsLoaded: true }));
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    getTemplates = deed => {
        callApi("POST", "template_list_bydeed.php", { deed })
            .then(res => res.data)
            .then(response => {
                this.setState({ templates: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };
    // function name(params:type) {

    // }
    getDynamicFields = id => {
        callApi("POST", "input_master_get.php", { id })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    response.data.map(dynamic => {
                        if (dynamic.type === 'select') {
                            const patchDynamicField: any = [];
                            const fieldSelected = !isEmpty(this.state[dynamic.name]) ? this.state[dynamic.name].split(",") : [];
                            fieldSelected.map(val => {
                                const selField: any = dynamic.optionList.find((all: any) => all.name === val)
                                patchDynamicField.push(selField);
                            });
                            this.setState({ [dynamic.name]: patchDynamicField, modifySelectToCommas: [dynamic.name] })
                        }
                    })
                    this.setState({ dynamicFields: response.data });
                } else {
                    this.setState({ dynamicFields: [] });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    manageStaticFields = id => {
        callApi("POST", "dynamic_form.php", { id })
            .then(res => res.data)
            .then(response => {
                this.setState({ staticFilesToHide: response.data });
            })
            .catch(err => {
                console.log(err);
            });
    };

    getScrutinizedDocs = (id) => {
        callApi("POST", "doc_scrutinize_get.php", {
            id: id
        })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    const state = response.data[0].scrutinize[0];
                    // state.base = response.data[0].base[0].base;
                    if (state.nature_doc) {
                        this.getTemplates(state.nature_doc);
                    }
                    state.main_favour = response.data[0].favour;
                    state.main_executant = response.data[0].executant;
                    state.highlights = response.data[0].highlights || [];
                    state.sheetCount = response.data[0].sheetCount || "";
                    if (!state.main_favour || !state.main_favour.length) {
                        state.main_favour = [];
                        state.main_favour.push({ favour: "" });
                    }
                    if (!state.main_executant || !state.main_executant.length) {
                        state.main_executant = [];
                        state.main_executant.push({ executant: "" });
                    }
                    if (state.template) {
                        this.getDynamicFields(state.template);
                        this.manageStaticFields(state.template)
                    }
                    const deedSelected = state.deed ? state.deed.split(",") : [];
                    state.deed = [];
                    deedSelected.forEach(deed => {
                        const selDeed: any = this.state.deeds.find((all: any) => all.id === deed)
                        state.deed.push(selDeed);
                    });
                    this.setState({ ...state, isHightlightsLoaded: true });
                }
                this.getDeeds();
            })
            .catch(err => {
                console.log(err);
            });
    };
    getDeeds = () => {
        callApi("POST", "deed_master_get.php", { category: this.state.type })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    const deeds = response.data;
                    this.setState({ deeds });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    getRevenueDocs = () => {
        callApi("POST", "getbaseby_id.php", { id: this.props.match.params.id })
            .then(res => res.data)
            .then(response => {
                if (response.data && response.data.length) {
                    let document = this.document;
                    document.base = response.data[0].base[0].base;
                    var totalDocs = document.base;
                    this.setState({ ...document, type: response.data[0].base[0].type, selectedDoc: "", base: totalDocs });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    onHighlightsChange = (highlights) => {
        this.setState({ highlights })
    }


    rotate = () => {
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({ rotation: newRotation })
    }

    getFileOverview = () => {
        const props: any = this.props;
        callApi("POST", "final_report_get.php", {
            file_idfk: props.match.params.id,
            check: true
        })
            .then(res => res.data)
            .then(response => {
                if (response && response.data && response.data.length) {
                    if (response.data[0].rep_id) {
                        // const state = response.data[0];
                        // this.setState({ ...state });
                    } else {
                        this.getScrutinyReport();
                    }
                    this.setState({ isapproved: response.data[0].isapproved });
                }
            });
    };

    getScrutinyReport = () => {
        const props: any = this.props;
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        callApi("POST", "template/tests/scrutiny_report.php", {
            file_idfk: props.match.params.id,
            check: true, userId
        })
            .then(res => res.data)
            .then(response => {
                if (response) {
                    const lastScrutinyDocument = API_ENDPOINT + "template/tests/output/" + response.file_name + ".pdf";
                    this.setState({ report: "" });
                    this.setState({ report: response.data, lastScrutinyDocument });
                }
            });
    }

    getFormData = () => {
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const fileId = this.props.match.params.id
        callApi("POST", "get_verification_checklist.php", {
            fileNo: fileId,
        })
            .then(res => res.data)
            .then(response => {
                if (response.responseJson.data.currentJson) {
                    this.setState({
                        data: JSON.parse(response.responseJson.data.currentJson),
                        verificationEOD: response.responseJson.data.eod_submit == true ? true : false
                    })
                } else {
                    this.setState({ data: DataModel })
                    this.getVerificationListData();
                }
            })
            .catch(err => {
                this.setState({ data: DataModel })
                this.getVerificationListData();
            });
    }

    getVerificationListData = () => {
        const fileId = this.props.match.params.id
        callApi("POST", "get_verification.php", {
            fileNo: fileId,
        })
            .then(res => res.data)
            .then(response => {
                if (response && response.data) {
                    let modifiedData = { ...this.state.data };
                    Object.entries(response.data).forEach((data: any) => {
                        if (data[0] && data[1]) {
                            modifiedData = {
                                ...modifiedData,
                                [data[0]]: {
                                    ...modifiedData[data[0]],
                                    ...data[1]
                                }
                            }
                        }
                    })
                    this.setState({
                        data: modifiedData
                    })
                }
                else {
                    this.setState({ data: DataModel });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }


    handleGeneralVerifyChange = (modData) => {
        const { data } = this.state;
        data.generalVerification = modData;
        this.setState({ data });
    }
    handleSurveyNoVerifyChange = (modData) => {
        const { data } = this.state;
        data.surveyNoVerification = modData;
        this.setState({ data });
    }
    handleDeedChange = (modData) => {
        const { data } = this.state;
        data.deed = modData;
        this.setState({ data });
    }
    handleLegalHerisChange = (modData) => {
        const { data } = this.state;
        data.legalHeirsVerify = modData;
        this.setState({ data });
    }
    handleEcVerifyChange = (modData) => {
        const { data } = this.state;
        data.ecVerify = modData;
        this.setState({ data });
    }
    handleLodChange = (modData) => {
        const { data } = this.state;
        data.missingLOD = modData;
        this.setState({ data });
    }
    handleVerifyChange = (modData) => {
        const { data } = this.state;
        data.verification = modData;
        this.setState({ data });
    }
    handleCourtVerifyChange = (modData) => {
        const { data } = this.state;
        data.courtDocVerification = modData;
        this.setState({ data });
    }
    handleChange = (e) => {
        const { data } = this.state;
        data.isVerified = e.target.checked;
        this.setState({ data });
    }
    actionHandle = (action) => {
        this.setState({ action });
    }
    uploadFile = (file: any) => {
        this.setState({ uploadFile: file });
    }
    handleReasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }
    setVerify = (verify) => {
        this.setState({ verification: verify });
        // this.setState({ isComplete: true });

    }
    validateReason = () => {
        if (this.state.reason === "") {
            ToastsStore.error("Please Enter Reason")
        } else {
            if (this.state.action === "download") {
                this.downloadDoc();
            } else {
                $("#reason-modal").modal("hide");
                this.uploadDoc();
                // $("#verification-modal").modal("show");
            }
        }
    }
    downloadDoc = () => {
        $("#reason-modal").modal("hide");
        const props: any = this.props;
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const obj = {
            fileid: props.match.params.id,
            reason: this.state.reason,
            createdBy: userId
        }
        callApi("POST", "download_log.php", obj)
            .then(res => res)
            .then(response => {
                if (response.status === 200) {
                    // ToastsStore.success("work file Q & A are saved succesfully")
                    ToastsStore.success("Reason saved successfully");
                    this.downloadDocs();
                    this.clearModal();
                } else {
                    ToastsStore.error("Error in storing, Please try again!")
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    //Download docs from external api
    downloadDocs = () => {
        const { lastUploadedDocument } = this.state;
        if (lastUploadedDocument.docx) {
            callApi("POST", "get_branch_code.php", {
                id: this.props.match.params.id
            }).then(res => res.data).then(response => {
                const name = `${response.data[0].lawhands_branch}-${this.props.match.params.id}.docx`;
                saveAs(lastUploadedDocument.docx, name)
            })
        }
        else {
            const props: any = this.props;
            // ToastsStore.success("Downloaded Successful!");
            callApi("POST", "template/tests/shell.php", {
                id: props.match.params.id,
                responseType: 'blob'
            })
                .then(res => res.data)
                .then(response => {
                    //ToastsStore.success("Downloaded Successfully!");
                    window.open(API_ENDPOINT + "template/tests/shell.php?id=" + props.match.params.id, "_self")
                })
        }
    }

    enableModalHandler = () => {
        // $("#complete-process").modal("show");
        if (this.state.blockdownload == "true") {
            this.completeModalHandler();
        } else {
            $("#complete-process").modal("show");
        }
    }

    verificationtime() {
        const props: any = this.props;
        const loginuser: any = localStorage.getItem("user1");
        var now = new Date();
        var date = now.toLocaleDateString();
        var time = now.toLocaleTimeString();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const currenttime = `${hours}:${minutes}:${seconds}`;
        const currentdate = moment(date).format("YYYY-MM-DD");
        const obj = {
            id: props.match.params.id,
            verification_completed_time: currenttime,
            verification_completed_date: currentdate,
            verification_completed_by: JSON.parse(loginuser),
            reason: "Verification Completed",
            created_by: JSON.parse(loginuser)
        };
        callApi("POST", "time_update.php", obj)
            .then(res => res.data)
            .then(response => {

            });
    }

    completeModalHandler = () => {
        if (this.state.verificationEOD == true && this.state.data.isVerified == true) {
            const props: any = this.props;
            this.setState({ showTitle: true })
            callApi("POST", "file_status.php", {
                id: props.match.params.id,
                file_status: 5,
            })
                .then(res => res)
                .then(response => {
                    this.verificationtime();
                    this.setState({ showTitle: false })

                    if (response.status === 200) {
                        this.setState({ showTitle: false })
                        $("#processComplete").modal("show")
                        const user: any = localStorage.getItem("user");
                        const userId = user ? JSON.parse(user)["userId"] : "";
                        const userName = user ? JSON.parse(user)["firstName"] : "";
                        const notifyData = {
                            title: `${this.props.match.params.id}Verification Completed By ${userName}`, notifyType: "message", updatedAt: moment().format("YYYY-MM-DD HH:mm:ss")
                        };
                        let user_id = userId;
                        pushnotificationApi(notifyData, userId);
                        const branchManger: any = localStorage.getItem("branch_manager");
                        const branch_Manager = JSON.parse(branchManger);
                        const queryOfficer: any = localStorage.getItem("Query_officer");
                        const query_ooficer = JSON.parse(queryOfficer);
                        const Scan_officer: any = localStorage.getItem("scan_officer");
                        const sacanofficer = JSON.parse(Scan_officer);
                        const process_mennager: any = localStorage.getItem("process_manager");
                        const processManager = JSON.parse(process_mennager);
                        if (Array.isArray(branch_Manager) && branch_Manager.length > 0) {
                            notifyToUser("user", { ...notifyData, notifyType: "message" }, branch_Manager);
                            const BranchManager = branch_Manager.join(",");
                            let obj = {
                                notification_type: "verificationcomplete",
                                user_id: BranchManager,
                                ...notifyData,
                                reason: `${this.props.match.params.id}Verification Completed`
                            }
                            callApi("POST", "pushnotification.php", obj)
                                .then((res) => res.data)
                                .then((response) => {
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                        //   if (Array.isArray(query_ooficer) && query_ooficer.length > 0) {
                        //     notifyToUser("user", { ...notifyData, notifyType: "notify" }, query_ooficer);
                        //   }
                        // if (Array.isArray(sacanofficer) && sacanofficer.length > 0) {
                        //   notifyToUser("user", { ...notifyData, notifyType: "message" }, sacanofficer);
                        // }
                        if (Array.isArray(processManager) && processManager.length > 0) {
                            notifyToUser("user", { ...notifyData, notifyType: "message" }, processManager);
                            const ProcessManager = processManager.join(",");
                            let obj = {
                                user_id: ProcessManager,
                                ...notifyData,
                                reason: `${this.props.match.params.id}Verification Completed`
                            }
                            callApi("POST", "pushnotification.php", obj)
                                .then((res) => res.data)
                                .then((response) => {
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                        // this.props.history.push("/work-file-verification-list/"+ props.match.params.id);

                    } else {
                        ToastsStore.error("Error in completing the file , Please try again!");
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            ToastsStore.error("Please fill the Verification EOD to proceed");
        }
    }
    verifyModalHandler = () => { $("#verification-modal").modal("show"); }
    close = () => {
        $("#processComplete").modal("hide");
        // this.props.history.push("/completed-file");
        this.props.history.push("/inprogress-file");
        localStorage.removeItem('updatedRemainingTime');
    }

    uploadDoc = () => {

        const props: any = this.props;
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        const obj: any = { ...this.state.verification };
        obj.fileId = props.match.params.id;
        obj.reason = this.state.reason;
        obj.file = this.state.uploadFile.base64.split(",")[1];
        obj.createdBy = userId;
        const errorTypes: any = [];
        obj.errorTypes.forEach((type: any) => {
            errorTypes.push({ type });
        });
        obj.errorTypes = errorTypes.length > 0 ? errorTypes : ['1', '2'];
        obj.errorSection = ['1', '2'];
        callApi("POST", "template/tests/upload_log.php", obj)
            .then(res => res)
            .then(response => {
                if (response.status == 200) {
                    this.setState({ isComplete: false });
                    this.getUpdateReasonData();
                    ToastsStore.success("Reason saved successfully");
                    this.clearModal();
                } else {
                    ToastsStore.error("Error in storing, Please try again!");
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    liveinsert = () => {

        const user: any = localStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["team_type"] : "";
        const userId = user ? JSON.parse(user)["userId"] : "";
        const role = user ? JSON.parse(user)["teamType"] : "";
        const role_type = user ? JSON.parse(user)["roleType"] : "";
        const user_name = user ? JSON.parse(user)["userName"] : "";
        const bankName: any = localStorage.getItem("bankName");
        const fileNamCode: any = localStorage.getItem("fileNamCode");
        const in_reason: any = localStorage.getItem("in_reason");
        let dateTime = new Date();
        let curentTime = dateTime.toLocaleTimeString();
        const textRay = `Currently Working On Verification File No  : ${this.props.match.params.id}`;
        let obj = {
            file_no: fileNamCode,
            in_time: curentTime,
            reason: in_reason,
            user_id: userId,
            user_name: user_name,
            team_type: role,
            role_type: role_type,
            working_process: textRay,
            bank_name: bankName,
        }
        console.log("231", obj);
        callApi("POST", "live_track_insert.php", obj)
            .then(res => res.data)
            .then(response => {

            })
    }

    clearModal = () => {
        // const state ={
        //     isComplete:true
        // }
        const verification = {
            highlight: false,
            dataEntry: false,
            flowChart: false,
            hintFile: false,
            errorIdentified: false,
            noOflod: "",
            errorSection: [],
            otherSection: "",
            errorTypes: [],
            errorDetails: "",
            totalTime: "",
            userId: '',
            fileId: '',
        }
        this.setState({
            reason: "",
            verification
        });
    }
    // completeEnable = () =>{
    //     // const {isComplete} 
    //     this.setState({isComplete: true});
    // }
    verificationEODHandler = () => {
        $("#verification-modal").modal("hide");
        const { verification } = this.state;
        // this.setState({isComplete : true});
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        verification.userId = userId;
        verification.fileId = this.props.match.params.id;

        console.log("verification", verification)
        callApi("POST", "verification_eod.php", {
            fileNo: this.props.match.params.id,
            eodData: JSON.stringify(verification),
            user_id: userId
        })
            .then(res => res)
            .then((response: any) => {
                if (response.status === 200) {
                    this.setState({ verificationEOD: true });
                    ToastsStore.success("verification saved successfully");
                    // this.completeFlowHandler()
                    this.props.history.push("/work-file-verification-list/" + this.props.match.params.id);
                } else if (response.status === 201) {
                    this.setState({ verificationEOD: true });
                    ToastsStore.error(response.message);
                } else { }
            })
            .catch(err => {
                console.log(err);
                this.setState({ verificationEOD: false });
            });
    }
    saveVerification = () => {
        const { data } = this.state;
        const props: any = this.props;
        const user: any = localStorage.getItem("user");
        const userId = user ? JSON.parse(user)["userId"] : "";
        if (!data.remarks) {
            ToastsStore.error("Enter Remarks!");
        }
        else {
            const obj = {
                fileNo: props.match.params.id,
                currentData: data,
                oldData: data,
                loginId: userId
            }
            callApi("POST", "verification_checklist.php", obj)
                .then(res => res.data)
                .then(response => {
                    ToastsStore.success("verification checklist saved");
                });
        }
    }
    updatedRemainingTime(time) {
        this.setState(
            (prevState: any) => ({
                updatedRemainingTime: time
            }),
            () => {
            }
        );
    }
    render() {
        const user: any = localStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const { flowGraphData } = this.state;
        const { data, report, action, uploadFile, reason, verification, rotation,
            velocityEqualToMove, enableVelocity, highlights, isHightlightsLoaded, base, lastUploadedDocument, lastScrutinyDocument, isComplete, showTitle, docToPreview } = this.state;
        const iframeSource = lastUploadedDocument.pdf ? lastUploadedDocument.pdf : lastScrutinyDocument ? lastScrutinyDocument : "";
        const { isQuestionsExpanded,
            autoQuestionsList,
            manualQuestionsList,
            activeTab,
            currentAutoColumnNumber,
            currentManualColumnNumber, } = this.state;
        let currentQuestionsList =
            activeTab === "AutomaticView" ? autoQuestionsList : manualQuestionsList;
        let currentColumnNumber =
            activeTab === "AutomaticView"
                ? currentAutoColumnNumber
                : currentManualColumnNumber;
        const verification_officer: any = localStorage.getItem("verification_office_id");
        const userid1: any = localStorage.getItem("user1");
        const userid = JSON.parse(userid1);
        return (
            <div className="pcoded-content sop">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-md-12 mt-2">
                                                {verification_officer == userid && this.state.initialTimeInMinutes && this.state.initialTimeInMinutes > 0 && (
                                                    <CountdownTimer
                                                        initialTime={this.state.initialTimeInMinutes}
                                                        updatedTime={(time) => this.updatedRemainingTime(time)}
                                                        ref={(ref) => (this.countdownTimerRef = ref)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="card-header row">
                                            <div className='col-md-5 row'>
                                                <h5 className='font-weight-bold text-uppercase' style={{ alignSelf: 'center' }}>Work File Verification list</h5>
                                                <div style={{ width: '50%' }}>
                                                    {this.state.allDocuments && this.state.allDocuments.length > 0 && <div style={{ minWidth: "40%" }}>
                                                        {this.state.allDocuments && this.state.allDocuments.length > 0 && this.state.allDocuments.map((opt: any) => {
                                                            opt.label = 'Document - ' + opt.id + (opt.status == 1 ? "- (Not Related)" : "");
                                                            opt.value = opt.base_id
                                                        }) &&
                                                            <Select options={this.state.allDocuments} name="selectedDoc"
                                                                value={this.state.allDocuments.find((o: any) => o.value === this.state.selectedDoc)}
                                                                onChange={(e) => this.viewDocument(e.value)}
                                                            />
                                                        }
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                {/* {this.state.blockdownload == "false" ? (<> */}
                                                <button className="btn btn-outline-dark"
                                                    onClick={() => this.actionHandle("download")}
                                                    data-toggle="modal" data-target="#reason-modal">
                                                    Download Doc
                                                </button>
                                                <button className="btn btn-outline-dark"
                                                    onClick={() => this.actionHandle("upload")}
                                                    data-toggle="modal" data-target="#reason-modal">
                                                    Upload Doc
                                                </button>
                                                {/* </>) :
                                                    (<> */}
                                                {/* {(role_id === ADMIN_ROLE) ? (
                                                            <>
                                                                <button className="btn btn-outline-dark"
                                                                    onClick={() => this.actionHandle("download")}
                                                                    data-toggle="modal" data-target="#reason-modal">
                                                                    Download Doc
                                                                </button>
                                                                <button className="btn btn-outline-dark"
                                                                    onClick={() => this.actionHandle("upload")}
                                                                    data-toggle="modal" data-target="#reason-modal">
                                                                    Upload Doc
                                                                </button>
                                                            </>
                                                        ) : ""
                                                        } */}
                                                {/* </>)} */}

                                                {isComplete && (<button type="button" className="btn btn-primary"
                                                    style={{ marginTop: '0px' }} onClick={this.enableModalHandler}>Complete</button>)}

                                                {!isComplete && (<><button onClick={this.completeModalHandler} type="button" className="btn btn-primary"
                                                    style={{ marginTop: '0px' }}>Complete</button></>
                                                )}
                                                <button className="btn btn-outline-dark float-right" onClick={() => this.props.history.goBack()}>
                                                    Back
                                                </button>
                                                <button className="btn btn-outline-dark float-right" onClick={() => this.setState({ isflowGraphDataupdated: true })}
                                                    data-toggle="modal"
                                                    data-target="#open-graphData1-modal">
                                                    Flow Chart
                                                </button>
                                                <button
                                                    className="btn btn-outline-dark float-right"
                                                    data-toggle="modal"
                                                    data-target="#hint-modal">
                                                    Hints
                                                </button>
                                                <button
                                                    className="btn btn-outline-dark float-right"
                                                    onClick={() =>
                                                        this.props.history.push("/work-file-details/" + this.props.match.params.id)
                                                    }
                                                >
                                                    Check
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="container">
                                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" id="old-view-tab" data-toggle="tab"
                                                                        href="#old-viewer" role="tab" aria-controls="home" aria-selected="true">
                                                                        Old Viewer
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="new-view-tab" data-toggle="tab"
                                                                        href="#new-viewer" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.setState({ docToPreview: true })}>
                                                                        New Viewer
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="new-view-tab" data-toggle="tab"
                                                                        href="#upload-view1" role="tab" aria-controls="profile" aria-selected="false">
                                                                        Last Uploaded TIR
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                            <div className="tab-content" id="tab-content">
                                                                <div className="tab-pane fade show active" id="old-viewer" role="tabpanel" aria-labelledby="old-viewer">
                                                                    <iframe
                                                                        src={this.document.base}
                                                                        width='100%'
                                                                        height={this.state.documentHeight}
                                                                    />
                                                                </div>
                                                                <div className="tab-pane fade" id="new-viewer" role="tabpanel" aria-labelledby="new-viewer">
                                                                    <p style={{ color: 'red' }}>NOTE: TO HIGHLIGHT [ ALT + RIGHT CLICK ON MOUSE AND DRAG ]</p>
                                                                    <br />
                                                                    <div className="App" style={{ display: 'flex', height: 'calc(90vh - 110px)' }}>
                                                                        <TransformWrapper
                                                                            pan={{
                                                                                disabled: true,
                                                                                velocityEqualToMove,
                                                                                velocity: enableVelocity
                                                                            }}
                                                                            wheel={{
                                                                                disabled: true
                                                                            }}
                                                                        >
                                                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                                                <React.Fragment>
                                                                                    <div className="tools">
                                                                                        <Rotate90DegreesCcwIcon className="pdf-icon rotate-icon" onClick={this.rotate} />
                                                                                        <ZoomInIcon className="pdf-icon" onClick={zoomIn} />
                                                                                        <ZoomOutIcon className="pdf-icon" onClick={zoomOut} />
                                                                                        <FullscreenExitIcon className="pdf-icon" onClick={resetTransform} />
                                                                                        {base && isHightlightsLoaded && <FullscreenIcon className="pdf-icon" onClick={() => this.setState({ docToPreview: true })} />}
                                                                                    </div>
                                                                                    <TransformComponent>
                                                                                        <div
                                                                                            style={{
                                                                                                height: 'calc(90vh - 110px)',
                                                                                                width: '45vw',
                                                                                                position: 'relative',
                                                                                                transform: `rotate(${rotation}deg)`
                                                                                            }}
                                                                                        >
                                                                                            {base && !docToPreview && <PdfHighlighter url={base} onHighlightsChange={this.onHighlightsChange} highlights={highlights} />}
                                                                                        </div>
                                                                                    </TransformComponent>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </TransformWrapper>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade" id="upload-view1" role="tabpanel" aria-labelledby="upload-view1">
                                                                    {iframeSource && <iframe
                                                                        src={iframeSource}
                                                                        width='100%'
                                                                        height={this.state.documentHeight}
                                                                    />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <div className='container'>
                                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" id="old-view-tab" data-toggle="tab"
                                                                    href="#verify-view" role="tab" aria-controls="home" aria-selected="true">
                                                                    Checklist
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="new-view-tab" data-toggle="tab"
                                                                    href="#upload-view" role="tab" aria-controls="profile" aria-selected="false">
                                                                    Last Uploaded TIR
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content" id="tab-content" style={{ padding: 0 }}>
                                                            <div className="tab-pane fade show active" id="verify-view" role="tabpanel" aria-labelledby="verify-view">
                                                                <div
                                                                    style={{
                                                                        border: '1px solid #dee2e6',
                                                                        borderRadius: '8px',
                                                                        height: '675px'
                                                                    }}
                                                                >
                                                                    <div className="container verify-container" style={{ height: '675px', width: "100%", }}>
                                                                        <div style={{ overflow: 'auto', height: '550px' }} >
                                                                            <GeneralVerification
                                                                                data={data.generalVerification}
                                                                                handleGeneralVerifyChange={this.handleGeneralVerifyChange}
                                                                            />
                                                                            <SurveyNoVerification
                                                                                data={data.surveyNoVerification}
                                                                                handleSurveyNoVerify={this.handleSurveyNoVerifyChange}
                                                                            />
                                                                            <Deed
                                                                                data={data.deed}
                                                                                handleDeedChange={this.handleDeedChange}
                                                                            />
                                                                            <LegalHerisVerification
                                                                                data={data.legalHeirsVerify}
                                                                                handleLegalHerisChange={this.handleLegalHerisChange}
                                                                            />
                                                                            <ECVerification
                                                                                data={data.ecVerify}
                                                                                handleEcVerifyChange={this.handleEcVerifyChange}
                                                                            />
                                                                            <MissingLOD
                                                                                data={data.missingLOD}
                                                                                handleLodChange={this.handleLodChange}
                                                                            />
                                                                            <Verification
                                                                                data={data.verification}
                                                                                handleVerifyChange={this.handleVerifyChange}
                                                                            />
                                                                            <CourtDocVerify
                                                                                data={data.courtDocVerification}
                                                                                handleCourtVerifyChange={this.handleCourtVerifyChange}
                                                                            />
                                                                            <div className="collapse-section">
                                                                                <Card>
                                                                                    <Card.Header>
                                                                                        <h5>
                                                                                            <b>* Remarks (if Any)/Any Error Found?</b>
                                                                                        </h5>
                                                                                    </Card.Header>
                                                                                    <Card.Body>
                                                                                        <div className="row">
                                                                                            <div className="form-group col-md-12">
                                                                                                <label className="label" style={{ padding: 0 }}>*Have you found any error/ is there any remark for the file?</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            {/* <div className='col-md-3'>
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Nill"
                                                                                    name="spellcorrect"
                                                                                    type="radio"
                                                                                />
                                                                            </div> */}
                                                                                            {/* <div className='form-group col-md-1'>
                                                                                    <Form.Check
                                                                                        inline
                                                                                        name="spellcorrect"
                                                                                        type="radio"
                                                                                    />
                                                                                </div> */}
                                                                                            <div className='form-group col-md-12'>
                                                                                                <textarea
                                                                                                    placeholder='Enter Remarks'
                                                                                                    className="form-control"
                                                                                                    name="remarks"
                                                                                                    value={data.remarks}
                                                                                                    onChange={(e) => {
                                                                                                        this.setState({
                                                                                                            data: {
                                                                                                                ...data,
                                                                                                                [e.target.name]: e.target.value
                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                    ref={this.remarksRef}
                                                                                                />
                                                                                                {/* <input
                                                                                                        className="form-control"
                                                                                                        name="remarks"
                                                                                                        ref={this.remarksRef}
                                                                                                        type="text"
                                                                                                        placeholder='Enter Remarks'
                                                                                                        value={data.remarks}
                                                                                                        onChange={(e) => {
                                                                                                            this.setState({
                                                                                                                data: {
                                                                                                                    ...data,
                                                                                                                    [e.target.name]: e.target.value
                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            marginTop: '32px'
                                                                        }}>
                                                                            <Form.Check
                                                                                inline
                                                                                label="I've Double-Checked Everything All of The Data Is Correct And Reliable"
                                                                                name="isVerified"
                                                                                type="checkbox"
                                                                                checked={data.isVerified}
                                                                                onChange={this.handleChange}
                                                                            />
                                                                            <div>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-success"
                                                                                    onClick={this.saveVerification}
                                                                                >
                                                                                    Save
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    disabled={!data.isVerified}
                                                                                    onClick={this.verifyModalHandler}
                                                                                >
                                                                                    Verification EOD
                                                                                </button>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="upload-view" role="tabpanel" aria-labelledby="upload-view">
                                                                {iframeSource && <iframe
                                                                    src={iframeSource}
                                                                    width='100%'
                                                                    // height={this.state.documentHeight}
                                                                    height='672px'
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <VerificationEOD
                            verify={verification}
                            setVerify={this.setVerify}
                            saveUploadData={this.verificationEODHandler}
                        // completeEnable={this.completeEnable}
                        />
                        <div
                            className={`modal ${this.state.docToPreview ? 'open' : ''}`}
                            id="preview-document"
                            role="dialog"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-xl" role="document" style={{ maxWidth: '90%' }}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            className="close cursor-pointer"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => this.setState({ docToPreview: false })}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" style={{ transform: `rotate(${this.state.rotation}deg)` }}>
                                        {this.state.base && this.state.isHightlightsLoaded &&
                                            <PdfHighlighter url={this.state.base} onHighlightsChange={this.onHighlightsChange} highlights={this.state.highlights} rotation={this.state.rotation} isPopup />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal" id="reason-modal">
                            <div className="modal-dialog ">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        {action === "download" ?
                                            <h5 className="modal-title">Reason For Download</h5>
                                            :
                                            <h5 className="modal-title">Reason For Upload</h5>
                                        }
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            {action === "download" ?
                                                <label>Enter Reason*</label>
                                                :
                                                <React.Fragment>
                                                    <label>Enter Updated Informations*</label>
                                                    <ReactFileReader
                                                        fileTypes={[".doc", ".docx"]}
                                                        base64={true}
                                                        // multipleFiles={true}
                                                        handleFiles={e => this.uploadFile(e)}
                                                    >
                                                        <button
                                                            className="ml2 btn btn-success btnupload"
                                                        // disabled={this.state.isFlowChartUploading}
                                                        >
                                                            {/* {this.state.isFlowChartUploading ? (
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            ) : <i className="icon feather icon-upload" />} */}

                                                            Select File
                                                        </button>
                                                    </ReactFileReader>
                                                    <span>{uploadFile.fileList[0].name}</span>
                                                </React.Fragment>
                                            }
                                            <textarea className="form-control mt-10" name="reason"
                                                value={reason}
                                                onChange={this.handleReasonChange}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn "
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        {action === "download" ?
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={this.validateReason}
                                            >
                                                Download
                                            </button>
                                            :
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={this.validateReason}
                                            >
                                                Upload
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal hide fade in" id="processComplete" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog ">
                                <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                                    <div className="modal-body">
                                        <h5 style={{ textAlign: 'center' }}> Thanks For Complete Your Verification File:{this.props.match.params.id}</h5>
                                    </div>
                                    <div className="modal-footer">
                                        {showTitle ? null : (<button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => this.close()}
                                            // onClick={this.disableModalHandler}
                                            style={{ width: '50%', fontSize: '17px', margin: 'auto' }}
                                        > Ok</button>)}

                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="modal" id="complete-process">
                            <div className="modal-dialog ">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <h3>Without upload document you can't complete the file</h3>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-dismiss="modal"
                                        // onClick={this.disableModalHandler}
                                        >
                                            {" "}
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal" id="hint-modal">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4>Hint About Files</h4>
                                        <div className="model-xl" style={{ height: '660px', position: 'relative', overflow: 'auto' }}>
                                            <div className='card-body'>
                                                <div className="latest-update-box">
                                                    {this.state.hints && this.state.hints.length > 0 && this.state.hints.map((hint: any, index: number) => (
                                                        <div className={index === 0 ? "row p-t-20 p-b-20" : "row p-b-20"}>
                                                            <div className="col-auto text-right update-meta">
                                                                <i className="bi bi-chevron-double-right"></i>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16" color='blue'>
                                                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                                                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                                                                </svg>
                                                            </div>
                                                            <div className="col p-l-5">
                                                                <h6 className="m-0">{hint.created_by}  <span style={{ fontSize: '12px' }}>{hint.created_date ? "(" + moment(hint.created_date).format('DD/MM/YYYY hh:mm:ss') + ")" : ''}</span></h6>
                                                                <p className="m-b-0" style={{ wordBreak: 'break-word' }}>{hint.hints}
                                                                    {/* <a href="#!" className="text-c-blue"> More</a> */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-dismiss="modal"
                                            // onClick={this.disableModalHandler}
                                            style={{ float: "right" }}
                                        > close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal" id="open-graphData1-modal">
                            <div className="modal-dialog modal-xl ">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <h4>Flow Chart</h4>
                                                <div className="model-xl" style={{ height: '655px', position: 'relative', overflow: "auto" }}>
                                                    <div className="card-body">

                                                        {/* <div id="open-graphData1-modal" ref="manualPaperRef" /> */}
                                                        <><Flowchart
                                                            fileId={this.props.match.params.id}
                                                            isflowGraphDataupdated={this.state.isflowGraphDataupdated}
                                                        />
                                                        </>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-5">
                                                    <h4>Questions</h4>
                                                    <div className="model-xl" style={{ height: '655px', position: 'relative' }}>
                                                        <div className="card-body">

                                                            <div className="row">
                                                                <div className="col-md-5">
                                                                </div>
                                                                <div className="col-md-2">
                                                                </div>
                                                                {/* <div className="col-md-2">
                                                                    <button
                                                                        className="btn btn-sm btn-success"
                                                                        id="hideOnDownload"
                                                                        onClick={() => this.saveFlowChart("questions")}
                                                                    >
                                                                        Save
                                                                    </button>
                                                                </div> */}
                                                            </div>

                                                            <div style={{ marginBottom: "3%" }}>
                                                                {this.state.manualQuestionsList.map((question: any, questIndex) => {
                                                                    return (
                                                                        <div
                                                                            key={questIndex}
                                                                            className="row"
                                                                            style={{
                                                                                alignItems: "center",
                                                                                textAlign: "center",
                                                                                marginTop: "1.5%",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="col-md-2"
                                                                                style={{ fontWeight: "bold" }}
                                                                            >
                                                                                {question && question.name ? question.name : ""}
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <textarea
                                                                                    name="qustion"
                                                                                    onChange={(e) =>
                                                                                        this.handleQuestion(e.target.value, questIndex)
                                                                                    }
                                                                                    className="form-control"
                                                                                    value={question.value}
                                                                                    placeholder="Question"
                                                                                    style={{ width: "360px" }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-dismiss="modal"
                                            // onClick={this.disableModalHandler}
                                            style={{ float: "right" }}

                                        > close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    handleQuestion(value: string, questIndex: number): void {
        throw new Error('Method not implemented.');
    }
}

export default VerificationList;
