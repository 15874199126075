import * as React from "react";
import { callApi } from "../../utils/api";
import { ADMIN_ROLE } from "../../utils/role";
import ReactFlexyTable from "react-flexy-table";
import "react-flexy-table/dist/index.css";
import {
    ToastsContainer,
    ToastsContainerPosition,
    ToastsStore
} from "react-toasts";
export interface reason {
    id: number;
    reason_id: number;
    name: string;
}
interface AllProps {
    history: any;
}
class ManageQueryReason extends React.Component<AllProps> {
    state = {
        reasons: [],
        reasondetail: {
            id: 0,
            reason: "",
        },
        reasonToBeDeleted: 0,
        isFileRefresh: false
    };
    componentDidMount() {
        this.listreason();
    }
    listreason = () => {
        this.setState({ isFileRefresh: false });
        callApi("GET", "query_reason_get.php")
            .then(res => res.data)
            .then(response => {
                const files = response.data;
                if (files && files.length > 0) {
                    files.forEach((file, index) => {
                        Object.keys(file).forEach(fileKey => {
                            file[fileKey] = !file[fileKey] ? "" : file[fileKey] !== null && typeof file[fileKey] !== 'undefined' ? String(file[fileKey]) : "";
                        });
                        file.index = index + 1;
                    });
                }
                this.setState({ reasons: files, isFileRefresh: true });
                console.log("query reason list:", this.state.reasons);
            })
            .catch(err => {
                console.log(err);
            });
    };


    handleChange = (e: any) => {
        const reasondetail: any = this.state.reasondetail;
        reasondetail[e.target.name] = e.target.value;
        this.setState({ reasondetail });
    };
    addBank = () => {
        this.setState({ reasondetail: { reason: "", id: 0 } });
    };
    editreasondetail = (reasondetail: any) => {
        this.setState({ reasondetail: reasondetail });
    };
    confirmDeletereasondetail = (reasondetail: any) => {
        this.setState({ reasondetail: reasondetail });
    };
    savereason = () => {
        if (this.state.reasondetail.id) {
            this.state.reasondetail.id = Number(this.state.reasondetail.id);
            callApi("POST", "query_reason_update.php", this.state.reasondetail)
                .then(res => res.data)
                .then(response => {
                    if (response.success == true) {
                        ToastsStore.success(response.message);
                        this.listreason();
                    } else {
                        ToastsStore.error(response.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            callApi("POST", "query_reason_create.php", this.state.reasondetail)
                .then(res => res.data)
                .then(response => {
                    if (response.success == true) {
                        ToastsStore.success(response.message);
                        this.listreason();
                    } else {
                        ToastsStore.error(response.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    deletereason = () => {
        callApi("POST", "query_reason_delete.php", { id: this.state.reasondetail.id })
            .then(res => res.data)
            .then(response => {
                if (response.success == true) {
                    ToastsStore.success(response.message);
                    this.listreason();
                } else {
                    ToastsStore.error(response.message);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    render() {
        const user: any = localStorage.getItem("user");
        const role_id = user ? JSON.parse(user)["role_id"] : "";
        const columns = [
            {
                header: 'ID',
                key: 'index',
            },
            // {
            //   header: 'SRO ID',
            //   key: 'id',
            // },
            {
                header: 'Reason',
                key: 'reason',
            },
            {
                header: "Actions",
                td: (file) => {
                    return <div className="btn-group mb-2 mr-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                        >
                            Action
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span className="sr-only">
                                Toggle Dropdown
                            </span>
                        </button>
                        <div
                            className="dropdown-menu"
                            x-placement="top-start"
                            style={{
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                transform:
                                    "translate3d(88px, -2px, 0px)"
                            }}
                        >
                            <a
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#reason-form"
                                onClick={() =>
                                    this.editreasondetail(file)
                                }
                            >
                                Edit
                            </a>
                            {role_id === ADMIN_ROLE && (
                                <a
                                    className="dropdown-item"
                                    style={{ float: "right" }}
                                    data-toggle="modal"
                                    data-target="#delete-reason"
                                    onClick={() =>
                                        this.confirmDeletereasondetail(file)
                                    }
                                >
                                    Delete
                                </a>
                            )}
                        </div>
                    </div>
                }
            }
        ];
        const data = this.state.reasons;
        return (
            <section className="pcoded-main-container">
                <ToastsContainer
                    store={ToastsStore}
                    position={ToastsContainerPosition.TOP_RIGHT}
                />
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h5>Query Reason Manage</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="dt-responsive table-responsive">
                                                        <a
                                                            className="btn btn-primary m-b-20"
                                                            data-toggle="modal"
                                                            data-target="#reason-form"
                                                            onClick={this.addBank}
                                                        >
                                                            + Add Reason
                                                        </a>

                                                        {this.state.isFileRefresh &&
                                                            <ReactFlexyTable data={data} columns={columns} sortable globalSearch />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="reason-form"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Reason Form</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label className="col-form-label">Reason:</label>{" "}
                                        <input
                                            type="text"
                                            name="reason"
                                            className="form-control"
                                            placeholder="reason"
                                            onChange={e => {
                                                this.handleChange(e);
                                            }}
                                            value={this.state.reasondetail.reason}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.savereason}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="delete-reason"
                    role="dialog"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Disable reason</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h6>Are you sure you want to disable this reason?</h6>
                                <div className="form-group">
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={this.deletereason}
                                >
                                    Disable
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ManageQueryReason;
