import * as React from "react";
import MaskedInput from "react-text-mask";
import { callApi } from "../../utils/api";
import {
  ADMIN_ROLE,
  EXECITUVE_ROLE,
  FRONTOFFICE_ROLE,
  MANAGER_ROLE,
} from "../../utils/role";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import Select from "react-select";
import "./Dashboard.css";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

declare var $: any;

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}
class Dashboard extends React.Component<AllProps> {
  state = {
    lhBranchShow: false,
    bankBranch: false,
    branchName: false,
    lawBranchName: false,
    showBank: false,
    bankName: false,
    outreason: "",
    selectVal: false,
    overview: [],
    employees: [],
    branchInfo: [],
    branches: [],
    fromdate: "",
    eodDate: "",
    eodFromDate: "",
    eodToDate: "",
    todate: "",
    branch: "",
    eodReportTypes: [
      {
        id: 1,
        label: "Branch",
        value: "Branch",
      },
      {
        id: 2,
        label: "Employee",
        value: "Employee",
      },
    ],
    eodData: [],
    branchEodData: [],
    eodReportType: "Branch",
    eodEmployeeList: [],
    eodEmployee: "",
    lawhandsBranches: [],
    bank_private_name: "",
    banks: [],
    filteredBranches: [],
    lawHandsBranch: "",
    isDateSearchClicked: false,
    myteamdetails: true,
    teamDetailsData: [],
    autoAssign: [],
    isLoading: false
  };
  tableDetails: any;
  componentDidMount() {
    // this.getOverview("");
    // this.getEmployees();
    // this.getBranchInfo();
    // this.listBranches();
    // const script = document.createElement("script");
    // script.src = "http://app.elawhands.com/lawapp/assets/form-picker-custom.js";
    // script.async = true;
    // document.body.appendChild(script);
    $(".datepicker").on("change", this.handleChange);
    this.getEodTableData();
    this.getBranchEodData();
    this.getAutoAssignStatus();
    this.getteamdetailsTableData();
    this.getOutTime();
    this.ouTime();
  }
  getteamdetailsTableData = () => {
    const user: any = localStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    let obj = {
      id: userDetails ? userDetails.userId : null,
      // id: 176
    };
    callApi("POST", "team_type.php", obj)
      .then((res) => res.data)
      .then((result) => {
        this.setState({ teamDetailsData: result.data, isDateSearchClicked: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  goToFileDashboard = id => {
    if (id) {
      const url = `/inprogress-file`;
      localStorage.setItem("url", url);
      let obj = { "file_id": id }
      localStorage.setItem("thisPopup", JSON.stringify(obj))
      localStorage.setItem("currentfileId", JSON.stringify(id));
      this.props.history.push("/file-dashboard/" + id);
    } else {
      console.log("no fileId");
    }
  };
  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  previousPop = () => {
    $("#out_reason").modal("show");
    $("#another-reason").modal("hide");
  }
  BacktoFile = () => {
    $("#out_reason").modal("hide");
    const fileid1: any = localStorage.getItem("currentfileId");
    this.props.history.push("/file-dashboard/" + JSON.parse(fileid1));
  }
  getOutTime = () => {
    let date = new Date();
    let stratTime = date.getTime();
    localStorage.setItem("startTime", JSON.stringify(stratTime))
    let outDate = date.toLocaleDateString();
    localStorage.setItem("OutDateGet", JSON.stringify(outDate));
    var Railway = date.getMinutes();
    if (Railway < 10) {
      var TotalMinutes = "0" + Railway;
      let ouTtime = date.getHours() + ":" + TotalMinutes;
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }
    else {
      let ouTtime: any = date.getHours() + ":" + date.getMinutes();
      localStorage.setItem("outtimeGet", JSON.stringify(ouTtime));
    }

  }
  ouTime = () => {
    const getfileId: any = localStorage.getItem("currentfileId");

    if (localStorage.getItem("currentfileId")) {
      $("#out_reason").modal("show");
    }
  }

  outimeGet = () => {
    this.setState({ isLoading: true });
    const fileid1: any = localStorage.getItem("currentfileId");
    const userid1: any = localStorage.getItem("user1");
    const inreason: any = localStorage.getItem("in_reason");
    const intime: any = localStorage.getItem("IntimeGet");
    console.log("70", intime);
    const outtime: any = localStorage.getItem("outtimeGet");
    console.log("73", outtime);
    const outdate: any = localStorage.getItem("OutDateGet");
    const secondsSpent = moment(outtime, "HH:mm").diff(moment(intime, "HH:mm"), 'seconds');
    console.log('Second Spn', secondsSpent);
    let updatedRemainingTime: any = localStorage.getItem('updatedRemainingTime');
    let updatedtime = Math.round(updatedRemainingTime);
    if (this.state.outreason == "") {
      this.setState({ selectVal: true });
    }
    else {
      let obj = {
        "file_id": JSON.parse(fileid1),
        "user_id": JSON.parse(userid1),
        "in_reason": JSON.parse(inreason),
        "out_reason": this.state.outreason,
        "intime": JSON.parse(intime),
        "outtime": JSON.parse(outtime),
        "insert_date": JSON.parse(outdate),
        "total_seconds": secondsSpent,
        "balance_min": updatedtime
      }

      // console.log("72",obj);
      callApi("POST", "process_eod_insert.php", obj)
        .then(res => res.data)
        .then(response => {
          this.setState({ isLoading: false });
          localStorage.removeItem('updatedRemainingTime');
          console.log("76", response);
          // localStorage.removeItem("currentfileId");
          localStorage.removeItem("in_reason");
          localStorage.removeItem("outreason");
          localStorage.removeItem("IntimeGet");
          localStorage.removeItem("OutDateGet");
          $("#out_reason").modal("hide");
          $("#another-reason").modal("hide");
          ToastsStore.success("Your Reason Saved Successfully");
          let process_officerId: any = localStorage.getItem("process_office_id");
          let process_officer_id = JSON.parse(process_officerId);
          const userid1: any = localStorage.getItem("user1");
          let user_id = JSON.parse(userid1);
          let file_id = JSON.parse(fileid1);
          const take_file: any = localStorage.getItem("takenfile_id");
          const user: any = localStorage.getItem("user");
          const query_status: any = localStorage.getItem("query_raised_status");
          const file_completed = localStorage.getItem("file_completed_status");
          if (process_officer_id == user_id && take_file != "" && take_file === fileid1 && query_status == "false" && file_completed == "false") {
            this.timeUpdate(file_id);
          } else {
            localStorage.removeItem('currentfileId');
            localStorage.removeItem("query_raised_status");
            localStorage.removeItem("file_completed_status");
          }
        }).catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
          ToastsStore.error("Your Reason Not Stored");
        })
    }
  }

  timeUpdate = (file_id) => {
    const loginuser: any = localStorage.getItem("user1");
    let req = {
      id: file_id,
      reason: "File Out",
      created_by: JSON.parse(loginuser)
    }
    callApi("POST", "time_update.php", req)
      .then(res => res.data)
      .then(response => {
        localStorage.removeItem("currentfileId");
        localStorage.removeItem("query_raised_status");
        localStorage.removeItem("file_completed_status");
        // localStorage.removeItem("takenfile_id");
      });
  }
  onchangeReason = (e: any) => {

    if (e.target.value == "Other") {
      this.setState({ in_reason: "", selectVal: false })
      $("#out_reason").modal("hide");
      $("#another-reason").modal("show");
    }
    else if (e.target.value == "select") {
      this.setState({ setResponse: false })
    }
    else {
      // this.setState({})
      this.setState({ [e.target.name]: e.target.value, selectVal: false });
    }
  };
  onchange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value.replace(/\s+/g, ' ').trim(), selectVal: false });
  };
  getBanks() {
    callApi("GET", "bank_get.php")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ banks: result.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLawHandsBranches() {
    callApi("GET", "get_branch.php")
      .then((res) => res.data)
      .then((response) => {
        const user: any = localStorage.getItem("user");
        const userDetails = user ? JSON.parse(user) : "";
        let lawhandsBranches = response.data;
        if (
          lawhandsBranches &&
          lawhandsBranches.length > 0 &&
          userDetails.role_id !== "1" &&
          userDetails.my_branchs
        ) {
          const myBranchList = userDetails.my_branchs.split(",");
          lawhandsBranches = lawhandsBranches.filter((value) =>
            myBranchList.includes(value.id)
          );
        }
        this.setState({ lawhandsBranches });
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  getBankBranches() {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ branches: result.data, filteredBranches: [] });
        if (this.state.bank_private_name) {
          this.filterBranches(this.state.bank_private_name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  filterBranches = (bankid) => {
    const filteredBranches = this.state.branches.filter(
      (branch: any) => branch.bank_id === bankid
    );
    const state = this.state;
    state.filteredBranches = filteredBranches;
    this.setState({ ...state });
  };

  getEodTableData = () => {
    const {
      eodFromDate,
      eodToDate,
      lawHandsBranch,
      bank_private_name,
      branch,
    } = this.state;

    let obj: any = {
      branch: lawHandsBranch,
      fromdate: eodFromDate,
      todate: eodToDate,
      bankname: bank_private_name,
      bankbranch: branch,
    };

    localStorage.setItem("report_filter", JSON.stringify(obj));

    const user: any = localStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    obj = {
      ...obj,
      roleId: userDetails ? userDetails.role_id : null,
      userId: userDetails ? userDetails.userId : null,
      roleType: userDetails ? userDetails.roleType : null,
    };
    callApi("POST", "main_report.php", obj)
      .then((res) => res.data)
      .then((result) => {
        this.setState({ eodData: result.data, isDateSearchClicked: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getBranchEodData = () => {
    const user: any = localStorage.getItem("user");
    const userDetails = user ? JSON.parse(user) : "";
    callApi("POST", "main_report_branch.php", {
      branch: userDetails.my_branchs,
      role_id: userDetails.role_id
    })
      .then((res) => res.data)
      .then((result) => {
        console.log("result", result);
        this.setState({ branchEodData: result.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // getOverview(filter) {
  //   const user: any = localStorage.getItem("user");
  //   const userId = user ? JSON.parse(user)["userId"] : "";
  //   const filterBody: any = filter ? { filter: filter, userId } : { userId };
  //   if (this.state.fromdate && this.state.todate) {
  //     filterBody.fromdate = this.state.fromdate;
  //     filterBody.todate = this.state.todate;
  //   }
  //   callApi("POST", "filter.php", filterBody)
  //     .then((res) => res.data)
  //     .then((response) => {
  //       console.log("307",response);
  //       this.setState({ overview: response.data });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  // getEmployees() {
  //   const user: any = localStorage.getItem("user");
  //   const userId = user ? JSON.parse(user)["userId"] : "";
  //   callApi("POST", "emp_table.php", { userId })
  //     .then((res) => res.data)
  //     .then((response) => {
  //       this.setState({ employees: response.data });

  //       $(document).ready(function () {
  //         $("#simpletable").DataTable();
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  // getBranchInfo() {
  //   callApi("POST", "branch_list.php")
  //     .then((res) => res.data)
  //     .then((response) => {
  //       this.setState({ branchInfo: response.data });
  //       $(document).ready(function () {
  //         $("#branchtable").DataTable();
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  // listBranches = () => {
  //   callApi("GET", "get_branch.php")
  //     .then((res) => res.data)
  //     .then((response) => {
  //       this.setState({ branches: response.data });
  //       $(document).ready(function () {
  //         $("#simpletable").DataTable();
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  handleChange1 = (e: any, propName?, fieldType?) => {



    const bank_private_name = this.state.bank_private_name;
    let fieldName = fieldType === "singleselect" ? propName : e.target.name;
    let fieldValue = fieldType === "singleselect" ? e.value : e.target.value;
    if (fieldName === "bank_private_name") {
      this.filterBranches(bank_private_name);
    }
    this.setState({ bank_private_name });

    if (fieldName === "lawHandsBranch") {
      const lawHandsBranch = this.state.lawHandsBranch;
      const branch = fieldValue;
      this.setState({ lawHandsBranch });
    }
    this.setState({ [fieldName]: fieldValue });
    //  lawHandsBranch[fieldName] = fieldValue;
  };
  handleChange = (e, stateName?, type?) => {
    if (e.target.name === "eodToDate") {
      this.setState({ todate: e.target.value });
    }
    if (e.target.name === "eodfromDate") {
      this.setState({
        fromdate: e.target.value,
      });
    }
    // if (e.target.name === "bank_private_name") {
    //   this.filterBranches(e.target.value);
    // }
    if (stateName && type === "dropdown") {
      this.setState({
        [stateName]: e.value,
      });
    } else this.setState({ [e.target.name]: e.target.value });
    if (["eodFromDate", "eodToDate"].includes(e.target.name)) {
      this.setState({ isDateSearchClicked: false });
    }
  };
  routeSubReport = (detail, type) => {
    const {
      eodFromDate,
      eodToDate,
      lawHandsBranch,
      bank_private_name,
      branch,
    } = this.state;
    const obj = {
      branch: lawHandsBranch,
      fromdate: eodFromDate,
      todate: eodToDate,
      bankname: bank_private_name,
      bankbranch: branch,
      isRaised: false,
    };
    if (detail.details === "Raised Query") {
      obj.isRaised = true;
    }
    this.props.history.push(`/sub-report/${type}/${detail.status}`, {
      filterData: { ...obj },
    });
  };
  handleDateChange = (date, dateStr, type) => {
    // console.log(date, dateStr);
    this.setState({ [type]: dateStr });
  };
  lawBranchName = (type: any) => {
    if (type == 2) {
      console.log("431", type)
      this.setState({ lhBranchShow: true })
      this.getLawHandsBranches();

    } else {
      console.log("436", type)
    }
  }
  bankName = (type: any) => {

    if (type == 2) {
      console.log("431", type)
      this.setState({ showBank: true })
      this.getBanks();
    } else {
      console.log("445", type)
    }
  }
  branchName = (type: any) => {
    if (type == 2) {
      console.log("450", type)
      this.setState({ bankBranch: true })
      this.getBankBranches();
    } else {
      console.log("454", type)
    }
  }
  render() {
    const user: any = localStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["role_id"] : "";
    const {
      eodData,
      eodFromDate,
      eodToDate,
      isDateSearchClicked,
      branchEodData,
      selectVal,
      lawBranchName, bankBranch
      , branchName, bankName, showBank, lhBranchShow, myteamdetails, teamDetailsData
    } = this.state;
    const userDetails = user ? JSON.parse(user) : "";
    const isAdmin = userDetails.role_id === "1" || userDetails.role_id === '2';
    // const isAdmin = userDetails.role_id === "1" || userDetails.role_id === "2" ;
    const lawhandsBranchesoptions = [
      { value: "", label: "All" },
      ...this.state.lawhandsBranches
    ];
    const banksoptions = [
      { value: "", label: "All" },
      ...this.state.banks
    ];
    const filteredBranchesoptions = [
      { value: "", label: "All" },
      ...this.state.filteredBranches
    ];
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    {/* EOD BRANCH TABLE */}
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="mt-1 mb-1">Login Report</h5>
                        </div>

                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-xl-2 col-md-2">
                              <h6>From</h6>
                              <DatePicker
                                format={"DD.MM.YYYY"}
                                onChange={(date, dateStr) =>
                                  this.handleDateChange(
                                    date,
                                    dateStr,
                                    "eodFromDate"
                                  )
                                }
                                style={{ height: "39px" }}
                              />
                            </div>
                            <div className="col-xl-2 col-md-2">
                              <h6>To</h6>
                              <DatePicker
                                format={"DD.MM.YYYY"}
                                onChange={(date, dateStr) =>
                                  this.handleDateChange(
                                    date,
                                    dateStr,
                                    "eodToDate"
                                  )
                                }
                                style={{ height: "39px" }}
                              />
                            </div>
                            <div className="col-xl-2 col-md-2">
                              <h6>LH Branch</h6>
                              {lhBranchShow ? <>{this.state.lawhandsBranches &&
                                this.state.lawhandsBranches.length > 0 &&
                                this.state.lawhandsBranches.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select
                                    options={lawhandsBranchesoptions}
                                    name="lawHandsBranch"
                                    value={this.state.lawhandsBranches.find(
                                      (o: any) =>
                                        o.value === this.state.lawHandsBranch
                                    )}

                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "lawHandsBranch",
                                        "singleselect"
                                      )
                                    }
                                  />
                                )}</> : <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={lawBranchName ? () => this.lawBranchName(1) : () => this.lawBranchName(2)}
                                  onChange={this.handleChange}
                                >
                                <option value="">Select</option>
                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                  ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                    return (
                                      <option key={index} value={lawBranch.id}>
                                        {lawBranch.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>}
                            </div>

                            <div className="col-xl-3 col-md-3" >
                              <h6>Bank Name</h6>
                              {showBank ? <> {this.state.banks &&
                                this.state.banks.length > 0 &&
                                this.state.banks.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select

                                    options={banksoptions}
                                    name="bank_private_name"
                                    value={this.state.banks.find(
                                      (o: any) =>
                                        o.value === this.state.bank_private_name
                                    )}
                                    onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "bank_private_name",
                                        "singleselect"
                                      )
                                    }
                                  />
                                )}</> : <select
                                  className="custom-select"
                                  id="lawhands_branch"
                                  name="lawhands_branch"
                                  onClick={bankName ? () => this.bankName(1) : () => this.bankName(2)}
                                  onChange={this.handleChange}
                                >
                                <option value="">Select</option>
                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                  ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                    return (
                                      <option key={index} value={lawBranch.id}>
                                        {lawBranch.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>}
                            </div>

                            <div className="col-xl-2 col-md-2">
                              <h6>Bank Branch</h6>
                              {bankBranch ? <>{this.state.branches &&
                                this.state.branches.length > 0 &&
                                this.state.branches.map((opt: any) => {
                                  opt.label = opt.name;
                                  opt.value = opt.id;
                                }) && (
                                  <Select
                                    options={filteredBranchesoptions}
                                    name="branch"
                                    value={this.state.filteredBranches.find(
                                      (o: any) => o.value === this.state.branch
                                    )}
                                    onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                    onChange={(e) =>
                                      this.handleChange1(
                                        e,
                                        "branch",
                                        "singleselect"
                                      )
                                    }
                                  />
                                )
                              } </> : <select
                                className="custom-select"
                                id="lawhands_branch"
                                name="lawhands_branch"
                                onClick={branchName ? () => this.branchName(1) : () => this.branchName(2)}
                                onChange={this.handleChange}
                              >
                                <option value="">Select</option>
                                {this.state.lawhandsBranches && this.state.lawhandsBranches.length
                                  ? this.state.lawhandsBranches.map((lawBranch: any, index: number) => {
                                    return (
                                      <option key={index} value={lawBranch.id}>
                                        {lawBranch.name}
                                      </option>
                                    );
                                  })
                                  : ""}
                              </select>}
                            </div>

                            {/* <div className="template-select">
                              <h6>Select Employee</h6>
                              <Select options={this.state.eodEmployeeList} name="eodEmployee"
                                value={this.state.eodEmployeeList.find((o: any) => o.value === this.state.eodEmployee)}
                                onChange={(e) => this.handleChange(e, 'eodEmployee', 'dropdown')}
                              />
                            </div> */}
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ alignSelf: "end" }}
                              onClick={() => this.getEodTableData()}
                            >
                              Search
                            </button>
                          </div>
                          {eodData && eodData.length > 0 ?
                            (<>
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button excel-download-btn"
                                table="eodtable"
                                filename="Login-report"
                                sheet="tablexls"
                                buttonText="Download as Excel"
                              /></>
                            ) : ""
                          }
                          <div className="dt-responsive table-responsive">
                            <table
                              id="eodtable"
                              className="table table-striped table-bordered nowrap"
                            >
                              <thead className="text-center">
                                <tr style={{ padding: "0.50rem" }}>
                                  <th>Details</th>
                                  <th>FTD (For the Day)</th>
                                  <th>MTD (Month to Date)</th>
                                  <th>YTD (Year to Date)</th>
                                  {isDateSearchClicked &&
                                    eodFromDate &&
                                    eodToDate && (
                                      <th>{eodFromDate + " - " + eodToDate}</th>
                                    )}
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {eodData && eodData.length > 0 ? (
                                  eodData.map(
                                    (listValue: any, listIndex: number) => {
                                      return (
                                        <tr
                                          key={listIndex + listValue.details}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <td
                                            style={{
                                              padding: "0.60rem",
                                              borderColor: "inherit",
                                              fontWeight: "bold",
                                              background: listValue.color,
                                            }}
                                          >
                                            {listValue.details}
                                          </td>
                                          <td
                                            onClick={() =>
                                              this.routeSubReport(
                                                listValue,
                                                "ftd"
                                              )
                                            }
                                          >
                                            {listValue.ftd}
                                          </td>
                                          <td
                                            onClick={() =>
                                              this.routeSubReport(
                                                listValue,
                                                "mtd"
                                              )
                                            }
                                          >
                                            {listValue.mtd}
                                          </td>
                                          <td
                                            onClick={() =>
                                              this.routeSubReport(
                                                listValue,
                                                "ytd"
                                              )
                                            }
                                          >
                                            {listValue.ytd}
                                          </td>
                                          {isDateSearchClicked &&
                                            eodFromDate &&
                                            eodToDate &&
                                            listValue.btd && (
                                              <td onClick={() =>
                                                this.routeSubReport(
                                                  listValue,
                                                  "btd"
                                                )
                                              }>{listValue.btd}</td>
                                            )}
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr
                                    style={{
                                      width: "100%",
                                      background: "grey",
                                    }}
                                  >
                                    <td colSpan={4}>No Details Found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.autoAssign && this.state.autoAssign.find(
                      (data: any) => data.module_name === "Auto Assign" && data.status) ?
                      <>
                        {myteamdetails && teamDetailsData && teamDetailsData.length > 0 ? (
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-header">
                                <h5 className="mt-1 mb-1"
                                // style={{display:"flex",justifyContent:"center",background:"#fff581"}}
                                >
                                  My Team Workfile
                                </h5>
                              </div>
                              <div className="card-body">
                                <div className="dt-responsive table-responsive">
                                  <table className="table table-striped table-bordered nowrap">
                                    <thead className="text-center">
                                      <tr style={{ padding: "0.50rem" }}>
                                        <th>Name</th>
                                        {/* <th>BTD</th> */}
                                        <th>File No</th>
                                        <th>Property Owner Name</th>
                                        <th>Time</th>
                                        {/* <th>SOP</th>
                                    <th>Data Entry</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {teamDetailsData && teamDetailsData.length > 0 ? (
                                        teamDetailsData.map(
                                          (list: any, i: number) => (
                                            <>
                                              <tr className="text-center">
                                                <td>{list.name}</td>
                                                <td onClick={() => this.goToFileDashboard(list.file)}>{list.file}</td>
                                                <td>{list.property_ownername}</td>
                                                <td>{list.updated_dt}</td>
                                                {/* <td>
                                              <h6 style={{ textAlign: "center" }} >Total - 1</h6>
                                              <div className="ConT"><p className="text-c-red1">0</p><p className="text-c-yellow1">1</p><p className="text-c-green1">2 </p></div>
                                            </td>
                                            <td>
                                              <h6 style={{ textAlign: "center" }} >Total - 1</h6>
                                              <div className="ConT"><p className="text-c-red1">0</p><p className="text-c-yellow1">1</p><p className="text-c-green1">2 </p></div>
                                            </td> */}
                                              </tr>
                                            </>
                                          )
                                        )
                                      ) : (
                                        <tr
                                          style={{
                                            width: "100%",
                                            background: "grey",
                                            textAlign: "center"
                                          }}
                                        >
                                          <td colSpan={6}>No Details Found</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                      : ""}
                    {isAdmin ? (
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="mt-1 mb-1">
                              Branch wise Login Report
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="dt-responsive table-responsive">
                              <table className="table table-striped table-bordered nowrap">
                                <thead className="text-center">
                                  <tr style={{ padding: "0.50rem" }}>
                                    <th>Details</th>
                                    {/* <th>BTD</th> */}
                                    <th>FTD (For the Day)</th>
                                    <th>MTD (Month to Date)</th>
                                    <th>YTD (Year to Date)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {branchEodData && branchEodData.length > 0 ? (
                                    branchEodData.map(
                                      (list: any, i: number) => (
                                        <>
                                          <tr
                                            className="group"
                                            // onClick={() => this.routeSubReport(list.details)}
                                            style={{ cursor: "pointer" }}

                                          >
                                            <td
                                              colSpan={5}
                                              style={{
                                                padding: "0.60rem",
                                                fontWeight: "bold",
                                                background: list.color,
                                                display: list.display
                                              }}
                                            >
                                              {list.details}
                                            </td>
                                          </tr>
                                          {Object.keys(list.btd).map(
                                            (key: string) => (
                                              <tr className="text-center">
                                                <td>{key}</td>
                                                {/* <td>{list.btd[key]}</td> */}
                                                <td>{list.ftd[key]}</td>
                                                <td>{list.mtd[key]}</td>
                                                <td>{list.ytd[key]}</td>
                                              </tr>
                                            )
                                          )}
                                        </>
                                      )
                                    )
                                  ) : (
                                    <tr
                                      style={{
                                        width: "100%",
                                        background: "grey",
                                      }}
                                    >
                                      <td colSpan={4}>No Details Found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}


                    {/* THIS FOR OUT REASON MODAL */}
                    <div className="modal fade hide in" id="out_reason" data-backdrop="static" data-keyboard="false">
                      <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                          <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>
                            <h3 style={{ margin: 'auto', fontSize: '17px' }}>Please Select The Reason For To Close The File.No:{localStorage.getItem("currentfileId")}</h3>
                          </div>
                          <div className="form-group" style={{ width: '74%', margin: 'auto' }}>
                            <select
                              className="custom-select"
                              name="outreason"
                              value={this.state.outreason}
                              onChange={(e) => this.onchangeReason(e)}
                              required
                              id="select1"
                              style={{ marginTop: '8px' }}
                            >
                              <option value="select">Select Your Reason</option>
                              <option value="Query Raised">Query Raised</option>
                              <option value=" Re-Query Raised"> Re-Query Raised</option>
                              <option value="Hold ">Hold </option>
                              <option value="Completed">Completed</option>
                              <option value="Other">Others</option>

                            </select>
                            {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                          </div>
                          <div className="modal-footer" style={{ marginTop: '27px' }}>
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={this.state.isLoading}
                              onClick={this.outimeGet}
                              style={{ width: '76px', fontSize: '17px' }}
                            >
                              {this.state.isLoading ? (
                                <i className="fa fa-spinner fa-spin"></i>
                              ) : "OK"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline"
                              onClick={this.BacktoFile}
                              style={{ width: '107px', fontSize: '14px', border: '1px solid black', height: '43px' }}
                            > Back To File</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* OTHER REASON MODAL */}
                    <div className="modal hide fade in" id="another-reason" data-backdrop="static" data-keyboard="false">
                      <div className="modal-dialog ">
                        <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0 0 15px #000' }}>
                          <h5 style={{ marginTop: '31px', textAlign: 'center' }}>Please Type a Reason To Close The File.No: {localStorage.getItem("currentfileId")} </h5>
                          <div className="modal-body" style={{ padding: '30px 23px 29px 24px' }}>

                            {/* <div className="col-md-5"> */}
                            <div className="form-group" style={{ width: '98%', margin: 'auto' }}>
                              <textarea
                                className="form-control"
                                placeholder="Type Your Reason"
                                name="outreason" onChange={this.onchange} />
                              {selectVal ? <p style={{ color: 'red', textAlign: 'center', fontSize: '16px' }}>Please Select Your Reason </p> : null}
                            </div>
                            {/* </div> */}

                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.outimeGet()}
                              style={{ width: '76px', fontSize: '17px' }}
                              disabled={this.state.isLoading}
                            >
                              {this.state.isLoading ? (
                                <i className="fa fa-spinner fa-spin"></i>
                              ) : "OK"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline"

                              onClick={() => this.previousPop()}
                              // onClick={this.disableModalHandler}
                              style={{ width: '76px', fontSize: '17px', border: '1px solid black' }}
                            > Back</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
